import { FC, useEffect, useState } from 'react';
import { NudosButton, NudosGenericModal, NudosModalContent } from '../../../../../components/NudosComponents';
import { getSpeiBankData } from '../../../../../services/checkout';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { NudosTransfDetails } from '../../../../../components/DesignSystem';
import { IBank } from '../../../../../types/checkout';
import useCartState from '../../../../../state/useCartState';
import { useTranslation } from 'react-i18next';
import { INudosTransfDetailsData } from '../../../../../types/cart';
import './DetailsPaymentModal.scss';

interface IDetailsPaymentModal {
  closeModal: () => void;
  paymentMethod: string;
  banckAccountData: INudosTransfDetailsData | undefined;
}

const DetailsPaymentModal: FC<IDetailsPaymentModal> = ({
  closeModal,
  paymentMethod,
  banckAccountData
}: IDetailsPaymentModal) => {
  const { t } = useTranslation();
  const { dataFinishCart } = useCartState();

  const [SPEIBankData, setSPEIBankData] = useState<IBank>();
  const [loading, setLoading] = useState<boolean>(true);

  const orderId = dataFinishCart?.orderId || banckAccountData?.orderId;
  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:detailsPaymentModal:';
  const isPaid = !!SPEIBankData?.isPaid;

  const getInfoBank = async () => {
    if (paymentMethod === 'Transf. MXN') {
      if (!orderId) return;
      setLoading(true);
      try {
        const data = await getSpeiBankData(orderId);
        setSPEIBankData(data);
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoading(false);
      }
    } else setLoading(false);
  };

  useEffect(() => {
    getInfoBank();
  }, []);

  return (
    <div id="detailsPaymentModalContainer">
      <NudosGenericModal
        clickOutsideCallback={() => closeModal()}
        closeModalCallback={() => closeModal()}
        modalContent={
          <NudosModalContent
            hideNudosIcon
            customWidth="446px"
            leftAlignmentTitle
            toCloseModal={() => closeModal()}
            modalContentTitle={t(`${i18nLanguageKey}modalContentTitle`)}
            CustomModalComponent={
              <div id="detailsPaymentModal">
                {!isPaid && <NudosTransfDetails specialData={banckAccountData} />}
                {isPaid && <div className="isPaidMessage">{t(`${i18nLanguageKey}isPaidMessage`)}</div>}
                <div className="buttonContainer">
                  <NudosButton
                    buttonText={t(`${i18nLanguageKey}buttonText`)}
                    customWidth="380px"
                    customHeight="38px"
                    isButtonDisabled={loading}
                    handleClick={() => closeModal()}
                  />
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default DetailsPaymentModal;

import { useEffect, useState } from 'react';
import { IbillingData, SPECIAL_BUSINESS_NAME_TYPES } from '../../../types/checkout';
import { getOrganizationAccount } from '../../../services/account';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { NudosButton, NudosHoverText } from '../../NudosComponents';
import { CameraIcon } from '../../../assets/images/components/NudosComponents';
import { useTranslation } from 'react-i18next';
import NudosStaticAlertInComponent from '../NudosStaticAlertInComponent/NudosStaticAlertInComponent';
import useStoreCountryState from '../../../state/useStoreCountryState';
import { useLocation } from 'react-router-dom';
import SelectOtherBillingCountryForLogisticService from '../../../views/RequestLogistics/LogisticsQuoteAndBillingStep/LogisticServiceBillingData/Components/SelectOtherBillingCountryForLogisticService/SelectOtherBillingCountryForLogisticService';
import { TlogisticsOriginOrDestinationData } from '../../../types/requestLogisticsModule';
import './NudosBillingDataSummaryComponent.scss';

/**
 * @param {Object} props - The properties object.
 * @param {IbillingData} props.billingData - The billing data to be displayed.
 * @param {TlogisticsOriginOrDestinationData} props.detinationData - The destination data related to logistics.
 * @param {Function} props.openBillingDataModal - Function to open the billing data modal.
 * @param {Function} props.cleanUpdatedBillingData - Function to clean the updated billing data.
 * @returns {JSX.Element} The rendered component.
 */

const NudosBillingDataSummaryComponent = ({
  billingData,
  originData,
  detinationData,
  openBillingDataModal,
  cleanUpdatedBillingData
}: {
  billingData?: IbillingData;
  originData?: TlogisticsOriginOrDestinationData;
  detinationData?: TlogisticsOriginOrDestinationData;
  openBillingDataModal?: () => void;
  cleanUpdatedBillingData?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const orgData = getOrgData();
  const { storeCountry } = useStoreCountryState();
  const { pathname } = useLocation();

  const [organizationImg, setOrganizationImg] = useState<string>();

  const { documentCountry, businessName, specialBusinessNameType, document, email } = billingData || {};
  const flag = documentCountry?.flag.replace(/\s/g, '%20') || '';
  const billingBusinessName = businessName || '';
  const translationKey = 'designSystemComponents:billingDataSummaryComponent:';
  const isCheckoutFlux = pathname.includes('catalogue');
  const isLogisticFLux = pathname.includes('nodi');

  const displaySpecialBusinessAlert =
    specialBusinessNameType?.id === SPECIAL_BUSINESS_NAME_TYPES?.FREE_ZONE &&
    storeCountry?.countryCode === 'uy' &&
    isCheckoutFlux;

  const getInfoCompany = async () => {
    const infoCompany = await getOrganizationAccount(orgData.organizationId);
    setOrganizationImg(infoCompany?.image || '');
  };

  useEffect(() => {
    getInfoCompany();
  }, []);

  return (
    <div className="NudosBillingDataSummaryComponent">
      <div className="billingDataSummaryContainer">
        <div className="userInformation">
          <div className="photoAndFlag">
            {organizationImg && <div className="photo" style={{ backgroundImage: `url("${organizationImg}")` }} />}
            {!organizationImg && <div className="noPhoto" style={{ backgroundImage: `url('${CameraIcon}')` }} />}
            {flag && <div className="flag" style={{ backgroundImage: `url(${flag})` }} />}
          </div>
          <div className="billingNameAndEmailContainer">
            <NudosHoverText
              onlyIfTruncated
              customClassName="userName"
              text={billingBusinessName}
              charactersLimit={15}
            />
            {document}
          </div>
        </div>
        <div className="billingRightSection">
          <div className="billingEmail">{t(`${translationKey}billingEmail`)}</div>
          {email}
        </div>
      </div>
      <NudosButton
        buttonText={t(`${translationKey}nudosButtonText`)}
        handleClick={openBillingDataModal}
        customClassName="openModalText"
        customFontStyles={{ fontSize: '10px' }}
      />
      <div className="changeBillingDataButton" onClick={cleanUpdatedBillingData}>
        {t(`${translationKey}changeBillingDataButton`)}
      </div>
      {displaySpecialBusinessAlert && (
        <NudosStaticAlertInComponent
          customClassname="specialBusinessAlert"
          type="information"
          color="blue"
          alertOptions={{
            title: 'Tu razón social fue marcada como Zona Franca, por tanto no cobraremos el 22% de impuestos'
          }}
        />
      )}
      {isLogisticFLux && (
        <SelectOtherBillingCountryForLogisticService
          billingData={billingData}
          detinationData={detinationData}
          originData={originData}
        />
      )}
    </div>
  );
};

export default NudosBillingDataSummaryComponent;

import { NudosButton } from '../../../../../../../../components/NudosComponents';
import { logisticQuoteIcon } from '../../../../../../../../assets/images/views/RequestLogistics';
import { NudosLinkTypeButton } from '../../../../../../../../components/DesignSystem';
import { IlogisticServiceDetailedData } from '../../../../../../../../types/logisticsServicesModule.types';
import './ReviewLogisticQuoteModal.scss';

const ReviewLogisticQuoteModalContent = ({
  logisticServiceData,
  handleLsRejection,
  approveOrRejectLogisticQuote
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  handleLsRejection: () => void;
  approveOrRejectLogisticQuote: (approveOrReject: 'approved' | 'rejected') => void;
}) => {
  return (
    <div className="reviewLogisticQuoteModalContainer">
      <div className="modalSubtitle">Aprueba o rechaza el servicio para poder continuar</div>
      <img className="logisticQuoteIcon" src={logisticQuoteIcon} />
      <NudosLinkTypeButton
        text="Revisar cotización"
        specialIcon="download"
        customClassname="nudosLinkTypeButton"
        handleClickCallback={() => window.open(logisticServiceData?.quoteDocumentUrl, 'blank')}
      />
      <div className="buttonsContainer">
        <NudosButton
          buttonText="Rechazar cotización"
          handleClick={handleLsRejection}
          colorPalette="secondary"
          customClassName="nudosButton"
        />
        <NudosButton
          buttonText="Aprobar cotización"
          handleClick={() => approveOrRejectLogisticQuote('approved')}
          customClassName="nudosButton"
        />
      </div>
    </div>
  );
};

export default ReviewLogisticQuoteModalContent;

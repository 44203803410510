import { OrderShipmentCard, OrderShipmentCardSkeletonLoader } from '..';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import { IshipmentDetails } from '../../../../../types/orders';
import { useTranslation } from 'react-i18next';
import './ShipmentsList.scss';

/**
 * @property {IshipmentDetails[]} shipments - The array of shipments to be displayed
 * @property {string} flag - The flag of the country where the shipments takes place
 * @property {string} countryName - The name of the country where the shipments takes place
 * @property {number} quantityShipments - The amount of shipments in the list
 * @property {() => void} updateOrderDataFunction - A function to upload the OrderData
 */
const ShipmentsList = ({
  shipments,
  flag,
  countryName,
  quantityShipments,
  updateOrderDataFunction,
  getOrderDatawithoutLoader
}: {
  shipments?: IshipmentDetails[] | null;
  flag?: string | null;
  countryName?: string | null;
  quantityShipments?: number | null;
  updateOrderDataFunction: () => void;
  getOrderDatawithoutLoader: () => void;
}) => {
  const { t } = useTranslation();

  const i18nLanguageKey = 'nodi:orderDetails:shipmentsList:';
  const shipmentsCountText = quantityShipments ?? shipments?.length ?? t(`${i18nLanguageKey}noData`);
  const countryDestinationAndCountText = `${countryName} (${shipmentsCountText})`;

  return (
    <div className="shipmentsList">
      <div className="shipmentCountryAndCount">
        <div className="flag" style={{ backgroundImage: `url(${flag})` || '' }} />
        <NudosHoverText text={countryDestinationAndCountText} charactersLimit={40} onlyIfTruncated={true} />
      </div>
      <div className="listContainer">
        {shipments &&
          Array.isArray(shipments) &&
          shipments.map((shipment, i) => {
            return (
              <OrderShipmentCard
                key={`shipment${i}-${shipment.shipmentId}`}
                shipmentCardIndex={i}
                shipmentData={shipment}
                updateOrderDataFunction={updateOrderDataFunction}
                countryName={countryName}
                countryTimeZone={shipment?.countryTimeZone || undefined}
                getOrderDatawithoutLoader={getOrderDatawithoutLoader}
              />
            );
          })}
      </div>
    </div>
  );
};

const ShipmentsListSkeletonLoader = () => {
  return (
    <div className="shipmentsListSkeletonLoader">
      <div className="shipmentCountryAndCount animationLoader" />
      <div className="listContainer">
        {[1, 2, 3, 4, 5, 6, 7].map(i => {
          return <OrderShipmentCardSkeletonLoader key={`shipmentSkeletonCard${i}`} />;
        })}
      </div>
    </div>
  );
};

export { ShipmentsList, ShipmentsListSkeletonLoader };

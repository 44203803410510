import { FC, useCallback, useEffect, useState } from 'react';
import { CartIcon } from '../../../../assets/images/views/Ecommerce';
import { AddIcon, DeleteIcon, RemoveIcon, redIcon } from '../../../../assets/images/components/countryPrices';
import IconWarningInformation from '../../../../assets/DesignSystem/SVGComponents/IconWarningInformation';
import {
  addProductCart,
  deleteProduct,
  editInputQuantity,
  firstProduct,
  productQuantity,
  removeProductCard
} from '../../../../utils/cart';
import useShopStore from '../../../../state/useShopContext';
import { Tany } from '../../../../types/global';
import { IProductUpdate } from '../../../../types/cart';
import { deleteProductCart, updateProductCart } from '../../../../services/cart.services';
import { formatOrgData } from '../../../../utils/orgFormatData';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import useCheckoutStore from '../../checkout/state';
import { nudosFormatCurrency } from '../../../../utils/formatNumbers';
import IconGreenThunder from '../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import useStateSubscriptions from '../../../../state/useStateSubscriptions';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../utils/segment';
import { IProductDetail } from '../../../../types/catalogue';
import useStoreCountryState from '../../../../state/useStoreCountryState';

import './SingleCountryAddToCartButton.scss';

interface ISingleCountryAddToCartButton {
  productInfo: IProductDetail;
  showSpecialElements: boolean;
  setTotalSaving: (totalSaving: string) => void;
}

interface IDebounceProduct {
  product: IProductUpdate;
  type: string;
  shoppingCartId?: number | null;
}

const SingleCountryAddToCartButton: FC<ISingleCountryAddToCartButton> = ({
  productInfo,
  showSpecialElements,
  setTotalSaving
}: ISingleCountryAddToCartButton) => {
  const { t } = useTranslation();
  const orgInfo = formatOrgData();
  const { itemsCart, setCart } = useShopStore();
  const { stateSubscription } = useStateSubscriptions();
  const { step: checkoutStep, setStep } = useCheckoutStore();
  const { storeCountry } = useStoreCountryState();

  const [quantity, setQuantity] = useState<number>(0);
  const [openInput, setOpenInput] = useState<boolean>(false);
  const [prodductAdded, setProdductAdded] = useState<number>(0);
  const [isEditQuantity, setIsEditQuantity] = useState<boolean>();
  const [showDetailsTooltip, setShowDetailsTooltip] = useState<boolean>(false);

  const { isPrime } = stateSubscription;
  const languageKey = 'nodi:checkout:countryPrices:';
  const languageCountriesKey = 'services:countries:';
  const disabledButton = !productInfo || !storeCountry;
  const storeCountryIsArgOrUSA = ['ar', 'us'].includes(storeCountry?.countryCode || '');
  const taxTextArgAndUSA = storeCountryIsArgOrUSA ? t(`${languageKey}taxArgAndUSA`) : undefined;
  const taxTex = storeCountry?.countryCode === 'uy' ? t(`${languageKey}taxDiscountTex`) : t(`${languageKey}taxtTex`);

  const resetCheckoutStep = () => checkoutStep !== 0 && setStep(0);

  const debounce = (func: Tany) => {
    let timer: Tany;
    return function (...args: Tany) {
      const context: Tany = '';
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const addQuantityProduct = (quantity: number) => {
    const formatProduct: IProductUpdate = {
      productId: Number(productInfo?.productId),
      productName: productInfo.productName,
      brandImage: productInfo.brandImage || '',
      image: productInfo.imageUrl || '',
      pills: productInfo?.pills || undefined,
      countryFlag: storeCountry?.countryFlag || '',
      countryName: storeCountry?.countryName || '',
      countryNameStringId: storeCountry?.nameStringId || '',
      countryId: Number(storeCountry?.id),
      quantity: quantity,
      unitPrice: productInfo?.prices?.pricePrimeUsd || '',
      brandName: productInfo?.brandName || '',
      category: productInfo?.categoryName || '',
      prices: null,
      sku: productInfo?.sku || ''
    };
    setQuantity(quantity);
    if (itemsCart && setCart) {
      addProductCart(formatProduct, itemsCart, setCart);
      optimizedFn({ product: formatProduct, type: 'edit', shoppingCartId: itemsCart.shoppingCartId });
      resetCheckoutStep();
    }
    setProdductAdded(prodductAdded + 1);
  };

  const removeItem = (quantity: number) => {
    const formatProduct: IProductUpdate = {
      productId: Number(productInfo?.productId),
      productName: productInfo.productName,
      brandImage: productInfo.brandImage || '',
      image: productInfo.imageUrl || '',
      pills: productInfo?.pills || undefined,
      countryFlag: storeCountry?.countryFlag || '',
      countryName: storeCountry?.countryName || '',
      countryNameStringId: storeCountry?.nameStringId || '',
      countryId: storeCountry?.id || 0,
      quantity: quantity,
      unitPrice: productInfo?.prices?.pricePrimeUsd || '',
      brandName: productInfo?.brandName || '',
      category: productInfo?.categoryName || '',
      prices: null,
      sku: productInfo?.sku || ''
    };
    setQuantity(quantity);
    if (storeCountry?.countryName && itemsCart) {
      removeProductCard(Number(productInfo.productId), quantity, itemsCart, setCart);
      optimizedFn({ product: formatProduct, quantity, type: 'edit', shoppingCartId: itemsCart.shoppingCartId });
      resetCheckoutStep();
    }
  };

  const deleteItem = async () => {
    setQuantity(0);
    if (storeCountry?.countryName && itemsCart && storeCountry?.id) {
      deleteProduct(Number(productInfo.productId), itemsCart, setCart);
      if (itemsCart?.shoppingCartId) {
        optimizedFn({ product: {}, quantity, type: 'delete', shoppingCartId: itemsCart.shoppingCartId });
        resetCheckoutStep();
        await deleteProductCart(itemsCart.shoppingCartId, Number(productInfo.productId), storeCountry?.id);
      }
    }
  };

  const editBlurQuantity = async (quantity: number) => {
    if (quantity === 0 && storeCountry?.countryName && itemsCart) {
      deleteProduct(Number(productInfo.productId), itemsCart, setCart);
      await deleteProductCart(Number(itemsCart.shoppingCartId), Number(productInfo.productId), storeCountry?.id);
      resetCheckoutStep();
    } else if (storeCountry?.countryName && itemsCart) {
      const formatProduct: IProductUpdate = {
        productId: Number(productInfo?.productId),
        productName: productInfo.productName,
        brandImage: productInfo.brandImage || '',
        image: productInfo.imageUrl || '',
        pills: productInfo?.pills || undefined,
        countryFlag: storeCountry?.countryFlag || '',
        countryName: storeCountry?.countryName || '',
        countryNameStringId: storeCountry?.nameStringId || '',
        countryId: storeCountry?.id,
        quantity: quantity,
        unitPrice: productInfo?.prices?.pricePrimeUsd || '',
        brandName: productInfo?.brandName || '',
        category: productInfo?.categoryName || '',
        prices: null,
        sku: productInfo?.sku || ''
      };
      await updateProductCart({
        userId: orgInfo?.userId || 0,
        havePrime: isPrime,
        organizationId: orgInfo?.organizationId || 0,
        products: [formatProduct],
        countryCode: storeCountry.countryCode || ''
      });
      resetCheckoutStep();
      editInputQuantity(Number(productInfo.productId), itemsCart, quantity, setCart);
    }
    setIsEditQuantity(false);
  };
  const handleChange = async (updateData: IDebounceProduct) => {
    if (updateData.type === 'edit' && updateData.product) {
      const format = {
        userId: orgInfo?.userId || 0,
        havePrime: isPrime,
        organizationId: orgInfo?.organizationId || 0,
        products: [updateData.product],
        countryCode: storeCountry?.countryCode || ''
      };
      try {
        await updateProductCart(format);
        resetCheckoutStep();
        if (!updateData?.shoppingCartId && orgInfo) {
          firstProduct(setCart, orgInfo.userId, orgInfo.organizationId);
        }
      } catch (error) {
        displayErrorNotification();
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const realProductPrice = (total: number, quantity?: number) => {
    const quantityUnits = quantity || 1;
    const primeTotal = total * quantityUnits;
    return primeTotal;
  };

  const calculateSavings = (quantity?: number, pricePrimeUsd?: number, priceWithoutPrimeUsd?: number) => {
    if (quantity && pricePrimeUsd && priceWithoutPrimeUsd) {
      const primePrice = pricePrimeUsd * quantity;
      const freePrice = priceWithoutPrimeUsd * quantity;
      const discountedAmount = freePrice - primePrice;
      return `${nudosFormatCurrency({ value: discountedAmount, maximumFractionDigits: 2 })} USD`;
    }
    return '';
  };

  const totalSavings = calculateSavings(
    quantity,
    Number(productInfo?.prices?.pricePrimeUsd),
    Number(productInfo?.prices?.priceWithoutPrimeUsd || 0)
  );

  useEffect(() => {
    if (storeCountry?.countryName && productInfo?.productId && itemsCart?.products) {
      const defaultQuantity = productQuantity(Number(productInfo?.productId), itemsCart);
      setQuantity(defaultQuantity);
    } else {
      setQuantity(0);
    }
  }, [storeCountry]);

  useEffect(() => {
    prodductAdded &&
      segmentTrackEvent({
        internalProductKartClick: {
          CategoryName: `${productInfo.categoryName}`,
          CountryName: storeCountry?.countryName || '',
          SKUId: `${productInfo?.sku}`,
          TypeOfPlatform: !isPrime ? 'Free' : 'Prime',
          USDTotal: `${
            productInfo?.prices?.havePrime
              ? productInfo?.prices?.pricePrimeUsd
              : productInfo?.prices?.priceWithoutPrimeUsd
          }`,
          TotalUnits: quantity,
          ProductName: productInfo?.productName || ''
        }
      });
  }, [prodductAdded]);

  useEffect(() => {
    setTotalSaving(totalSavings);
  }, [quantity]);

  return (
    <div id="singleCountryAddToCartButton">
      <div className="addContainer">
        {quantity === 0 && !openInput ? (
          <button onClick={() => addQuantityProduct(quantity + 1)} className="orangeButton" disabled={disabledButton}>
            {t(`nodi:tools:addToolLocationButton`)}{' '}
            <CartIcon className="ml-1" stroke={disabledButton ? '#B3B3B3' : ''} />
          </button>
        ) : (
          <div className="containerQuantity">
            <div className="boxQuantityItems">
              {quantity === 1 ? (
                <div className="deleteContainer" onClick={() => deleteItem()}>
                  <DeleteIcon />
                </div>
              ) : (
                <div className="removeContainer" onClick={() => removeItem(quantity - 1)}>
                  <RemoveIcon />
                </div>
              )}
              <div className="inputContainer">
                <div className="boxInputContainer">
                  <input
                    className="customInput"
                    onMouseOut={(e: Tany) => {
                      if (isEditQuantity) {
                        editBlurQuantity(Number(e.target.value || 0));
                        setOpenInput(false);
                      }
                    }}
                    onChange={e => {
                      if (!isNaN(Number(e.target.value))) {
                        setQuantity(Number(e.target.value));
                        setIsEditQuantity(true);
                        setOpenInput(true);
                      }
                    }}
                    value={quantity || 0}
                  />
                </div>
              </div>
              <div className="addContainerExtra" onClick={() => addQuantityProduct(quantity + 1)}>
                <AddIcon />
              </div>
            </div>
            <div className="buttonBlueCart">
              <Link className="cartLink" to={'/catalogue/checkout'} />
              <div className="minBlueText">{t(`${languageKey}minBlueText`)}</div>
              <div className="blueCartIcon">
                <CartIcon className="iconCustomSize" stroke="#54A1FC" />
              </div>
            </div>
          </div>
        )}
        <div className="infoPricesCountry">
          <div className={`totalTextContainer ${quantity && quantity > 0 ? 'orangeTextSelect' : ''}`}>
            <div className="limitsContainerText">
              <div className="totalTextAddButton">{`${t(`${languageKey}totalByCountry`)} ${t(
                `${languageCountriesKey}${storeCountry?.nameStringId}`
              )}`}</div>
              <div className="flagAddButtonContainer">
                <div
                  className="flagButtonBox"
                  style={{ backgroundImage: `url("${storeCountry?.countryFlag}")`, opacity: !quantity ? '0.5' : '' }}
                />
              </div>
            </div>
            <div className="flex">
              {!!isPrime && showSpecialElements && quantity > 0 && (
                <div className="containerYellowThunder">
                  <IconGreenThunder fill="#7B61FF" />
                </div>
              )}
              {`${nudosFormatCurrency({
                value: !quantity
                  ? 0
                  : realProductPrice(
                      Number(
                        isPrime ? productInfo?.prices?.pricePrimeUsd : productInfo?.prices?.priceWithoutPrimeUsd || 0
                      ),
                      quantity
                    ),
                maximumFractionDigits: 2
              })} USD`}
            </div>
          </div>
          <div className="containerAmount enabledButton">
            <div>{t(`${languageKey}amountText`)}</div>
            <div className="containerCountryPrices">
              {showSpecialElements && (
                <div className="discuntPriceContainer">
                  (
                  {!isPrime && (
                    <>
                      <div className="containerIconPrice">
                        <IconGreenThunder fill="#7B61FF" />
                      </div>
                    </>
                  )}
                  <div className="line-through">
                    {`${nudosFormatCurrency({
                      value: Number(
                        !isPrime ? productInfo?.prices?.pricePrimeUsd : productInfo?.prices?.priceWithoutPrimeUsd || 0
                      ),
                      maximumFractionDigits: 2
                    })} USD`}
                  </div>
                  )
                </div>
              )}
              {!!isPrime && showSpecialElements && (
                <div className="containerYellowThunder">
                  <IconGreenThunder fill="#7B61FF" />
                </div>
              )}
              {`${nudosFormatCurrency({
                value: realProductPrice(
                  Number(isPrime ? productInfo?.prices?.pricePrimeUsd : productInfo?.prices?.priceWithoutPrimeUsd || 0)
                ),
                maximumFractionDigits: 2
              })} USD`}
            </div>
          </div>
          <div className="spaceGrayLine" />
          {showSpecialElements && (
            <>
              {!isPrime ? (
                <div className="discountMinBanner">
                  <div className="boxDiscountMinBanner">
                    <div className="iconPercent">
                      <img alt="redIcon" className="redIconSize" src={redIcon} />
                    </div>
                    <div className="discountTextPercent">
                      {t(`recurrentWords:pay`)}
                      <strong className="text-sm">
                        {` ${calculateSavings(
                          quantity,
                          Number(productInfo?.prices?.pricePrimeUsd),
                          Number(productInfo?.prices?.priceWithoutPrimeUsd || 0)
                        )} `}
                        {t('recurrentWords:less')}
                      </strong>
                      {` ${t(`nodi:dynamicBanner:with`)} Nodi Prime`}
                    </div>
                    <div className="discountThunderIcon">
                      <IconGreenThunder fill="#7B61FF" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="primeSavings">
                  <div className="boxPrimeSavings">
                    <div className="thunderSavingsIcon">
                      <IconGreenThunder fill="#7B61FF" />
                    </div>
                    <div className="savingsBlueText">
                      {t(`${languageKey}savingsBlueText`)}
                      <strong>
                        {calculateSavings(
                          quantity,
                          Number(productInfo?.prices?.pricePrimeUsd || 0),
                          Number(productInfo?.prices?.priceWithoutPrimeUsd)
                        )}
                      </strong>
                    </div>
                    <div className="tooltipSavings">
                      <IconWarningInformation
                        onMouseOverCapture={() => setShowDetailsTooltip(true)}
                        onMouseMoveCapture={() => setShowDetailsTooltip(true)}
                        onMouseOutCapture={() => setShowDetailsTooltip(false)}
                        onMouseOut={() => setShowDetailsTooltip(false)}
                      />
                      {showDetailsTooltip && (
                        <div className="minModalInfo">
                          <div className="minBlueTitle">{t(`nodi:footerBanner:checkoutSummary:bannerTitleLite`)}</div>
                          <div className="minBlueBody">
                            <Trans i18nKey={t(`${languageKey}minBlueBody`)} components={{ 1: <br /> }} />
                          </div>
                          <div className="minCenterTotal">
                            {`${t(`${languageKey}minCenterTotal`)} = ${nudosFormatCurrency({
                              value: Number(productInfo?.prices?.priceWithoutPrimeUsd),
                              maximumFractionDigits: 2
                            })} USD`}
                          </div>
                          <div className="minListBlue">
                            <div className="labelTotals">Subtotal</div>
                            <div className="labelAmount">
                              {`${nudosFormatCurrency({
                                value: Number(productInfo?.prices?.priceWithoutPrimeUsd) * quantity,
                                maximumFractionDigits: 2
                              })} USD`}
                            </div>
                          </div>
                          <div className="minListBlue">
                            <div className="labelTotals">
                              {t(`${languageKey}primerSave`)}
                              <span className="minGray">{` (${t(`recurrentWords:upTo`)} 3% off)`}</span>
                            </div>
                            <div className="labelAmount">
                              {`${
                                productInfo?.prices?.pricePrimeUsd && quantity
                                  ? calculateSavings(
                                      quantity,
                                      Number(productInfo?.prices.pricePrimeUsd || 0),
                                      Number(productInfo?.prices.priceWithoutPrimeUsd)
                                    )
                                  : '$0.00 USD'
                              }`}
                            </div>
                          </div>
                          <div className="minListBlue">
                            <div className="labelTotals">{t(`${languageKey}labelTotals`)}</div>
                            <div className="labelAmount">
                              <strong>
                                {quantity !== 0
                                  ? nudosFormatCurrency({
                                      value: realProductPrice(
                                        Number(
                                          isPrime
                                            ? productInfo?.prices?.pricePrimeUsd
                                            : productInfo?.prices?.priceWithoutPrimeUsd || 0
                                        ),
                                        quantity
                                      ),
                                      maximumFractionDigits: 2
                                    })
                                  : '$0.00'}{' '}
                                USD
                              </strong>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="taxtTex">{taxTextArgAndUSA || taxTex}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleCountryAddToCartButton;

import { useTranslation } from 'react-i18next';
import IconGreenThunder from '../../../../../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { NudosRedirectionIcon } from '../../../../../../../components/DesignSystem';
import { NudosHoverText } from '../../../../../../../components/NudosComponents';
import ProductPills from '../../../../../../../components/ProductPills/ProductPills';
import useStateSubscriptions from '../../../../../../../state/useStateSubscriptions';
import { IProductUpdate } from '../../../../../../../types/cart';
import { formatCost, nudosFormatCurrency } from '../../../../../../../utils/formatNumbers';
import { AddingSubtractingInputButton } from '../../../../../Components';
import './CheckoutProductItemCard.scss';

/**
 * @property { IProductUpdate } itemData - an object with the data of the product, including the following properties productId (number), productName (string), unitPrice (number| string) brand (string), brandImage (string), image (string), pills ({[key: string]: string}) quantity (number), countryId (number), countryName (string), countryFlag (string)
 */
const CheckoutProductItemCard = ({ itemData }: { itemData: IProductUpdate }) => {
  const { productId, image, productName, brandImage, brand, pills, unitPrice, quantity, prices } = itemData;
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const { isPrime } = stateSubscription;
  const { prices: productPrices } = itemData;
  const returnText = t('designSystemComponents:referenceOptions:checkoutRedirectionText');

  const toolDetailsRedirectionData = {
    pathname: `/catalogue/product/${productId}`,
    state: {
      returnText: returnText,
      returnUrl: '/catalogue/checkout'
    }
  };

  const formatMainPrice = () => {
    if (productPrices) {
      const mainPrice = Number(isPrime ? productPrices?.priceWithPrime || 0 : productPrices?.priceWithoutPrime || 0);
      return `${nudosFormatCurrency({
        value: mainPrice * quantity,
        maximumFractionDigits: 2
      })} USD`;
    }
    return `${formatCost(+unitPrice * quantity)} USD`;
  };

  const formatSecondaryPrice = () => {
    if (productPrices) {
      const secondaryPrice = Number(
        isPrime ? productPrices.priceWithPrime || 0 : productPrices?.priceWithoutPrime || 0
      );
      return `${nudosFormatCurrency({ value: secondaryPrice, maximumFractionDigits: 2 })} USD`;
    }
    return `${nudosFormatCurrency({ value: Number(unitPrice || 0), maximumFractionDigits: 2 })} USD`;
  };

  const formatSecondaryGrayLightText = () => {
    if (prices) {
      const alternatePrice = Number(isPrime ? prices.priceWithoutPrime || 0 : prices.priceWithPrime || 0);
      return `${nudosFormatCurrency({ value: alternatePrice, maximumFractionDigits: 2 })} USD`;
    }
    return `${nudosFormatCurrency({ value: Number(unitPrice || 0), maximumFractionDigits: 2 })} USD`;
  };

  return (
    <div className="checkoutProductItemCard sizeProductCard">
      <div className="imageSection">
        <img src={image} alt="Imagen del producto" />
      </div>
      <div className="nameAndPillsSection extraNameAndPillsSection">
        <div className="nameAndBrand">
          <div className="brand">
            {brandImage && <img src={brandImage} />}
            {!brandImage && (brand || 'Sin marca')}
          </div>
          <NudosHoverText customClassName="name" text={productName} charactersLimit={35} onlyIfTruncated />
          <NudosRedirectionIcon redirectionDataObject={toolDetailsRedirectionData} />
        </div>
        <ProductPills pills={pills || {}} />
      </div>
      <AddingSubtractingInputButton itemData={itemData} />
      <div className="priceSection">
        <div className="totalPrice">{formatMainPrice()}</div>
        <div className="unitPrice addContainerIcon">
          {Number(unitPrice || 0) >= 170 && (
            <>
              <div className="containerExtraInfo">
                (
                {!isPrime && (
                  <div className="thunderIcon">
                    <IconGreenThunder fill="#7B61FF" />
                  </div>
                )}
                <div className="textGrayLine">{formatSecondaryGrayLightText()}</div>)
              </div>
              {isPrime && (
                <div className="yellowIconPrime">
                  <IconGreenThunder fill="#7B61FF" />
                </div>
              )}
            </>
          )}
          {formatSecondaryPrice()} {'c/u'}
        </div>
      </div>
    </div>
  );
};

const CheckoutProductItemCardSkeletonLoader = () => {
  return (
    <div className="checkoutProductItemCard checkoutProductItemCardSkeletonLoader">
      <div className="imageSection animationLoader" />
      <div className="nameAndPillsSection">
        <div className="nameAndBrand animationLoader" />
        <div className="pillsSkeleton">
          <div className="pill animationLoader" />
          <div className="pill animationLoader" />
          <div className="pill animationLoader" />
        </div>
      </div>
      <div className="buttonSkeleton animationLoader" />
      <div className="priceSection">
        <div className="totalPrice animationLoader" />
        <div className="unitPrice animationLoader" />
      </div>
    </div>
  );
};

export { CheckoutProductItemCard, CheckoutProductItemCardSkeletonLoader };

import LogisticServicePaymentSection from '../LogisticServicePaymentSection/LogisticServicePaymentSection';
import LogisticServiceTrackingSection from '../LogisticServiceTrackingSection/LogisticServiceTrackingSection';
import LogisticServiceLocationCard from '../LogisticServiceLocationCard/LogisticServiceLocationCard';
import { IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import BordBillingDataSection from '../../../../../../components/DesignSystem/BordBillingDataSection/BordBillingDataSection';
import { NudosDownloadDocumentButton } from '../../../../../../components/NudosComponents';
import { useTranslation } from 'react-i18next';
import './LogisticsDetailsSummary.scss';

const LogisticsDetailsSummary = ({
  logisticServiceData,
  getLogisticServiceDetailCallback,
  setLoadingCallback,
  loading
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceDetailCallback: () => void;
  setLoadingCallback: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    status,
    trackingLink,
    trackingCode,
    estimatedDates,
    deliveryDate,
    addressIssue,
    billingData,
    invoiceUrl,
    id,
    isPaymentZero,
    paymentType
  } = logisticServiceData || {};
  const { collect } = estimatedDates || {};
  const serviceIsCanceled = status === 'cancelado';
  const hasTrackingData = !!trackingCode || !!trackingLink;
  const translationKey = 'nodi:logisticServices:logisticServicePaymentSection:';
  const haveSomeDeliveredData = hasTrackingData || !!Object.values(collect || {}).length || !!deliveryDate;
  const ifDeliveredServiceHaveData = status !== 'entregado' || haveSomeDeliveredData;
  const originAddressEditedByUser = addressIssue?.origin?.editedBy === 'user';
  const destinationAddressEditedByUser = addressIssue?.destination?.editedBy === 'user';
  const originIssueReported = addressIssue?.origin?.issueReported;
  const destinationIssueReported = addressIssue?.destination?.issueReported;
  const paymentIsCare = paymentType === 'Care';
  const displayInvoice = !loading && !serviceIsCanceled && !paymentIsCare;

  return (
    <div className="LogisticsDetailsSummary">
      <div className="logisticTrackingAndLocationSection">
        <LogisticServiceTrackingSection
          logisticServiceData={logisticServiceData}
          isLoading={loading}
          haveSomeDeliveredData={haveSomeDeliveredData}
        />
        {!serviceIsCanceled && ifDeliveredServiceHaveData && <div className="divider" />}
        <LogisticServiceLocationCard
          isLoading={loading}
          tipeOfCard="origin"
          logisticServiceData={logisticServiceData}
          getLogisticServiceDetailCallback={getLogisticServiceDetailCallback}
          issueReported={
            !originAddressEditedByUser && originIssueReported && originIssueReported?.length > 0
              ? originIssueReported?.[originIssueReported.length - 1]
              : undefined
          }
        />
        <div className="divider" />
        <LogisticServiceLocationCard
          isLoading={loading}
          tipeOfCard="destination"
          logisticServiceData={logisticServiceData}
          getLogisticServiceDetailCallback={getLogisticServiceDetailCallback}
          issueReported={
            !destinationAddressEditedByUser && destinationIssueReported && destinationIssueReported?.length > 0
              ? destinationIssueReported?.[destinationIssueReported.length - 1]
              : undefined
          }
        />
      </div>
      {!isPaymentZero && (
        <>
          <LogisticServicePaymentSection
            logisticServiceData={logisticServiceData}
            isLoading={loading}
            setLoadingCallback={setLoadingCallback}
            updateDataCallback={() => getLogisticServiceDetailCallback()}
          />
          <BordBillingDataSection
            updateDataCallaback={() => getLogisticServiceDetailCallback()}
            orderOrLogisticId={id || undefined}
            billingData={billingData || {}}
            loading={loading}
            disableEditingOfBillingData={!!serviceIsCanceled}
          />
          {displayInvoice && (
            <div className="invoiceUrlContainer">
              <div className="divider" />
              Factura del servicio
              {!!invoiceUrl && (
                <NudosDownloadDocumentButton
                  buttonText={t(`${translationKey}invoice`)}
                  downloadLink={invoiceUrl || ''}
                  height={18}
                  width={105}
                />
              )}
              {!invoiceUrl && (
                <div className="emptyInvoiceText">
                  Podrás consultar tu factura en esta sección cuando esté disponible
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LogisticsDetailsSummary;

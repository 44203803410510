import { useState } from 'react';
import { IorderDetails, IshipmentDetails } from '../../../../../types/orders';
import { blurRectangle } from '../../../../../assets/images/components';
import { formatCost, nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import { truncateText } from '../../../../../utils/truncateText';
import { useTranslation } from 'react-i18next';
import { NoticeProblemsWithAddresses } from '../NoticeProblemsWithAddresses';
import { AlertTextComponent } from '../AlertTextComponent';
import { getNameByPlace } from '../../../../../utils/getNameByPlace';
import { NudosToolTip } from '../../../../../components/NudosComponents';
import './OrderDetailsSummary.scss';

/**
 * @property {IorderDetails} orderData - Required, the information of the order with the specified orderId. This component required the properties references, shipmentsCount, productsCount, items, subtotalUsd, totalUsd, commissionUsd of the orderData to correctly display its information.
 */
const OrderDetailsSummary = ({
  orderData,
  problemsWithAddresses,
  addressesHaveBeenUpdated,
  editShipments,
  getOrderDatawithoutLoader
}: {
  orderData: IorderDetails;
  problemsWithAddresses: boolean;
  addressesHaveBeenUpdated: boolean;
  editShipments?: IshipmentDetails;
  getOrderDatawithoutLoader: () => void;
}) => {
  const { t } = useTranslation();

  const [showBannerErrorInAddressesOrEditon, setShowBannerErrorInAddressesOrEditon] = useState<boolean>(true);

  const {
    references,
    shipmentsCount,
    productsCount,
    items,
    subtotalUsd,
    commissionUsd,
    shimpentsInfo,
    taxValueUsd,
    billingMethod,
    taxesToCalculate
  } = orderData;
  const orderSubTotal = subtotalUsd ? `$${formatCost(+subtotalUsd, 2)} USD` : 'Sin datos';
  const taxesTotal = `${nudosFormatCurrency({ value: Number(taxValueUsd || 0), maximumFractionDigits: 2 })} USD`;
  const orderCommission = commissionUsd ? `$${formatCost(+commissionUsd, 2)} USD` : '$0.0 USD';
  const noCommisionStyle = !commissionUsd || orderCommission === '$0.0 USD' ? 'zero' : '';

  const billingCountryisAr = billingMethod?.billingEntityCountryCode === 'ar';
  const showTaxesToCalculate = taxesToCalculate;
  const allShipmentsInThisOrder = Object.values(shimpentsInfo || {}).flat();
  const i18nLanguageKey = 'nodi:orderDetails:orderDetailsSummary:';

  const tooltipTaxContainer = <>{t(`${i18nLanguageKey}tooltipTaxesTex`)}</>;
  const totalValue = `$${formatCost(
    Number(orderData?.currency === 'USD' || orderData?.currency === 'ARS' ? orderData?.totalUsd : orderData?.total || 0)
  )}`;

  const getShipmentsPriceData = () => {
    const currency = 'USD';
    let currentShipmentIndex = 1;
    let currentShipmentCountry = '';
    const shipmentsInformation = allShipmentsInThisOrder?.map((item, i) => {
      if (currentShipmentCountry !== item?.country) currentShipmentIndex = 1;
      else currentShipmentIndex += 1;
      currentShipmentCountry = item?.country || '';
      const countryFlag = item?.flag;
      const destinationNameByPlace = getNameByPlace({
        name: item.destinationName ?? '',
        place: item.destinationPlace ?? ''
      });

      const shipmentText = truncateText(destinationNameByPlace, 20);
      const shipmentPriceValue = item?.shipmentValueUsd || 0;
      const lineThroughStyles = +shipmentPriceValue > 0 ? '' : 'line-through';
      const title = (
        <div className="title shipmentTitle" key={`shipmentPriceTitle${i}`}>
          <div className="shipmentNumber">
            {t(`${i18nLanguageKey}shipmentNumber`)} {currentShipmentIndex} -
          </div>
          <div className="flag" style={{ backgroundImage: `url(${countryFlag})` }} />
          <div className="shipmentText">{shipmentText}</div>
        </div>
      );
      const value = (
        <div className="value shipmentValue" key={`shipmentPriceValue${i}`}>
          <div className={`shipmentPrice ${lineThroughStyles}`}>
            ${formatCost(+(shipmentPriceValue || 0))} {currency}
          </div>
        </div>
      );
      return { title, value };
    });
    return shipmentsInformation;
  };

  const changeStatusBanners = (status: boolean) => setShowBannerErrorInAddressesOrEditon(status);

  return (
    <div className="orderDetailsSummary">
      <div className={`summaryTitleContainer ${problemsWithAddresses ? 'titleWhithProblems' : ''}`}>
        <div className="summaryTitle">{t(`${i18nLanguageKey}summaryTitle`)}</div>
        {(problemsWithAddresses || addressesHaveBeenUpdated) && (
          <AlertTextComponent
            changeStatusBanners={() => changeStatusBanners(true)}
            problemsWithAddresses={problemsWithAddresses}
            addressesHaveBeenUpdated={addressesHaveBeenUpdated}
            showSeeMore={!showBannerErrorInAddressesOrEditon}
          />
        )}
      </div>
      {(problemsWithAddresses || addressesHaveBeenUpdated) && showBannerErrorInAddressesOrEditon && (
        <NoticeProblemsWithAddresses
          changeStatusBanner={() => changeStatusBanners(false)}
          problemsWithAddresses={problemsWithAddresses}
          addressesHaveBeenUpdated={addressesHaveBeenUpdated}
          editShipments={editShipments}
          getOrderDatawithoutLoader={getOrderDatawithoutLoader}
        />
      )}{' '}
      <div className="summaryContainer">
        <div className="referencesSummary">
          <div className="titles">
            <div className="title references">{t(`${i18nLanguageKey}titleReferences`)}:</div>
            <div className="title products">{t(`${i18nLanguageKey}titleProducts`)}</div>
            <div className="title shipments">{t(`${i18nLanguageKey}titleShipments`)}</div>
          </div>
          <div className="values">
            <div className="value references">{references || t(`${i18nLanguageKey}values`)}</div>
            <div className="value products">{productsCount || t(`${i18nLanguageKey}values`)}</div>
            <div className="value shipments">{shipmentsCount || t(`${i18nLanguageKey}values`)}</div>
          </div>
        </div>
        <div className="referencesDetails">
          <div className="titles">
            <div className="reference">{t(`${i18nLanguageKey}titleReferences`)}</div>
            <div className="count">{t(`${i18nLanguageKey}count`)}</div>
            <div className="total">Total</div>
          </div>
          <div className="values">
            {items?.map((item, i) => {
              return (
                <div key={`item${i}${item?.name}`} className="referenceItem">
                  <div className="reference">{item?.name || t(`${i18nLanguageKey}values`)}</div>
                  <div className="count">
                    <div className="countValue">{item?.quantity || t(`${i18nLanguageKey}values`)}</div>
                    <div className="flag" style={{ backgroundImage: `url(${item?.flag})` }} />
                  </div>
                  <div className="total">
                    $
                    {typeof item?.totalUsd === 'number' ? formatCost(item?.totalUsd, 2) : t(`${i18nLanguageKey}values`)}
                    USD
                  </div>
                </div>
              );
            })}
          </div>
          <img alt="blurr" className="blurr" src={blurRectangle} />
        </div>
        <div className="valuesSummary">
          <div className="titles">
            <div className="title subtotal">Subtotal:</div>
            {getShipmentsPriceData()?.map(shipment => shipment?.title)}
            <div className="title commission">{t(`${i18nLanguageKey}titleCommission`)}</div>
            {billingCountryisAr && (
              <div className="title subtotal flex">
                {t(`${i18nLanguageKey}taxes`)}
                {showTaxesToCalculate && (
                  <div className="mt-1 ml-0.5">
                    <NudosToolTip tooltipContent={tooltipTaxContainer} />
                  </div>
                )}
              </div>
            )}{' '}
            <div className="title total">Total:</div>
          </div>
          <div className="values">
            <div className="value subtotal">{orderSubTotal}</div>
            {getShipmentsPriceData()?.map(shipment => shipment?.value)}
            <div className={`value commission ${noCommisionStyle}`}>{orderCommission}</div>
            {billingCountryisAr && (
              <div className="value subtotal">
                {showTaxesToCalculate ? (
                  <div className="blueText">{t(`${i18nLanguageKey}toBeCalculated`)}</div>
                ) : (
                  taxesTotal
                )}
              </div>
            )}
            <div className="value total">
              {totalValue} {orderData?.currency === 'ARS' ? 'USD' : orderData.currency}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderDetailsSummarySkeletonLoader = () => {
  return (
    <div className="orderDetailsSummarySkeletonLoader">
      <div className="summaryTitleSkeleton animationLoader" />
      <div className="summaryContainerSkeleton">
        <div className="referencesSummarySkeleton animationLoader" />
        <div className="referencesDetailsSkeleton animationLoader" />
        <div className="valuesSummarySkeleton animationLoader" />
      </div>
    </div>
  );
};

export { OrderDetailsSummary, OrderDetailsSummarySkeletonLoader };

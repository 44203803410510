import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import deepEqual from 'deep-equal';
import { NudosBreadcrumbButton, NudosButton } from '../../../../../components/NudosComponents';
import useCheckoutStore from '../../state';
import CheckoutSummary from '../CheckoutSummary';
import { ICountryDetail } from '../../../../../types/countries';
import {
  createOrderPayment,
  createCartOrder,
  putProcessOrder,
  putUpdateBillingData,
  requestQuotationPdf
} from '../../../../../services/checkout';
import {
  ICartInfo,
  IInvoicePaymentMethods,
  IShipmentGroupedByDestinationLocationAndProductId,
  IShipmentToASingleCountry,
  IShippingFormat,
  IShippingSummary,
  IShippingTotals,
  ISummaryItem
} from '../../../../../types/cart';
import useShopStore from '../../../../../state/useShopContext';
import useCartState from '../../../../../state/useCartState';
import { productsNumber, productsOnly } from '../../../../../utils/cart';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import {
  IbillingData,
  IcreateOrderPaymentDto,
  IstripeCheckoutPaymentSessionData,
  ORDER_PAYMENT_STRIPE_METHOD,
  PAYMENT_TYPES,
  SPECIAL_BUSINESS_NAME_TYPES,
  STRIPE_PAYMENT_PROVIDERS,
  TCurrencyCountryCode
} from '../../../../../types/checkout';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { formatCost, nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import {
  costForPaidShipments,
  lowerLimitForFreeShipping,
  minimumShippingCost
} from '../../../../../utils/productDefinitions';
import PaymentMethodSection from './components/PaymentMethodSection/PaymentMethodSection';
import { getShipmentsPricingDataByDestination, groupShipmentsByLocationAndProductId } from '../../checkout.utils';
import BillingDataSection from './components/BillingDataSection/BillingDataSection';
import useCheckoutController from '../../checkout.controller';
import { ShipmentSubtotalTooltip } from '../../../../../components/NudosPrimeOrFree';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { calculatePercentage } from '../../../../../utils/calculatePercentage';
import { segmentTrackEvent, deleteAndCreateNewID } from '../../../../../utils/segment';
import { getShoppingCart } from '../../../../../services/cart.services';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import { DownloadIcon } from '../../../../../assets/images/components/NudosComponents';
import { BILLING_METHODS } from '../../../../../types/orders';
import {
  BILLING_METHODS_WITH_INTEGRATED_PAYMENTS,
  VALID_AND_INTEGRATED_METHODS_TO_OPEN_THE_STRIPE_MODAL,
  VALID_AND_INTEGRATED_TRANSFER_METHODS
} from '../../../../../constants';
import './Step3InvoiceCart.scss';

interface ISummaryTotal {
  subtotal?: number;
  commisionTotal?: number;
  total?: number;
  shipmentsCostUsd?: number;
}

interface IHandleCreateOrderPayment {
  createPaymenMethodBody: IcreateOrderPaymentDto;
  orderPaymentStripeMethod: ORDER_PAYMENT_STRIPE_METHOD;
  orderId: number;
}

interface IHandleProcessOrder {
  orderId: number;
}

interface Istep3InvoiceCartProps {
  listOfCountriesOpenForBuying?: ICountryDetail[];
  completeListOfCountries?: ICountryDetail[];
  billingDataByCountry?: IbillingData;
  setBillingDataByCountry: React.Dispatch<React.SetStateAction<IbillingData | undefined>>;
  billingDataWasSubmited: boolean;
  setBillingDataWasSubmited: React.Dispatch<React.SetStateAction<boolean>>;
  showBillingData: boolean;
  setShowBillingData: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBillingCountry?: ICountryDetail;
  setSelectedBillingCountry: React.Dispatch<React.SetStateAction<ICountryDetail | undefined>>;
  openModalInvalidCart?: () => void;
  handleChangeSelectPromiseStripe?: (stripeProvider: STRIPE_PAYMENT_PROVIDERS) => void;
}

const Step3InvoiceCart = ({
  listOfCountriesOpenForBuying,
  completeListOfCountries,
  openModalInvalidCart,
  handleChangeSelectPromiseStripe
}: Istep3InvoiceCartProps) => {
  const { t } = useTranslation();
  const orgInfo = formatOrgData();
  const { itemsCart, setCart } = useShopStore();
  const { deleteAndCreateNewCart } = useCheckoutController();
  const { stateSubscription } = useStateSubscriptions();
  const { globalShippingCart, setDataFinishCart } = useCartState();
  const totalProducts = productsNumber();
  const productsCart = productsOnly();
  const { storeCountry } = useStoreCountryState();
  const { setStep, setPaymentSession, paymentSession, checkoutBillingData, invoiceAdditionalEmail } =
    useCheckoutStore();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IInvoicePaymentMethods>();
  const [loadingCreateOrder, setLoadingCreateOrder] = useState<boolean>(false);
  const [loadingUpdateBillingData, setLoadingUpdateBillingData] = useState<boolean>(false);
  const [summary, setSummary] = useState<IShippingSummary>();
  const [totalSaved, setTotalSaved] = useState<number>(0);
  const [quotationPdfUrl, setQuotationPdfUrl] = useState('');
  const [loadingQuotation, setLoadingQuotation] = useState(false);
  const [lastQuotationItems, setLastQuotationItems] = useState<ICartInfo>();
  const [summaryTotals, setSummaryTotals] = useState<ISummaryTotal>({
    subtotal: 0,
    commisionTotal: 0,
    total: 0
  });
  const [totalsShipping, setTotalsShipping] = useState<IShippingTotals>({
    freeTotalFormat: '$0 USD',
    freeTotal: 0,
    primeTotalFormat: '$0 USD',
    primeTotal: 0,
    totalShippingDiscount: 0
  });

  const { isPrime } = stateSubscription;
  const translationKey = 'nodi:checkout:step1ShoppingCart:';
  const showTaxesToCalculate = ['ar'].includes(checkoutBillingData?.countryCode || '');
  const billingDataCountryId = checkoutBillingData?.documentCountry?.id || 0;
  const billingDataCountryCode = checkoutBillingData?.documentCountry?.code || '';
  const paymentMethodName = selectedPaymentMethod?.name;
  const specialCountriesCode: string[] = ['uy', 'mx', 'pe', 'co', 'ar'];
  const countryIsArgentina = billingDataCountryCode === 'ar';
  const currencyCode = !countryIsArgentina ? selectedPaymentMethod?.currency?.code : 'USD';
  const currencyArs = 'ARS';
  const returnText = t(`recurrentWords:shipments`);
  const shoppingCartIsEmpty = !itemsCart?.numberOfProducts || !itemsCart?.references;
  const taxDiscountValue = !isPrime ? Number(itemsCart?.totalTaxWithoutPrime) : Number(itemsCart?.totalTaxWithPrime);
  const businessInSpecialZone =
    !!checkoutBillingData?.specialBusinessNameTypeId &&
    checkoutBillingData?.specialBusinessNameTypeId === SPECIAL_BUSINESS_NAME_TYPES.FREE_ZONE &&
    storeCountry?.countryCode === 'uy';
  const uyTaxesHidden = showTaxesToCalculate || !businessInSpecialZone;

  const checkoutSummaryFirstSectionListingElements = {
    [t('recurrentWords:referencesName')]: itemsCart?.references || 0,
    [t('recurrentWords:products')]: totalProducts || 0,
    [t('recurrentWords:numberOfShipments')]: summary?.totalShippings || 0
  };

  const checkoutSummarySecondSectionListingElements = {
    subtotal: `$${formatCost(itemsCart?.total || 0, 2)} ${'USD'}`,
    commission: `$${formatCost(summaryTotals?.commisionTotal || 0, 2)} USD`
  };

  const usdTotal = `${nudosFormatCurrency({
    value: Number(isPrime ? itemsCart?.total || 0 : itemsCart?.totalWithoutPrime || 0),
    maximumFractionDigits: 2
  })} USD`;

  const getCountryIdBasedOnCode = (countryCode: string) => {
    if (!completeListOfCountries) return 0;
    for (const country of completeListOfCountries) {
      if (country?.code === countryCode) return country?.id;
    }
    return 0;
  };

  const getCountryIdBasedOnPaymentMethod = () => {
    if (paymentMethodName?.includes('Transf. USD')) return getCountryIdBasedOnCode('uy');
    if (paymentMethodName?.includes('Tarjeta')) return getCountryIdBasedOnCode('mx');
    return 0;
  };

  const orderBillingDataCountryId = specialCountriesCode?.includes(billingDataCountryCode)
    ? billingDataCountryId
    : getCountryIdBasedOnPaymentMethod();

  const summaryAction = () => {
    const enabledContinue = !globalShippingCart?.shipments?.find(element => !element.destinationLocationId);
    const shippingNumber = groupShipmentsByLocationAndProductId(globalShippingCart);
    const shipmentsPricingDataByDestination = getShipmentsPricingDataByDestination(shippingNumber?.shippingsList);
    setSummary({
      enabledContinue,
      totalShippings: shippingNumber?.totalShippings,
      shippingsList: shippingNumber?.shippingsList || [],
      shipmentsPricingDataByDestination
    });
  };

  const singleCountryShippingFormat = () => {
    const shoppingCartsItemsWithShipmentInfo = globalShippingCart?.shipments;
    if (!shoppingCartsItemsWithShipmentInfo || shoppingCartsItemsWithShipmentInfo?.length <= 0) return [];
    const formattedShipments: IShipmentToASingleCountry[] = [];
    for (let i = 0; i < shoppingCartsItemsWithShipmentInfo.length; i++) {
      const item = shoppingCartsItemsWithShipmentInfo[i];
      const existingIdenticalShipment = formattedShipments.find(
        element => element.locationId === item.destinationLocationId && element?.productsIds[0] === item.productId
      );
      if (existingIdenticalShipment) {
        existingIdenticalShipment.productsIds = [...existingIdenticalShipment.productsIds, Number(item.productId)];
      } else if (item?.productId) {
        const newItem: IShipmentToASingleCountry = {
          productsIds: [Number(item.productId)],
          locationId: item?.destinationLocationId || 0,
          destinationCountry: storeCountry?.countryName || '',
          receiverInformation: {
            receiverName: item?.receiverInformation?.receiverName || '',
            receiverPhone: item?.receiverInformation?.receiverPhone || '',
            personalId: item?.receiverInformation?.personalId || ''
          }
        };
        formattedShipments.push(newItem);
      }
    }
    const shippingFormatToGenerateTheOrder: IShippingFormat = {
      countryCode: storeCountry?.countryCode || '',
      destinationLocations: formattedShipments
    };
    return shippingFormatToGenerateTheOrder;
  };

  const isBillingDataComplete = () => {
    if (!checkoutBillingData?.id || !checkoutBillingData?.name || !checkoutBillingData?.organizationId) return false;
    if (!checkoutBillingData?.businessName || !checkoutBillingData?.document) return false;
    if (!checkoutBillingData?.address || !checkoutBillingData?.additionalReferences) return false;
    if (!checkoutBillingData?.zipCode || !checkoutBillingData?.city || !checkoutBillingData?.state) return false;
    if (!checkoutBillingData?.email || !checkoutBillingData?.documentCountry) return false;
    if (!checkoutBillingData?.documentCountry?.id) return false;
    return true;
  };

  const handleProcessOrder = async ({ orderId }: IHandleProcessOrder) => {
    await putProcessOrder(orderId);
    await deleteAndCreateNewCart();
    deleteAndCreateNewID();
    segmentTrackEvent({ generalOrderCompleted: { OrderCreated: true } });
    return setStep(3);
  };

  const handleCreateOrderPayment = async ({
    createPaymenMethodBody,
    orderPaymentStripeMethod,
    orderId
  }: IHandleCreateOrderPayment) => {
    const paymentData = await createOrderPayment({
      ...createPaymenMethodBody,
      paymentMethod: orderPaymentStripeMethod
    });

    if (orderPaymentStripeMethod === ORDER_PAYMENT_STRIPE_METHOD.CARD) {
      const stripeProviderData = paymentData as IstripeCheckoutPaymentSessionData;
      if (handleChangeSelectPromiseStripe && stripeProviderData.stripeProvider) {
        handleChangeSelectPromiseStripe(stripeProviderData.stripeProvider);
      }
      const paymentSession = {
        ...(paymentData as IstripeCheckoutPaymentSessionData),
        orderId
      };
      localStorage.setItem('paymentSession', JSON.stringify(paymentSession));
      paymentSession && setPaymentSession({ ...paymentSession });
      return;
    }
    await handleProcessOrder({ orderId });
  };

  const createOrder = async () => {
    if (!isBillingDataComplete() || !orgInfo?.organizationId || paymentSession) return;
    setLoadingCreateOrder(true);
    segmentTrackEvent({
      [!isPrime ? 'freeBillingAndPaymentClick' : 'primeBillingAndPaymentClick']: {
        UsdTotal: `${nudosFormatCurrency({
          value: Number(!isPrime ? finalFreeTotal : primefinalPrimeTotal),
          maximumFractionDigits: 2
        })} USD`,

        PaymentMethods: selectedPaymentMethod?.name
      }
    });
    const dataCart = await getShoppingCart(Number(orgInfo?.userId), Number(orgInfo?.organizationId));
    if (!dataCart?.shoppingCartId && openModalInvalidCart) {
      setCart(dataCart);
      localStorage.setItem('bagItems', JSON.stringify(dataCart));
      return openModalInvalidCart();
    }

    const bodyOrder = {
      notificationEmail: invoiceAdditionalEmail,
      organizationId: orgInfo?.organizationId || 0,
      userId: orgInfo?.userId || 0,
      paymentType: selectedPaymentMethod?.name || '',
      needInvoice: selectedPaymentMethod?.extraData?.needInvoice || false,
      shoppingCartId: itemsCart?.shoppingCartId || 0,
      billingCountryId: orderBillingDataCountryId,
      billingData: {
        id: checkoutBillingData?.id || 0,
        countryCode: checkoutBillingData?.countryCode,
        organizationId: checkoutBillingData?.organizationId || 0,
        name: checkoutBillingData?.name || '',
        businessName: checkoutBillingData?.businessName || '',
        specialBusinessNameTypeId: checkoutBillingData?.specialBusinessNameTypeId,
        document: checkoutBillingData?.document || '',
        address: checkoutBillingData?.address || '',
        additionalReferences: checkoutBillingData?.additionalReferences || '',
        zipCode: checkoutBillingData?.zipCode || '',
        city: checkoutBillingData?.city || '',
        state: checkoutBillingData?.state || '',
        createdAt: checkoutBillingData?.createdAt || '',
        updatedAt: checkoutBillingData?.updatedAt || '',
        email: checkoutBillingData?.email || '',
        documentCountry: checkoutBillingData?.documentCountry,
        taxSituationDocUrl: checkoutBillingData?.taxSituationDocUrl || '',
        taxSituation: checkoutBillingData?.taxSituation,
        taxUse: checkoutBillingData?.taxUse,
        phone: checkoutBillingData?.phone,
        phoneCountry: checkoutBillingData?.phoneCountry,
        phoneCountryId: checkoutBillingData?.phoneCountryId,
        fantasyName: checkoutBillingData?.fantasyName
      },
      shipments: singleCountryShippingFormat()
    };
    try {
      const orderResponse = await createCartOrder(bodyOrder);
      if (orderResponse) {
        const currencyCode = orderResponse.currency !== currencyArs ? orderResponse.currency : 'USD';

        const paymentType = orderResponse?.paymentType as PAYMENT_TYPES;
        const billingMethodName = orderResponse?.billingMethod?.billingMethodName as BILLING_METHODS;
        const methodIsIntegratedWithStripe =
          VALID_AND_INTEGRATED_TRANSFER_METHODS?.includes(paymentType) ||
          VALID_AND_INTEGRATED_METHODS_TO_OPEN_THE_STRIPE_MODAL?.includes(paymentType);
        const orderBillingMethodIsIntegrated = BILLING_METHODS_WITH_INTEGRATED_PAYMENTS?.includes(billingMethodName);

        const mexicanUsdTransfer =
          orderResponse.billingMethod?.destinationCountryCode === 'mx' &&
          orderResponse.paymentType === PAYMENT_TYPES.TRANSFER_USD;

        const mexicanUsdLink =
          orderResponse.billingMethod?.destinationCountryCode === 'mx' &&
          orderResponse.paymentType === PAYMENT_TYPES.INTERNATIONAL_CARD;

        const mustCreateOrderPayment =
          !orderResponse.requirePO &&
          methodIsIntegratedWithStripe &&
          orderBillingMethodIsIntegrated &&
          !mexicanUsdTransfer &&
          !mexicanUsdLink;

        const orderTotalValue =
          orderResponse.currency !== 'USD' && !countryIsArgentina
            ? Number(orderResponse?.totalLocalCurrency || 0)
            : Number(orderResponse?.total || 0);
        setDataFinishCart({
          paymentType: orderResponse?.paymentType,
          orderId: orderResponse.id,
          orderTotal: orderTotalValue,
          shippings: summary?.totalShippings || 0,
          currencyCode: currencyCode,
          bankAccountInfo: orderResponse?.bankAccountInfo,
          summary: summary
        });
        const createPaymenMethodBody: IcreateOrderPaymentDto = {
          orderId: orderResponse.id,
          userData: {
            organizationId: orgInfo?.organizationId
          }
        };
        if (mustCreateOrderPayment) {
          const isTransfer = VALID_AND_INTEGRATED_TRANSFER_METHODS?.includes(paymentType);
          await handleCreateOrderPayment({
            createPaymenMethodBody: createPaymenMethodBody,
            orderPaymentStripeMethod: isTransfer
              ? ORDER_PAYMENT_STRIPE_METHOD.BANK_TRANSFER
              : ORDER_PAYMENT_STRIPE_METHOD.CARD,
            orderId: orderResponse.id
          });
        } else {
          await handleProcessOrder({ orderId: orderResponse.id });
        }
      }
    } catch (err) {
      const error = err as AxiosError;

      const errorMessage =
        error?.response?.data?.message ||
        'Los datos de facturación no son válidos, deben coincidir con los registrados en el SAT. Por favor revísalos e intenta de nuevo';
      if (error?.response?.data?.error === 'Billing Data') {
        return displayErrorNotification({
          customJSXMessage: <>{errorMessage}</>
        });
      }
      if (
        (error?.response?.status === 409 ||
          error?.response?.data?.message === "Error in createOrder : Cannot read property 'items' of undefined") &&
        openModalInvalidCart
      ) {
        const fakeCart = {
          shoppingCartId: null,
          userId: orgInfo?.userId || 0,
          organizationId: orgInfo?.organizationId || 0,
          references: null,
          numberOfProducts: null,
          total: null,
          products: null,
          totalDiscountValue: null,
          totalWithoutPrime: null
        };
        setCart(fakeCart);
        localStorage.setItem('bagItems', JSON.stringify(fakeCart));
        return openModalInvalidCart();
      }
      displayErrorNotification();
    } finally {
      setLoadingCreateOrder(false);
    }
  };

  const getSummaryTotals = (currencyCode: string, transactionFee: number, exchangeRate: number) => {
    const subtotal = itemsCart?.total || 0;
    const currencyIsNotUsd = currencyCode !== 'USD' && !countryIsArgentina;
    const shipmentsCostUsd = summary?.shippingsList?.reduce((previousVal, currentVal) => {
      const shipmentsPricingDataByDestination = summary?.shipmentsPricingDataByDestination || {};
      const thisShipmentDestinationProductsPricingData =
        shipmentsPricingDataByDestination[currentVal?.destinationLocationId || 0];
      const currentShippingCost =
        +thisShipmentDestinationProductsPricingData >= lowerLimitForFreeShipping ? 0 : costForPaidShipments;
      const newValue = previousVal + currentShippingCost;
      return newValue;
    }, 0);
    const subtotalPlusShipmentsUsd = subtotal + (shipmentsCostUsd || 0);
    const comissionTotalUsd = subtotalPlusShipmentsUsd * (transactionFee / 100);
    const total = subtotalPlusShipmentsUsd + comissionTotalUsd;
    const totalInLocalCurrency = total * exchangeRate;
    return setSummaryTotals({
      subtotal,
      commisionTotal: comissionTotalUsd,
      total: currencyIsNotUsd ? totalInLocalCurrency : total,
      shipmentsCostUsd
    });
  };

  const isButtonDisabled = () => {
    if (!selectedPaymentMethod) return true;
    if (!isBillingDataComplete() || !summary?.enabledContinue) return true;
    return false;
  };

  const updateBillingData = async () => {
    if (!orgInfo?.organizationId) return;
    setLoadingUpdateBillingData(true);
    const updatedBillingDataBody: IbillingData = {
      businessName: checkoutBillingData?.businessName || null,
      document: checkoutBillingData?.document,
      address: checkoutBillingData?.address,
      additionalReferences: checkoutBillingData?.additionalReferences || null,
      zipCode: checkoutBillingData?.zipCode || null,
      city: checkoutBillingData?.city || null,
      state: checkoutBillingData?.state || null,
      email: checkoutBillingData?.email,
      documentCountry: checkoutBillingData?.documentCountry,
      taxSituationDocUrl: checkoutBillingData?.taxSituationDocUrl || null,
      taxSituation: checkoutBillingData?.taxSituation,
      taxUse: checkoutBillingData?.taxUse,
      phone: checkoutBillingData?.phone || null,
      phoneCountryId: checkoutBillingData?.phoneCountryId || null,
      fantasyName: checkoutBillingData?.fantasyName || null,
      id: checkoutBillingData?.id || null,
      countryId: checkoutBillingData?.countryId || null,
      countryCode: checkoutBillingData?.documentCountry?.code,
      organizationId: checkoutBillingData?.organizationId || null,
      name: checkoutBillingData?.name || null,
      documentCountryId: checkoutBillingData?.documentCountryId || null,
      street: checkoutBillingData?.street || null,
      number: checkoutBillingData?.number,
      internalNumber: checkoutBillingData?.internalNumber || null,
      colony: checkoutBillingData?.colony || null,
      createdAt: checkoutBillingData?.createdAt || null,
      updatedAt: checkoutBillingData?.updatedAt || null,
      deletedAt: checkoutBillingData?.deletedAt || null,
      phoneCountry: checkoutBillingData?.phoneCountry || null
    };
    try {
      await putUpdateBillingData(orgInfo?.organizationId, updatedBillingDataBody);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingUpdateBillingData(false);
    }
  };

  const primeTotalShipments = Number(totalsShipping.primeTotal || 0);
  const primeTotalCart = Number(itemsCart?.total || 0);
  const primeTotals = primeTotalShipments + primeTotalCart;
  const primeTotalCommision = 0;
  const primefinalPrimeTotal = primeTotals + primeTotalCommision;

  const freeTotalShipments = Number(totalsShipping.freeTotal || 0);
  const freeTotalCart = Number(itemsCart?.totalWithoutPrime || 0);
  const freeTotals = freeTotalShipments + freeTotalCart;
  const freeTotalCommision = calculatePercentage({
    percentage: Number(selectedPaymentMethod?.transactionFee || 0),
    total: Number(freeTotals)
  });
  const finalFreeTotal = freeTotalShipments + freeTotalCart + freeTotalCommision;

  const totalPriceWithTaxDiscount = isPrime
    ? itemsCart?.totalWithPrimeNetOfTaxes
    : itemsCart?.totalWithoutPrimeNetOfTaxes;

  const mainAmountCalc = () => {
    const currencyIsNotUsd = currencyCode !== 'USD' && !countryIsArgentina;
    if (businessInSpecialZone)
      return `${nudosFormatCurrency({ value: Number(totalPriceWithTaxDiscount), maximumFractionDigits: 2 })} USD`;
    if (isPrime) {
      if (currencyIsNotUsd && selectedPaymentMethod) {
        const totalLocalCurrency = primefinalPrimeTotal * Number(selectedPaymentMethod?.exchangeRate || 1);
        return `${nudosFormatCurrency({
          value: totalLocalCurrency,
          maximumFractionDigits: 2,
          currencyCountryCode: selectedPaymentMethod?.currency?.code as TCurrencyCountryCode
        })} ${selectedPaymentMethod?.currency?.code}`;
      }
      return `${nudosFormatCurrency({ value: Number(primefinalPrimeTotal), maximumFractionDigits: 2 })} USD`;
    }

    if (currencyIsNotUsd && selectedPaymentMethod) {
      const totalLocalCurrency = finalFreeTotal * Number(selectedPaymentMethod?.exchangeRate || 1);
      return `${nudosFormatCurrency({
        value: totalLocalCurrency,
        maximumFractionDigits: 2,
        currencyCountryCode: selectedPaymentMethod?.currency?.code as TCurrencyCountryCode
      })} ${selectedPaymentMethod?.currency?.code}`;
    }
    return `${nudosFormatCurrency({ value: Number(finalFreeTotal), maximumFractionDigits: 2 })} USD`;
  };

  const secondaryAmountCalc = () => {
    const currencyIsNotUsd = currencyCode !== 'USD' && !countryIsArgentina;

    if (isPrime) {
      const totalShipments = Number(totalsShipping.freeTotal || 0);
      const totalCart = Number(itemsCart?.totalWithoutPrime || 0);
      const totals = totalShipments + totalCart;
      const totalCommision = calculatePercentage({
        percentage: Number(selectedPaymentMethod?.transactionFee || 0),
        total: Number(totals)
      });
      const finalPrimeTotal = totals + totalCommision;
      if (currencyIsNotUsd && selectedPaymentMethod) {
        const totalLocalCurrency = finalPrimeTotal * Number(selectedPaymentMethod?.exchangeRate || 1);
        return `${nudosFormatCurrency({
          value: totalLocalCurrency,
          maximumFractionDigits: 2,
          currencyCountryCode: selectedPaymentMethod?.currency?.code as TCurrencyCountryCode
        })} ${selectedPaymentMethod?.currency?.code}`;
      }
      return `${nudosFormatCurrency({ value: Number(finalPrimeTotal), maximumFractionDigits: 2 })} USD`;
    }
    const totalShipments = Number(totalsShipping.primeTotal || 0);
    const totalCart = Number(itemsCart?.total || 0);
    const totals = totalShipments + totalCart;
    const totalCommision = 0;
    const finalFreeTotal = totals + totalCommision;
    if (currencyIsNotUsd && selectedPaymentMethod) {
      const totalLocalCurrency = finalFreeTotal * Number(selectedPaymentMethod?.exchangeRate || 1);
      return `${nudosFormatCurrency({
        value: totalLocalCurrency,
        maximumFractionDigits: 2,
        currencyCountryCode: selectedPaymentMethod?.currency?.code as TCurrencyCountryCode
      })} ${selectedPaymentMethod?.currency?.code}`;
    }
    return `${nudosFormatCurrency({ value: Number(finalFreeTotal), maximumFractionDigits: 2 })} USD`;
  };

  const calcPaymentCommision = () => {
    if (isPrime) {
      const totalShipments = Number(totalsShipping.primeTotal || 0);
      const totalCart = Number(itemsCart?.total || 0);
      const totalTransactionFee = selectedPaymentMethod?.transactionFee || 0;
      const finalTotals = Number(totalShipments) + Number(totalCart);
      const calcPercent = calculatePercentage({
        percentage: Number(totalTransactionFee || 0),
        total: finalTotals
      });
      return `${nudosFormatCurrency({ value: calcPercent, maximumFractionDigits: 2 })} USD`;
    }
    const totalShipments = Number(totalsShipping.freeTotal || 0);
    const totalCart = Number(itemsCart?.totalWithoutPrime || 0);
    const totalTransactionFee = selectedPaymentMethod?.transactionFee || 0;
    const finalTotals = Number(totalShipments) + Number(totalCart);
    const calcPercent = calculatePercentage({
      percentage: Number(totalTransactionFee || 0),
      total: finalTotals
    });
    return `${nudosFormatCurrency({ value: calcPercent, maximumFractionDigits: 2 })} USD`;
  };

  const filterSummaryItems = (summaryItems: ISummaryItem[]) => {
    if (businessInSpecialZone) return summaryItems;
    return summaryItems.filter(item => item.key !== 'impuestos');
  };

  const summarySThirdSectionListingElementsOtherFormat: ISummaryItem[] = [
    {
      key: 'productsSubtotal',
      name: t(`${translationKey}productsSubtotal`),
      mainValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.total || 0 : itemsCart?.totalWithoutPrime || 0),
        maximumFractionDigits: 2
      })} USD`,
      secondaryValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.totalWithoutPrime || 0 : itemsCart?.total || 0),
        maximumFractionDigits: 2
      })} USD`,
      showSecondaruValue: true,
      toCalculate: false,
      showGrayIcon: !isPrime,
      showYellowIcon: false
    },
    {
      key: 'deliverySubtotal',
      name: t(`${translationKey}deliverySubtotal`),
      mainValue:
        summary && summary?.totalShippings > 0
          ? totalsShipping[!isPrime ? 'freeTotalFormat' : 'primeTotalFormat']
          : t('recurrentWords:toBeCalculated'),
      toCalculate: summary?.totalShippings ? false : true,
      showSecondaruValue: !!summary?.totalShippings,
      secondaryValue: summary?.totalShippings ? totalsShipping[!isPrime ? 'primeTotalFormat' : 'freeTotalFormat'] : '',
      showYellowIcon: !!summary?.totalShippings && isPrime,
      showGrayIcon: !!summary?.totalShippings && !isPrime,
      tooltip: <ShipmentSubtotalTooltip step={3} primeTotal={totalsShipping.primeTotal || 0} />
    },
    {
      key: 'taxes',
      name: 'Impuestos',
      mainValue: `-${nudosFormatCurrency({ value: Number(taxDiscountValue), maximumFractionDigits: 2 })} USD`,
      toCalculate: false,
      tooltip: (
        <ShipmentSubtotalTooltip customMessageText="Para las razones sociales en Zona Franca no cobramos el impuesto de 22% en Uruguay" />
      ),
      hidden: uyTaxesHidden
    },
    {
      key: 'paymentCommission',
      name: t('nodi:checkout:paymentComission'),
      mainValue: isPrime ? '0 USD' : `${calcPaymentCommision()}`,
      secondaryValue: `${calcPaymentCommision()}`,
      showYellowIcon: isPrime,
      toCalculate: false,
      showSecondaruValue: isPrime,
      hidden: !selectedPaymentMethod?.transactionFee
    },
    {
      key: 'taxesToCalculate',
      name: 'Impuestos',
      mainValue: t('recurrentWords:toBeCalculated'),
      toCalculate: true,
      tooltip: (
        <ShipmentSubtotalTooltip
          customMessageText="El impuesto de esta compra podrá visualizarse en la factura una vez esta sea emitida al finalizar la orden"
          step={3}
          primeTotal={totalsShipping.primeTotal || 0}
        />
      ),
      hidden: !showTaxesToCalculate
    },
    {
      key: 'productsTotal',
      name: 'Total',
      mainValue: `${mainAmountCalc()}`,
      secondaryValue: `${secondaryAmountCalc()}`,
      showSecondaruValue: true,
      toCalculate: false,
      isBold: true,
      showYellowIcon: isPrime,
      showGrayIcon: !isPrime
    }
  ];
  const calcTotalShippings = (shippings?: IShipmentGroupedByDestinationLocationAndProductId[]) => {
    if (shippings && shippings.length > 0) {
      let freePrice = 0;
      let primePrice = 0;
      for (let i = 0; i < shippings.length; i++) {
        const shippingElement = shippings[i];
        if (shippingElement) {
          if (shippingElement.unitPrice < lowerLimitForFreeShipping) {
            freePrice = freePrice + costForPaidShipments;
            primePrice = primePrice + costForPaidShipments;
          }
          if (shippingElement.unitPrice >= lowerLimitForFreeShipping) {
            freePrice = freePrice + minimumShippingCost;
          }
        }
      }
      const totalShippingDiscount = freePrice - primePrice;
      setTotalsShipping({
        freeTotalFormat: `${nudosFormatCurrency({ value: freePrice, maximumFractionDigits: 2 })} USD`,
        freeTotal: freePrice,
        primeTotalFormat: `${nudosFormatCurrency({ value: primePrice })} USD`,
        primeTotal: primePrice,
        totalShippingDiscount: totalShippingDiscount
      });
    } else {
      setTotalsShipping({
        freeTotalFormat: '$0 USD',
        freeTotal: 0,
        primeTotalFormat: '$0 USD',
        primeTotal: 0,
        totalShippingDiscount: 0
      });
    }
  };

  const productsEvent = itemsCart?.products?.map(product => {
    return {
      SKUId: `${product?.sku}`,
      Total: `${product?.unitPrice} USD`,
      Country: `${product?.countryName}`,
      Quantity: `${product?.quantity}`
    };
  });

  const downloadQuotation = async () => {
    if (!itemsCart?.shoppingCartId || !checkoutBillingData?.id) return;
    setLoadingQuotation(true);
    try {
      const body = {
        shoppingCartId: itemsCart?.shoppingCartId,
        billingDataId: checkoutBillingData?.id
      };
      const { url } = await requestQuotationPdf(body);
      if (url) {
        setLastQuotationItems(itemsCart);
        setQuotationPdfUrl(url);
        window.open(url, 'blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingQuotation(false);
    }
  };

  const handleClickRequestQuotation = () => {
    segmentTrackEvent({
      freeCheckoutQuoteClick: {
        ProductList: productsEvent || [],
        USDTotal: usdTotal,
        TotalSaving: `${itemsCart?.totalDiscountValue} USD`
      }
    });
    if (quotationPdfUrl) window.open(quotationPdfUrl, 'blank');
    else downloadQuotation();
  };

  const calcTotalSaved = () => {
    const totalPrimeCart = itemsCart?.total || 0;
    const totalCartSaved = itemsCart?.totalDiscountValue || 0;
    const totalPrimeShippings = totalsShipping?.primeTotal || 0;
    const totalShippingsSaved = totalsShipping?.totalShippingDiscount || 0;
    const totals = totalPrimeCart + totalPrimeShippings;
    const totalCommisionsSaved = selectedPaymentMethod?.transactionFee || 0;
    const calcPercent = totalCommisionsSaved
      ? calculatePercentage({ percentage: Number(totalCommisionsSaved || 0), total: totals })
      : 0;
    const finalTotals = totalCartSaved + totalShippingsSaved + calcPercent;
    setTotalSaved(finalTotals || 0);
  };

  const breadcrumbButtonReturnAction = () => {
    setStep(1);
    segmentTrackEvent({
      [!isPrime ? 'freeCheckoutBackClick' : 'primeCheckoutBackClick']: {
        ScreenName: 'Billing&Payment'
      }
    });
  };

  useEffect(() => {
    calcTotalSaved();
  }, [summary, totalsShipping, selectedPaymentMethod]);

  useEffect(() => {
    calcTotalShippings(summary?.shippingsList);
  }, [summary?.shippingsList]);

  useEffect(() => {
    summaryAction();
  }, [globalShippingCart]);

  useEffect(() => {
    singleCountryShippingFormat();
    summaryAction();
  }, []);

  useEffect(() => {
    getSummaryTotals(
      selectedPaymentMethod?.currency?.code || 'USD',
      Number(selectedPaymentMethod?.transactionFee || 0),
      Number(selectedPaymentMethod?.exchangeRate || 1)
    );
  }, [selectedPaymentMethod, itemsCart, summary]);

  useEffect(() => {
    const hasShoppingCartChange = deepEqual(itemsCart, lastQuotationItems);
    if (hasShoppingCartChange) {
      setLastQuotationItems(undefined);
      setQuotationPdfUrl('');
    }
  }, [itemsCart]);

  useEffect(() => {
    if (!quotationPdfUrl) return;
    setLastQuotationItems(undefined);
    setQuotationPdfUrl('');
  }, [checkoutBillingData?.id]);

  useEffect(() => {
    segmentTrackEvent({
      [!isPrime ? 'free3CheckoutBillingAndPaymentView' : 'prime3CheckoutBillingAndPaymentView']: null
    });
  }, []);

  return (
    <div id="invoiceCart">
      <NudosBreadcrumbButton returnText={returnText} returnAction={breadcrumbButtonReturnAction} />
      <div className="step3Contents">
        <div className="billingDataAndPaymentMethodsContainer extraBillingDataAndPaymentMethodsContainer">
          <BillingDataSection completeListOfCountries={completeListOfCountries} />
          {!!checkoutBillingData && (
            <PaymentMethodSection
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              updateBillingData={updateBillingData}
              billingData={checkoutBillingData}
              countryCode={storeCountry?.countryCode}
            />
          )}
        </div>
        <div className="summaryContainer extraSummaryContainer">
          <CheckoutSummary
            isButtonDisabled={isButtonDisabled()}
            firstSectionListingElements={checkoutSummaryFirstSectionListingElements}
            secondSectionListingElements={checkoutSummarySecondSectionListingElements}
            boldAndLargeTextInSecondLine={undefined}
            shipmentsList={summary?.shippingsList}
            shipmentsPricingDataByDestination={summary?.shipmentsPricingDataByDestination || {}}
            handleClickButton={() => createOrder()}
            referencesList={productsCart}
            buttonText={t('nodi:checkout:step3ButtonText')}
            loadingButton={loadingCreateOrder || loadingUpdateBillingData}
            checkoutStep={3}
            listOfCountriesOpenForBuying={listOfCountriesOpenForBuying}
            totalShippingDiscount={totalsShipping?.totalShippingDiscount || 0}
            itemsCart={itemsCart}
            heightLimitProductList={100}
            thirdSectionListItems={filterSummaryItems(summarySThirdSectionListingElementsOtherFormat)}
            totalSaved={totalSaved}
          />
          <NudosButton
            colorPalette="blue"
            customHeight="28px"
            customWidth="128px"
            buttonText={t('recurrentWords:downloadQuote')}
            buttonIcon={<DownloadIcon className="downloadIcon" />}
            customClassName="requestQuotationButton"
            handleClick={handleClickRequestQuotation}
            isButtonLoading={loadingQuotation}
            isButtonDisabled={loadingQuotation || shoppingCartIsEmpty || !checkoutBillingData?.id}
          />
        </div>
      </div>
    </div>
  );
};

export default Step3InvoiceCart;

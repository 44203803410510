import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NudosBreadcrumbButton } from '../../../components/NudosComponents';
import { getOrderDetails } from '../../../services/orders';
import { IorderDetails, IshipmentDetails } from '../../../types/orders';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import {
  OrderDetailsHeader,
  OrderDetailsHeaderSkeletonLoader,
  OrderDetailsSummary,
  OrderDetailsSummarySkeletonLoader,
  OrderPaymentDetails,
  ShipmentsList,
  ShipmentsListSkeletonLoader
} from './Components';
import { useTranslation } from 'react-i18next';
import BordBillingDataSection from '../../../components/DesignSystem/BordBillingDataSection/BordBillingDataSection';
import { getCountryName } from '../../../utils/getCountries';
import './OrderDetails.scss';

const OrderDetails = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const { id: orderId } = useParams<{ id: string }>();

  const [orderData, setOrderData] = useState<IorderDetails>();
  const [loading, setLoading] = useState(false);
  const [dataWasUpdated, setDataWasUpdated] = useState(0);
  const [problemsWithAddresses, setProblemsWithAddresses] = useState<boolean>(false);
  const [addressesHaveBeenUpdated, setAddressesHaveBeenUpdated] = useState<boolean>(true);
  const [editShipments, setEditShipments] = useState<IshipmentDetails>();

  const origin = new URLSearchParams(search).get('from');
  const originId = new URLSearchParams(search).get('id');
  const shipmentsEntries = orderData?.shimpentsInfo ? Object.entries(orderData.shimpentsInfo) : [];
  const i18nLanguageKey = 'nodi:orderDetails:getReturnText:';
  const orderDataExists = !loading && orderData;

  const getReturnText = () => {
    switch (origin) {
      case 'dashboard':
        return 'Dashboard';
      case 'tools':
        return t(`${i18nLanguageKey}tools`);
      case 'tool-details':
        return t(`${i18nLanguageKey}tool-details`);
      case 'employee-details':
        return t(`${i18nLanguageKey}employee-details`);
      case 'employee-edit':
        return t(`${i18nLanguageKey}employee-edit`);
      default:
        return t(`${i18nLanguageKey}default`);
    }
  };

  const returnAction = () => {
    switch (origin) {
      case 'dashboard':
        return push('/nodi/dashboard');
      case 'tools':
        return push('/nodi/tools');
      case 'tool-details':
        return push(`/nodi/details/${originId}`);
      case 'employee-details':
        return push(`/nodi/employees/details/${originId}`);
      case 'employee-edit':
        return push(`/nodi/employees/edit/${originId}`);
      default:
        return push('/nodi/orders');
    }
  };

  const getOrderData = async (noLoading?: boolean) => {
    if (!orderId) return;
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const responseOrderData = await getOrderDetails(orderId);
      if (responseOrderData) setOrderData(responseOrderData);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const updateOrderData = () => setDataWasUpdated(dataWasUpdated + 1);
  const getOrderDatawithoutLoader = () => getOrderData(true);

  const verifyReportsAndDetails = () => {
    if (orderData && orderData.shimpentsInfo) {
      const lengthShimpents = Object.keys(orderData.shimpentsInfo).length;
      if (lengthShimpents > 0) {
        const shipments = Object.keys(orderData.shimpentsInfo);
        let myShipmentsList: IshipmentDetails[] = [];
        for (const keyShimpent of shipments) {
          const countryList = orderData.shimpentsInfo[keyShimpent];
          myShipmentsList = myShipmentsList.concat(countryList);
        }
        const reportsFound = !!myShipmentsList.find(
          shipment =>
            shipment.reports &&
            shipment.reports.length > 0 &&
            shipment?.reports?.find(report => report.status === 'OPEN')
        );

        const searchEditShipments = myShipmentsList.find(
          shipment => shipment?.editShipments?.id && shipment?.editShipments?.platform === 'SOGA'
        );

        const searchForEditionsFromSoga = !!searchEditShipments;
        if (searchEditShipments?.editShipments?.id) setEditShipments(searchEditShipments);
        const hasBeenEdited = !reportsFound && searchForEditionsFromSoga;
        setAddressesHaveBeenUpdated(hasBeenEdited);
        setProblemsWithAddresses(reportsFound);
      }
    }
  };

  const getCountryNameHanlder = (countryShipmentData: IshipmentDetails) => {
    const countryDetail = {
      id: 0,
      flag: countryShipmentData?.flag || '',
      name: countryShipmentData?.countryName || '',
      code: countryShipmentData?.country || '',
      nameStringId: countryShipmentData?.countryNameStringId
    };
    return getCountryName(countryDetail);
  };

  const OrderDataExists = !loading && orderData;
  useEffect(() => {
    getOrderData();
  }, [orderId, dataWasUpdated]);

  useEffect(() => {
    verifyReportsAndDetails();
  }, [orderData]);

  return (
    <div id="OrderDetails">
      <NudosBreadcrumbButton returnText={getReturnText()} returnAction={returnAction} />
      {orderDataExists ? <OrderDetailsHeader orderData={orderData} /> : <OrderDetailsHeaderSkeletonLoader />}
      <div className="orderInformation">
        <div className="leftContent">
          {orderDataExists ? (
            <OrderDetailsSummary
              orderData={orderData}
              problemsWithAddresses={problemsWithAddresses}
              addressesHaveBeenUpdated={addressesHaveBeenUpdated}
              editShipments={editShipments}
              getOrderDatawithoutLoader={getOrderDatawithoutLoader}
            />
          ) : (
            <OrderDetailsSummarySkeletonLoader />
          )}
          <OrderPaymentDetails
            updateOrderDataFunction={updateOrderData}
            loading={!orderDataExists}
            setLoading={setLoading}
            orderData={orderData}
            getOrderDatawithoutLoader={getOrderDatawithoutLoader}
          />
          <BordBillingDataSection
            updateDataCallaback={updateOrderData}
            billingData={orderData?.billingData || {}}
            orderOrLogisticId={orderData?.orderId || ''}
            loading={!orderDataExists}
          />
        </div>
        <div className="rightContent">
          <div className="shipmentsContainer">
            {orderDataExists &&
              shipmentsEntries?.map(([country, countryShipments], i) => {
                const countryShipmentData = countryShipments[0];
                const flag = countryShipmentData?.flag || '';
                const countryName = getCountryNameHanlder(countryShipmentData);
                return (
                  <ShipmentsList
                    key={`shipmentList${i}-${country}`}
                    shipments={countryShipments}
                    flag={flag}
                    countryName={countryName}
                    quantityShipments={countryShipments?.length}
                    updateOrderDataFunction={updateOrderData}
                    getOrderDatawithoutLoader={getOrderDatawithoutLoader}
                  />
                );
              })}
            {!orderDataExists && <ShipmentsListSkeletonLoader />}
            <div className="whiteSpaceAtTheBottom w-10 h-10" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;

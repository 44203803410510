import { useTranslation } from 'react-i18next';
import { ICountryDetail } from '../../../types/countries';
import { TcomponentSize } from '../../../types/global';
import { removeAccents } from '../../../utils/string';
import { NudosHoverText } from '../../NudosComponents';
import NudosSearchbarDropdown from '../NudosSearchbarDropdown/NudosSearchbarDropdown';
import { getCountryName } from '../../../utils/getCountries';
import './NudosCountryDropdown.scss';

/**
 * @property {boolean} displayFlag: Optional, boolean indicating if the flag shoud be displayed, default is TRUE.
 * @property {boolean} displayText: Optional, boolean indicating if the text should be displayed, default is TRUE.
 * @property {boolean} displayLabel: Optional, a label for the select input, default is TRUE.
 * @property {boolean} isDeactivated: Optional, boolean indicating if the dropdown input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {ICountryDetail[]} countriesList: Optional, an array of all the possible values from which select.
 * @property {string} componentSize: Optional, one of the standard sizes for the components, corresponding to one of the following words: "extra-small", "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {number} charactersLimit: The number of characters that the text will have before it gets truncated.
 * @property {boolean} isPhoneIndicative: Optional, boolean indicating if the text displayed should be the phone indicative numberm, default is FALSE
 * @property {string} customPlaceholder: Optional, the desired text when the dropdown is closed and no option has been selected yet. Default is the text "Elige una opción"
 * @property {boolean} showSkeletonLoader: Optional, boolean indicating if the component is loading and therefore the skeleton loader is to be shown. Default is false.
 * @property {(newValue: ICountryDetail) => void} updateCurrentValueCallback: Callback function that received a value from the countriesList.
 */

const NudosCountryDropdown = ({
  errorText,
  displayFlag = true,
  displayText = true,
  displayLabel = true,
  customLabel,
  currentValue,
  hideErrorText,
  isDeactivated,
  countriesList,
  componentSize,
  charactersLimit,
  isPhoneIndicative = false,
  customPlaceholder = 'Escoger país',
  showSkeletonLoader,
  updateCurrentValueCallback
}: {
  errorText?: string;
  customLabel?: string;
  displayFlag?: boolean;
  displayText?: boolean;
  displayLabel?: boolean;
  currentValue?: ICountryDetail;
  hideErrorText?: boolean;
  isDeactivated?: boolean;
  countriesList: ICountryDetail[];
  componentSize?: TcomponentSize;
  charactersLimit: number;
  isPhoneIndicative?: boolean;
  customPlaceholder?: string;
  showSkeletonLoader?: boolean;
  updateCurrentValueCallback: (newValue: ICountryDetail) => void;
}) => {
  const { t } = useTranslation();
  const countryLabel = customLabel ? customLabel : 'País';
  const label = displayLabel ? countryLabel : undefined;

  const filterBySearchStringCallback = (search: string, countriesList: ICountryDetail[]) => {
    return countriesList.filter(country => {
      const cleanSearch = removeAccents(search.toLowerCase().trim());
      const countryName = country?.name?.toLowerCase().trim();
      const countryPhoneCode = country?.phoneCode?.toString().toLowerCase().trim();
      const countryCode = country?.code?.toLowerCase().trim();

      return (
        (countryName && removeAccents(countryName)?.includes(cleanSearch)) ||
        (countryPhoneCode && removeAccents(countryPhoneCode)?.includes(cleanSearch)) ||
        (countryCode && removeAccents(countryCode)?.includes(cleanSearch))
      );
    });
  };

  const formDropdownOptionsCallback = (
    countriesList: ICountryDetail[],
    clickOptionCallback?: (option: ICountryDetail) => void,
    searchString?: string
  ) => {
    const handleSelectOption = (option: ICountryDetail) => {
      updateCurrentValueCallback(option);
      clickOptionCallback && clickOptionCallback(option);
    };
    if (countriesList.length === 0)
      return [
        <div className="noOptionContainer" key="loading">
          {searchString ? 'No hay resultados para tu búsqueda' : 'No hay resultados'}
        </div>
      ];
    return countriesList.map(country => {
      const finalCharactersLimit = country?.flag ? charactersLimit : charactersLimit + 3;
      const countryName = getCountryName(country);
      const text = isPhoneIndicative ? `+${country.phoneCode}` || '' : countryName || '';
      return (
        <div
          className="optionContainer flex"
          key={`country-option-${country?.id}`}
          onClick={() => handleSelectOption(country)}
        >
          {displayFlag && !!country?.flag && (
            <div className="optionFlag" style={{ backgroundImage: `url(${country?.flag})` }} />
          )}
          {displayText && <NudosHoverText text={text} charactersLimit={finalCharactersLimit} onlyIfTruncated />}
        </div>
      );
    });
  };

  const formSelectedOptionUICallback = (selectedOption: ICountryDetail) => {
    const finalCharactersLimit = selectedOption?.flag ? charactersLimit : charactersLimit + 3;
    const countryName = getCountryName(selectedOption);
    const text = isPhoneIndicative ? `+${selectedOption.phoneCode}` : countryName || '';
    return (
      <div className="optionContainer flex gap-1">
        {displayFlag && !!selectedOption?.flag && (
          <div className="optionFlag" style={{ backgroundImage: `url(${selectedOption?.flag})` }} />
        )}
        {displayText && <NudosHoverText text={text} charactersLimit={finalCharactersLimit} onlyIfTruncated />}
      </div>
    );
  };

  return (
    <div className="NudosCountryDropdown">
      <NudosSearchbarDropdown
        customClassname="NudosCountryDropdowSearchbar"
        label={label}
        isFilled={!!currentValue}
        errorText={errorText}
        rawOptions={countriesList}
        currentValue={currentValue}
        hideErrorText={hideErrorText}
        componentSize={componentSize}
        isDeactivated={isDeactivated}
        customPlaceholder={customPlaceholder}
        showSkeletonLoader={showSkeletonLoader}
        includeMagnifierIcon={false}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
      />
    </div>
  );
};

export default NudosCountryDropdown;

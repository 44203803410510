import { useEffect, useState } from 'react';
import { NudosBillingCountryDropdown, NudosBillingDataFormModal } from '../../../../components/DesignSystem';
import { NudosButton } from '../../../../components/NudosComponents';
import { ModalShareInvoice } from './Components';
import { IconArrow } from '../../../../assets/DesignSystem/SVGComponents';
import { IbillingData } from '../../../../types/checkout';
import { ICountryDetail } from '../../../../types/countries';
import useLogisticsServiceBillingState from '../../../../state/useLogisticsQuoteAndBilling';
import NudosBillingDataSummaryComponent from '../../../../components/DesignSystem/BillingDataSummary/NudosBillingDataSummaryComponent';
import { TlogisticsOriginOrDestinationData } from '../../../../types/requestLogisticsModule';
import './LogisticServiceBillingData.scss';

/**
 * @param {Object} props - The component props.
 * @param {TlogisticsOriginOrDestinationData} props.detinationData - Data related to the destination.
 * @param {ICountryDetail[]} props.allCountries - The list of all countries.
 * @param {ICountryDetail[]} props.availableCountries - The list of available countries for billing data.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.updateBillingData - The state to save the updated billing data.
 * @param {IbillingData[]} props.organizationBillingData - The different organization billing datas, an array of billing datas.
 * @param {Function} props.cleanUpdatedBillingData - The function to clean updated billing data from the parent.
 */

const LogisticServiceBillingData = ({
  allCountries,
  originData,
  detinationData,
  availableCountries,
  updateBillingData,
  organizationBillingData,
  cleanUpdatedBillingData
}: {
  allCountries: ICountryDetail[];
  originData?: TlogisticsOriginOrDestinationData;
  detinationData?: TlogisticsOriginOrDestinationData;
  availableCountries: ICountryDetail[];
  updateBillingData: React.Dispatch<React.SetStateAction<boolean>>;
  organizationBillingData?: IbillingData[];
  cleanUpdatedBillingData: () => void;
}) => {
  const {
    setLogisticsServiceBillingData,
    invoiceAdditionalEmail,
    setInvoiceAdditionalEmail,
    logisticsServiceBillingData
  } = useLogisticsServiceBillingState();

  const [showShareInvoiceEmailModal, setShowShareInvoiceEmailModal] = useState(false);
  const [showLogisticBillingDataModal, setShowLogisticBillingDataModal] = useState(false);

  const hasBillingDataForSomeCountry = organizationBillingData && !!organizationBillingData.length;
  const showBillingDataDropdown = !logisticsServiceBillingData && hasBillingDataForSomeCountry;
  const showAddBillingButton = !logisticsServiceBillingData && !hasBillingDataForSomeCountry;
  const showBillinDataSummary = logisticsServiceBillingData;

  const openBillingDataModal = () => setShowLogisticBillingDataModal(true);
  const closeBillingDataModal = () => setShowLogisticBillingDataModal(false);

  const submitModalCallback = (newData?: IbillingData) => {
    setLogisticsServiceBillingData(newData || undefined);
    updateBillingData(true);
    closeBillingDataModal();
  };

  const setBillingDataWhenJustOneExist = () => {
    if (organizationBillingData && organizationBillingData?.length === 1)
      setLogisticsServiceBillingData(organizationBillingData[0]);
  };

  useEffect(() => {
    setBillingDataWhenJustOneExist();
  }, []);

  return (
    <div className="LogisticServiceBillingData">
      <div className="logisticServiceBillingContainer">
        {showShareInvoiceEmailModal && (
          <ModalShareInvoice
            emailShareInvoice={invoiceAdditionalEmail}
            closeModalCallback={() => setShowShareInvoiceEmailModal(false)}
            setEmailShareInvoice={setInvoiceAdditionalEmail}
          />
        )}
        {showLogisticBillingDataModal && (
          <NudosBillingDataFormModal
            customZIndex={79}
            initialBillingData={logisticsServiceBillingData}
            closeModalCallback={closeBillingDataModal}
            submitModalCallback={submitModalCallback}
            availableCountries={availableCountries || []}
          />
        )}
        <div className="billingSummaryTitle">Facturación</div>
        {showAddBillingButton && (
          <NudosButton
            buttonText="Agregar datos de facturación"
            buttonIcon={<IconArrow className="IconArrow" />}
            handleClick={openBillingDataModal}
            customWidth="162px"
            customHeight="28px"
          />
        )}
        {showBillingDataDropdown && (
          <NudosBillingCountryDropdown
            componentSize="medium"
            countriesList={allCountries || []}
            currentValue={logisticsServiceBillingData}
            billingDataForAllCountriesProp={organizationBillingData}
            updateCurrentValueCallback={(newValue: IbillingData) => setLogisticsServiceBillingData(newValue)}
            addNewBillingCountryCallback={openBillingDataModal}
          />
        )}
        {showBillinDataSummary && (
          <NudosBillingDataSummaryComponent
            billingData={logisticsServiceBillingData}
            originData={originData}
            detinationData={detinationData}
            openBillingDataModal={openBillingDataModal}
            cleanUpdatedBillingData={cleanUpdatedBillingData}
          />
        )}
      </div>
    </div>
  );
};

export default LogisticServiceBillingData;

import { FC, useEffect, useState } from 'react';
import LogisticServiceBillingData from './LogisticServiceBillingData/LogisticServiceBillingData';
import LogisticServicePrincingQuote from './LogisticServicePrincingQuote/LogisticServicePrincingQuote';
import { IlocationAddressForLogistics, TlogisticsOriginOrDestinationData } from '../../../types/requestLogisticsModule';
import { IDetailsQuote, ItoolDataForRequiringLogistics, TstoragePlace } from '../../../types/assignationFluxes';
import { ICountryDetail } from '../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import { GenericLoader } from '../../Admin/dashboard/components';
import { getOrganizationBillingDataForAllItsCountries } from '../../../services/account';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { IbillingData } from '../../../types/checkout';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { TimeOutModal } from '../Components';
import {
  generateLogisticsQuoteData,
  getLastBillingDataIdUsedInLS
} from '../../../services/logisticsServicesModule.services';
import { ILogisticQuoteGeneralData } from '../../../types/logisticsServicesModule.types';
import { segmentTrackEvent } from '../../../utils/segment';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { Iemployee } from '../../../types/global';
import useLogisticsServiceBillingState from '../../../state/useLogisticsQuoteAndBilling';
import { TbilingDataByCountryMap } from '../../../types/account';
import PaymentMethodSection from '../../ecommerce/checkout/Components/Step3InvoiceCart/components/PaymentMethodSection/PaymentMethodSection';
import { IInvoicePaymentMethods } from '../../../types/cart';
import {
  BILLING_PROVIDER,
  internationalCode,
  logisticServiceProvidersWithOnePaymentCard
} from '../../../utils/productDefinitions';
import './LogisticsQuoteAndBillingStep.scss';

const LogisticsQuoteAndBillingStep: FC<ILogisticsQuoteAndBillingStep> = ({
  originData,
  destinationData,
  toolsData,
  logisticsType,
  resetSteps,
  showTimeOutModal,
  place,
  getQuouteDetails,
  handleLoadingQuote,
  handleErrorQuote,
  detailsQuote,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  showQuoteAndBillingData
}) => {
  const orgData = getOrgData();
  const statePlatform = useStateSubscriptions();
  const { setLogisticsServiceBillingData, logisticsServiceBillingData, billingMethodAndCountry } =
    useLogisticsServiceBillingState();

  const [listOfCountries, setListOfCountries] = useState<ICountryDetail[]>();
  const [organizationBillingData, setOrganizationBillingData] = useState<IbillingData[]>([]);
  const [billingDataByCountryMap, setBillingDataByCountryMap] = useState<TbilingDataByCountryMap>(new Map());
  const [loadingOrganizationBillingDataCountries, setLoadingOrganizationBillingDataCountries] = useState(true);
  const [loaderQuoteData, setLoaderQuoteData] = useState<boolean>(false);
  const [logisticServiceQuoteData, setLogisticServiceQuoteData] = useState<ILogisticQuoteGeneralData>();
  const [originPlace, setOriginPlace] = useState<TstoragePlace>();
  const [destinationPlace, setDestinationPlace] = useState<TstoragePlace>();
  const [updateBillingData, setUpdateBillingData] = useState<boolean>(false);
  const [initialBillingDataWasLoader, setInitialBillingDataWasLoader] = useState<boolean>(false);
  const [initialQuoteDataWasLoader, setInitialQuoteDataWasLoader] = useState<boolean>(false);
  const [billingDataIdUsedLoader, setBillingDataIdUsedLoader] = useState<boolean>(false);

  const originLocationId = originData?.locationId || (originData as IlocationAddressForLogistics)?.id;
  const destinationLocationId = destinationData?.locationId || (destinationData as IlocationAddressForLogistics)?.id;
  const destinationCountry = (destinationData as IlocationAddressForLogistics)?.countryData || destinationData?.country;

  const toolsDataForQuote = toolsData
    ? toolsData.map(tool => {
        return {
          productId: tool?.productId ? +tool.productId : 0,
          additionalServicesIds: tool?.newLogisticAdditionalServices
            ? tool.newLogisticAdditionalServices.map(additionalService => additionalService.id)
            : []
        };
      })
    : [];

  const getOrganizationBillingData = async () => {
    if (!orgData?.organizationId) return;
    setLoadingOrganizationBillingDataCountries(true);
    try {
      const response = await getOrganizationBillingDataForAllItsCountries(orgData?.organizationId);
      const organizationBillingDataEntries = Object.entries(response);
      const countriesBillingDataMap = new Map(organizationBillingDataEntries);
      const billingDataEntitiesByCountry = Object.values(response).map(
        countryBillingData => countryBillingData.billingEntities
      );
      const allOrganizationBillingEntities = billingDataEntitiesByCountry.flat();
      setOrganizationBillingData(allOrganizationBillingEntities);
      setBillingDataByCountryMap(countriesBillingDataMap);
      !initialBillingDataWasLoader && setInitialBillingDataWasLoader(true);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOrganizationBillingDataCountries(false);
    }
  };

  const availableCountries = listOfCountries?.filter(country => {
    const thisCountryBillingData = billingDataByCountryMap.get(country.code);
    return !thisCountryBillingData || !!thisCountryBillingData?.canCreateNew;
  });

  const changeLoadingQuote = (loading: boolean) => {
    if (handleLoadingQuote) handleLoadingQuote(loading);
  };

  const changeErrorQuote = (therIsAnError: boolean) => {
    if (handleErrorQuote) handleErrorQuote(therIsAnError);
  };

  const showLoader =
    !listOfCountries ||
    (!initialBillingDataWasLoader && loadingOrganizationBillingDataCountries) ||
    (showQuoteAndBillingData && loaderQuoteData && !initialQuoteDataWasLoader) ||
    billingDataIdUsedLoader;

  const generalLogisticsQuoteData = async () => {
    if (!showQuoteAndBillingData) return;
    const someToolDoesntHaveId = toolsDataForQuote.some(tool => !tool.productId);
    if (!originLocationId || !destinationLocationId || !toolsDataForQuote || someToolDoesntHaveId) return;
    if (!destinationCountry?.id) return;
    changeLoadingQuote(true);
    setLoaderQuoteData(true);
    const body = {
      organizationId: orgData?.organizationId,
      originId: originLocationId,
      destinationId: destinationLocationId,
      destinationCountryId: destinationCountry?.id,
      tools: toolsDataForQuote,
      paymentMethodName: selectedPaymentMethod?.name
    };
    try {
      const requestedQuoteData = await generateLogisticsQuoteData(body);
      changeErrorQuote(false);
      //si quieren mas empresas "Vip" se debe considerar cambiar la logica
      const platformSubscription =
        requestedQuoteData.platformSubscription === 'Vip' ? 'Lite' : requestedQuoteData.platformSubscription;
      setLogisticServiceQuoteData({ ...requestedQuoteData, platformSubscription: platformSubscription });
      if (requestedQuoteData && getQuouteDetails) {
        getQuouteDetails({
          fee: Number(
            requestedQuoteData?.managementCommissionToCharge || !!requestedQuoteData?.paymentCommissionToCharge || 0
          ),
          total: requestedQuoteData.total
        });
      }
    } catch {
      displayErrorNotification();
      changeErrorQuote(true);
    } finally {
      setLoaderQuoteData(false);
      changeLoadingQuote(false);
      !initialBillingDataWasLoader && setInitialQuoteDataWasLoader(true);
    }
  };

  const saveActualDate = () => {
    const newActualDate = new Date();
    if (newActualDate) localStorage.setItem('actualDateLogisticsService', JSON.stringify({ date: newActualDate }));
  };

  const unassignmentEvents = () => {
    if (logisticsType === 'unassignment') {
      segmentTrackEvent({
        nodiLogisticServices4QuotationView: {
          NudosCare: statePlatform?.stateSubscription?.nudosCare,
          TypeOfPlatform: statePlatform?.stateSubscription?.levelSubscription === 'Lite' ? 'Prime' : 'Free'
        }
      });
    }
  };

  const assignPlaceOfOrigin = () => {
    const originDataAsEmployee = originData as Iemployee;
    const originDataAsLocation = originData as IlocationAddressForLogistics;
    const selectedOrigin = originDataAsEmployee?.userId ? 'user' : originDataAsLocation?.place || '';
    setOriginPlace(selectedOrigin);
    setDestinationPlace(place);
  };

  const getLastBillingDataUsed = async () => {
    if (loadingOrganizationBillingDataCountries) return;
    setBillingDataIdUsedLoader(true);
    try {
      const body = { orgId: orgData?.organizationId, countryCode: destinationCountry?.code || '' };
      const lastBillingIdUsed = await getLastBillingDataIdUsedInLS(body);
      const lastBillingDataUsed = organizationBillingData?.find(billingData => billingData?.id === lastBillingIdUsed);
      setLogisticsServiceBillingData(lastBillingDataUsed);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setBillingDataIdUsedLoader(false);
    }
  };

  useEffect(() => {
    getOrganizationBillingData();
  }, [updateBillingData]);

  useEffect(() => {
    getLastBillingDataUsed();
  }, [loadingOrganizationBillingDataCountries]);

  useEffect(() => {
    generalLogisticsQuoteData();
  }, [selectedPaymentMethod]);

  useEffect(() => {
    saveActualDate();
    unassignmentEvents();
    assignPlaceOfOrigin();
    getListOfCountriesByOfferedService(setListOfCountries);
  }, []);

  const lsProvidersWithOnePaymentCard = logisticServiceProvidersWithOnePaymentCard.includes(
    billingMethodAndCountry?.billingMethod?.billingProvider as BILLING_PROVIDER
  );
  const countryCodeIsXX = billingMethodAndCountry?.billingCountryCode === internationalCode;
  const displaySpecialPaymentCardOption = lsProvidersWithOnePaymentCard || countryCodeIsXX;

  if (showLoader)
    return (
      <div className="LogisticsQuoteAndBillingStepInitialLoading">
        <GenericLoader />
      </div>
    );

  return (
    <>
      {showTimeOutModal && <TimeOutModal resetSteps={resetSteps} />}
      <div className="LogisticsQuoteAndBillingStep">
        <div className="leftContent">
          <LogisticServiceBillingData
            allCountries={listOfCountries || []}
            originData={originData}
            detinationData={destinationData}
            availableCountries={availableCountries || []}
            organizationBillingData={organizationBillingData}
            updateBillingData={setUpdateBillingData}
            cleanUpdatedBillingData={() => setLogisticsServiceBillingData(undefined)}
          />
          {!!logisticsServiceBillingData && (
            <PaymentMethodSection
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              billingData={logisticsServiceBillingData}
              countryCode={billingMethodAndCountry?.billingCountryCode}
              displaySpecialPaymentCardOption={displaySpecialPaymentCardOption}
            />
          )}
        </div>
        {showQuoteAndBillingData && (
          <div className="rightContent">
            <LogisticServicePrincingQuote
              selectedPaymentMethod={selectedPaymentMethod}
              logisticsType={logisticsType}
              logisticServiceQuoteData={logisticServiceQuoteData}
              originData={originData}
              detinationData={destinationData}
              destinationPlace={destinationPlace}
              originPlace={originPlace}
              detailsQuote={detailsQuote}
              quoteLoader={loaderQuoteData}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LogisticsQuoteAndBillingStep;

interface ILogisticsQuoteAndBillingStep {
  originData?: TlogisticsOriginOrDestinationData;
  destinationData?: TlogisticsOriginOrDestinationData;
  toolsData?: ItoolDataForRequiringLogistics[];
  logisticsType?: 'assignment' | 'unassignment';
  resetSteps?: () => void;
  showTimeOutModal?: boolean;
  place?: TstoragePlace;
  getQuouteDetails?: (details: IDetailsQuote) => void;
  handleLoadingQuote?: (loading: boolean) => void;
  handleErrorQuote?: (therIsAnError: boolean) => void;
  detailsQuote?: IDetailsQuote;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<IInvoicePaymentMethods | undefined>>;
  selectedPaymentMethod?: IInvoicePaymentMethods;
  showQuoteAndBillingData: boolean;
}

import { NudosDownloadDocumentButton } from '../../../../../../components/NudosComponents';
import { uploadLogisticServiceDocument } from '../../../../../../services/logisticsServicesModule.services';
import { IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../utils/displayNudosStandardNotifications';
import validateFile from '../../../../../../utils/validateFile';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PaymentMethodAndLink from '../../../../orderDetails/Components/OrderPaymentDetails/PaymentMethodAndLink/PaymentMethodAndLink';
import { DetailsPaymentModal } from '../../../../orderDetails/Components';
import { TstoragePlace } from '../../../../../../types/assignationFluxes';
import './LogisticServicePaymentSection.scss';

const LogisticServicePaymentSection = ({
  logisticServiceData,
  isLoading = false,
  setLoadingCallback,
  updateDataCallback
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  isLoading?: boolean;
  setLoadingCallback: React.Dispatch<React.SetStateAction<boolean>>;
  updateDataCallback: () => void;
}) => {
  const { t } = useTranslation();

  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState<boolean>(false);

  const {
    id,
    proofOfPaymentUrl,
    paymentLink,
    paymentType,
    quoteDocumentUrl,
    documentUrl,
    delivery,
    bankAccountInfo,
    paymentDate,
    paymentAgreementDate,
    exchangeRate,
    billingMethod,
    paymentMethodStringId,
    paymentMethod,
    isPaymentReceived,
    manualQuote,
    invoiceUrl,
    status
  } = logisticServiceData || {};

  const deliveryPlace: TstoragePlace = delivery?.place as TstoragePlace;
  const translationKey = 'nodi:logisticServices:logisticServicePaymentSection:';
  const recurrentWordsKey = 'recurrentWords:chargeFile';
  const chargeFileErrorSize = t(`${recurrentWordsKey}chargeFileErrorSize`);
  const chargeFileErrorFormat = t(`${recurrentWordsKey}chargeFileErrorFormat`);

  const uploadLogisticServiceProofOfPayment = async (fileToUpload?: File) => {
    if (!id || !fileToUpload) return;
    const isFileValid = validateFile(fileToUpload, ['application/pdf'], 2, chargeFileErrorFormat, chargeFileErrorSize);
    if (!isFileValid) return;
    const formData = new FormData();
    formData.append('file', fileToUpload);
    setLoadingCallback(true);
    try {
      const response = await uploadLogisticServiceDocument(id, 'proofOfPayment', formData);
      if (response) {
        updateDataCallback();
        displaySuccessNotification({ customJSXMessage: <>{t(`${translationKey}proofOfPaymentNotification`)}</> });
        setLoadingCallback(false);
      }
    } catch {
      displayErrorNotification();
      setLoadingCallback(false);
    }
  };

  const paymentMethodAndLinkBody = {
    paymentMethod: paymentMethod,
    proofOfPaymentUrl: proofOfPaymentUrl,
    paymentLink: paymentLink,
    paymentDate: paymentDate,
    committedPaymentDate: paymentAgreementDate,
    billingMethod: billingMethod,
    exchangeRate: exchangeRate,
    paymentMethodStringId: paymentMethodStringId,
    isPaymentReceived: isPaymentReceived,
    isLogisticService: true,
    bankAccountInfo: bankAccountInfo,
    manualQuote: manualQuote,
    invoiceUrl: invoiceUrl || '',
    status: status,
    paymentType: paymentType,
    quoteDocumentUrl: quoteDocumentUrl
  };

  if (isLoading || !logisticServiceData) return <div className="h-5 logisticServicePaymentSection animationLoader" />;

  return (
    <div className="logisticServicePaymentSection">
      {showPaymentInfoModal && (
        <DetailsPaymentModal
          banckAccountData={logisticServiceData}
          closeModal={() => setShowPaymentInfoModal(false)}
          paymentMethod={paymentType || ''}
        />
      )}
      <div className="sectionTitle">Detalle de pago</div>
      <div className="sectionContents">
        <div className="documentContainer">
          <div className="subsectionTitle">Cotización</div>
          <NudosDownloadDocumentButton
            width={76}
            height={18}
            buttonText={t(`${translationKey}quoteText`)}
            downloadLink={quoteDocumentUrl || undefined}
          />
        </div>
        <div className="divider" />
        <PaymentMethodAndLink
          paymentMethodAndLink={paymentMethodAndLinkBody}
          uploadProofOfPaymentCallback={uploadLogisticServiceProofOfPayment}
          openPaymentInfoModal={() => setShowPaymentInfoModal(true)}
          isLogisticServiceFlux
        />
        <div className="divider" />
        <div className="documentContainer">
          <div className="subsectionTitle">Documentos</div>
          <NudosDownloadDocumentButton
            buttonText={t(`${translationKey}proofOfPayment`)}
            downloadLink={proofOfPaymentUrl || ''}
            height={18}
          />
          {deliveryPlace === 'nudos' && (
            <NudosDownloadDocumentButton
              buttonText={t(`${translationKey}deviceStatusText`)}
              downloadLink={documentUrl || ''}
              height={18}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LogisticServicePaymentSection;

import { useTranslation } from 'react-i18next';
import { NudosButton } from '../../../../../../../components/NudosComponents';
import { IlogisticServiceDetailedData } from '../../../../../../../types/logisticsServicesModule.types';
import { supportUrl } from '../../../../../../../utils/productDefinitions';
import { logisticQuoteIcon } from '../../../../../../../assets/images/views/RequestLogistics';
import { NudosConfirmActionModal } from '../../../../../../../components/DesignSystem';
import { useState } from 'react';
import ReviewLogisticQuoteModal from './ReviewLogisticQuoteModal/ReviewLogisticQuoteModal';
import { approveOrRejectLogisticQuote } from '../../../../../../../services/logisticsServicesModule.services';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../utils/displayNudosStandardNotifications';
import './ApproveOrRejectLogisticNotificacion.scss';

const ApproveOrRejectLogisticNotificacion = ({
  logisticServiceData,
  getLogisticServiceDetailCallback
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceDetailCallback: () => void;
}) => {
  const { t } = useTranslation();

  const [showReviewQuoteModal, setShowReviewQuoteModal] = useState<boolean>(false);
  const [showConfirmQuoteActionModal, setShowConfirmQuoteActionModal] = useState<boolean>(false);

  const translationKey = 'nodi:logisticServices:logisticServiceBanner:';
  const displayReviewQuoteModal = showReviewQuoteModal && !showConfirmQuoteActionModal;
  const displayConfirmQuoteActionModal = showConfirmQuoteActionModal && !displayReviewQuoteModal;

  const handleClickForHelp = () => window.open(supportUrl);

  const handleLsRejection = () => {
    setShowReviewQuoteModal(false);
    setShowConfirmQuoteActionModal(true);
  };

  const approveOrRejectLogisticQuoteFunction = async (approveOrReject: 'approved' | 'rejected') => {
    if (!logisticServiceData?.id) return;
    try {
      await approveOrRejectLogisticQuote(logisticServiceData?.id, approveOrReject);
      if (approveOrReject === 'approved')
        displaySuccessNotification({
          customJSXMessage: <>✅ Cotización aprobada. Te notificaremos cuando el link de pago esté disponible.</>
        });
      if (approveOrReject === 'rejected')
        displayErrorNotification({ customJSXMessage: <>El servicio ha sido cancelado exitosamente</> });
    } catch (error) {
      displayErrorNotification();
    } finally {
      getLogisticServiceDetailCallback();
    }
  };

  return (
    <div className="approveOrRejectLogisticNotificacion">
      {displayReviewQuoteModal && (
        <ReviewLogisticQuoteModal
          approveOrRejectLogisticQuote={approveOrRejectLogisticQuoteFunction}
          closeModalCallback={() => setShowReviewQuoteModal(false)}
          logisticServiceData={logisticServiceData}
          handleLsRejection={handleLsRejection}
        />
      )}
      {displayConfirmQuoteActionModal && (
        <NudosConfirmActionModal
          firstButtonAction={() => approveOrRejectLogisticQuoteFunction('rejected')}
          firstButtonText="Sí, rechazar cotización"
          secondButtonAction={() => setShowConfirmQuoteActionModal(false)}
          secondButtonText="No, continuar con el servicio"
          modalText="Si rechazas la cotización, se cancelará el servicio automáticamente ¿Seguro que deseas continuar?"
        />
      )}
      <div className="logisticServiceAddressContainer">
        <div className="title">Revisa la cotización para continuar con tu servicio</div>
        <div>Aprueba o rechaza la cotización para poder continuar con tu servicio</div>
        <div className="informationContainer">
          <div>{t(`${translationKey}moreInformationText`)}</div>
          <div className="contactSupport" onClick={handleClickForHelp}>
            {t(`${translationKey}contactSupportText`)}
          </div>
        </div>
        <NudosButton
          buttonText="Revisar"
          customWidth="80px"
          customHeight="18px"
          customClassName="checkAddressButton"
          handleClick={() => setShowReviewQuoteModal(true)}
        />
      </div>
      <img className="logisticQuoteIcon" src={logisticQuoteIcon} />
    </div>
  );
};

export default ApproveOrRejectLogisticNotificacion;

import { FC, useEffect, useState } from 'react';
import { IconDropdownArrow } from '../../../../assets/DesignSystem/SVGComponents';
import {
  InformationText,
  LogisticsQuoteByTool,
  TotalDescription
} from '../../LogisticsQuoteAndBillingStep/LogisticServicePrincingQuote/Components';
import { ILogisticQuoteGeneralData } from '../../../../types/logisticsServicesModule.types';
import { formatCost } from '../../../../utils/formatNumbers';
import { TstoragePlace } from '../../../../types/assignationFluxes';
import './TotalsDropdown.scss';

interface ITotalsDropDown {
  quoteData: ILogisticQuoteGeneralData;
  originPlace?: TstoragePlace;
  destinationPlace?: TstoragePlace;
}

const TotalsDropdown: FC<ITotalsDropDown> = ({ quoteData, originPlace, destinationPlace }: ITotalsDropDown) => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const [generalTypePlan, setGeneralTypePlan] = useState<'Free' | 'Care' | 'Lite'>('Free');

  const initialSetData = () => {
    if (quoteData?.nudosCareCouldSaving === 0) {
      setGeneralTypePlan('Care');
    } else {
      //si quieren mas empresas "Vip" se debe considerar cambiar la logica
      setGeneralTypePlan(quoteData?.platformSubscription === 'Vip' ? 'Lite' : quoteData?.platformSubscription);
    }
  };

  useEffect(() => {
    initialSetData();
  }, [quoteData]);

  return (
    <div id="totalsDropdown">
      <div className="boxPrimaryContainer">
        <div className="primaryContainer">
          <div className="blackTitle">Total a pagar </div>
          <div className="orangeTitle">
            &nbsp;{`$${formatCost(Number(quoteData.total || 0))} ${quoteData?.currency}`}
          </div>
          <div className={'minArrowContainer '} onClick={() => setOpenDropDown(!openDropDown)}>
            <IconDropdownArrow className={openDropDown ? 'rotateArrow' : ''} />
          </div>
        </div>
      </div>
      {openDropDown && (
        <div className="boxSecondaryContainer">
          <div className="secondaryContainer">
            <div className="containerToolsDetails">
              <LogisticsQuoteByTool
                logisticServiceQuoteData={quoteData}
                originPlace={originPlace}
                destinationPlace={destinationPlace}
              />
            </div>
            <div className="graySpace" />
            <TotalDescription logisticServiceQuoteData={quoteData} typePlan={generalTypePlan} />
            <InformationText logisticServiceQuoteData={quoteData} typePlan={generalTypePlan} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalsDropdown;

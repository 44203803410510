import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import useSWR from 'swr';
import {
  LoaderProductDetails,
  ModalCountryChangeAlert,
  SecondaryImages,
  SingleCountryAddToCartButton
} from '../Components';
import { NudosBreadcrumbButton } from '../../../components/NudosComponents';
import useCartState from '../../../state/useCartState';
import { BlackArrowDownload } from '../../../assets/images/views/Ecommerce';
import urlParameter from '../../../utils/urlParameter';
import { FooterBanner } from '../../RequestLogistics/LogisticsQuoteAndBillingStep/LogisticServicePrincingQuote/Components';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { FloatCartDetail } from '../../../components';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../utils/segment';
import { nudosFormatCurrency } from '../../../utils/formatNumbers';
import IconGreenThunder from '../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { IconArrow } from '../../../assets/DesignSystem/SVGComponents';
import { productsNumber } from '../../../utils/cart';
import { getProductDetails } from '../../../services/services';
import { formatOrgData } from '../../../utils/orgFormatData';
import useStoreCountryState, { IStoreCountry } from '../../../state/useStoreCountryState';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { IProductsUpdate } from '../../../types/cart';
import { getShoppingCart, updateProductCart } from '../../../services/cart.services';
import useShopStore from '../../../state/useShopContext';
import { GenericLoader } from '../../Admin/dashboard/components';
import useCheckoutStore from '../checkout/state';
import useLanguagePlatformState from '../../../state/useLenguageState';
import {
  getCountryDataFromCountryCode,
  getCountryName,
  getListOfCountriesByOfferedService
} from '../../../utils/getCountries';
import { ICountryDetail } from '../../../types/countries';
import RedirectionToCheckoutView from '../../RedirectionToCheckoutView/RedirectionToCheckoutView';
import './InternalProductCatalog.scss';

const InternalProductCatalog = () => {
  const { t } = useTranslation();
  const { replace, push } = useHistory();
  const { search, pathname } = useLocation();
  const orgInfo = formatOrgData();
  const { setStoreCountry, storeCountry } = useStoreCountryState();
  const { step: checkoutStep, setStep } = useCheckoutStore();
  const { stateSubscription } = useStateSubscriptions();
  const { platformLanguage } = useLanguagePlatformState();
  const { itemsCart, setCart } = useShopStore();
  const { openCart, setGlobalShippingCart } = useCartState();
  const totalItemsCart = productsNumber();
  const { state }: { state: { returnUrl: string; returnText: string } } = useLocation();
  const { id } = useParams<{ id: string }>();

  const [openChangeCountryModal, setOpenChangeCountryModal] = useState<IStoreCountry>();
  const [loadingSpecificCountry, setLoadingSpecificCountry] = useState<number>();
  const [loadingAndDeleteCart, setLoadingAndDeleteCart] = useState<boolean>(false);
  const [totalSaving, setTotalSaving] = useState<string>('0');
  const [hoverCountry, setHoverCountry] = useState<string>();
  const [aSuccessfulUploadWasPerformed, setASuccessfulUploadWasPerformed] = useState<boolean>(false);
  const [allCountries, setAllCountries] = useState<ICountryDetail[]>();

  const SWRKey =
    storeCountry && storeCountry?.countryCode
      ? `${id}?countryCode=${storeCountry?.countryCode}&organizationId=${orgInfo?.organizationId}`
      : '';

  const spanishLanguage = platformLanguage === 'esMX';
  const searchParams = new URLSearchParams(search);
  const { data: productDetailData, error: productDetailError } = useSWR(SWRKey, getProductDetails);
  const productLoading = !productDetailData && !productDetailError;
  const { levelSubscription, isPrime } = stateSubscription;
  const typePlan = levelSubscription ? levelSubscription : 'Free';
  const originSeeker = !!urlParameter('originSeeker');
  const showSpecialElements = Number(productDetailData?.prices?.pricePrimeUsd || 0) >= 170;
  const { returnUrl, returnText } = state || {};
  const translationKey = 'nodi:checkout:';
  const translationsEcommerce = 'ecommerce:productDetail:';
  const navigationCountryCode = searchParams.get('countryCode') || undefined;
  const navigationCountryDiffersFromLocalCountry =
    !!navigationCountryCode && !!storeCountry?.countryCode && navigationCountryCode !== storeCountry?.countryCode;
  const hasProductsSelected = !!itemsCart?.products?.length;
  const navigationCountryData = getCountryDataFromCountryCode(navigationCountryCode, allCountries);
  const productURL = pathname + search;
  const productDetailErrorAndNoData = productDetailError && !productDetailData;

  const changeCountryStoreValidation =
    productDetailErrorAndNoData &&
    !aSuccessfulUploadWasPerformed &&
    navigationCountryDiffersFromLocalCountry &&
    !hasProductsSelected &&
    navigationCountryData &&
    !loadingSpecificCountry;

  const redirectToConfirmationViewValidation =
    productDetailErrorAndNoData &&
    !aSuccessfulUploadWasPerformed &&
    navigationCountryDiffersFromLocalCountry &&
    hasProductsSelected &&
    !loadingAndDeleteCart;

  const URLCountryData = {
    id: navigationCountryData?.id || 0,
    countryFlag: navigationCountryData?.flag || '',
    countryName: navigationCountryData?.name || '',
    countryCode: navigationCountryData?.code || '',
    nameStringId: navigationCountryData?.nameStringId || ''
  };

  const changeStoreCountry = async () => await updateStoreCountryEmptyCart(URLCountryData, true);
  const resetCheckoutStep = () => checkoutStep !== 0 && setStep(0);

  const returnAction = () => {
    if (returnUrl) return replace(returnUrl);
    replace('/catalogue/home');
  };

  const setNewLanguageOnThePlatform = async (specialCountry?: boolean) => {
    const dataCart = await getShoppingCart(Number(orgInfo?.userId), Number(orgInfo?.organizationId));
    const { country } = dataCart;
    const newCountryData = specialCountry ? navigationCountryData : country;
    if (newCountryData) {
      setStoreCountry({
        countryCode: newCountryData?.code,
        countryFlag: newCountryData.flag,
        countryName: newCountryData.name,
        id: newCountryData.id,
        nameStringId: newCountryData?.nameStringId || ''
      });
      setOpenChangeCountryModal(undefined);
      !specialCountry && displaySuccessNotification({ customJSXMessage: <>Cambiaste el país de compra con éxito</> });
    }
  };

  const updateStoreCountryEmptyCart = async (country: IStoreCountry, specialCountry?: boolean) => {
    setLoadingSpecificCountry(country.id);
    const createCartBody: IProductsUpdate = {
      userId: Number(orgInfo?.userId),
      havePrime: true,
      organizationId: Number(orgInfo?.organizationId),
      products: [],
      countryCode: country?.countryCode || ''
    };
    try {
      await updateProductCart(createCartBody);
      await setNewLanguageOnThePlatform(specialCountry);
      resetCheckoutStep();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingSpecificCountry(undefined);
    }
  };

  const updateStoreCountryCartWithProducts = async (specialCountry?: boolean) => {
    const newCountryData = specialCountry ? URLCountryData : openChangeCountryModal;
    const createCartBody: IProductsUpdate = {
      userId: Number(orgInfo?.userId),
      havePrime: true,
      organizationId: Number(orgInfo?.organizationId),
      products: [],
      countryCode: newCountryData?.countryCode || ''
    };
    if (itemsCart?.shoppingCartId && newCountryData) {
      setLoadingAndDeleteCart(true);
      try {
        const resetedCart = {
          shoppingCartId: null,
          userId: Number(orgInfo?.userId),
          organizationId: Number(orgInfo?.organizationId),
          references: null,
          numberOfProducts: null,
          total: null,
          products: null,
          totalDiscountValue: null,
          totalWithoutPrime: null
        };
        setCart(resetedCart);
        setGlobalShippingCart(undefined);
        localStorage.setItem('bagItems', JSON.stringify(resetedCart));
        localStorage.removeItem('currentOrderShippingData');
        localStorage.setItem('resetCheckoutSteps', 'true');
        await updateProductCart(createCartBody);
        await setNewLanguageOnThePlatform(specialCountry);
        resetCheckoutStep();
        push(productURL);
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoadingAndDeleteCart(false);
      }
    }
  };

  useEffect(() => {
    if (!totalSaving) setTotalSaving(totalSaving);
  }, [totalSaving]);

  useEffect(() => {
    if (productDetailData) {
      segmentTrackEvent({
        catalogueProductView: {
          SKUId: productDetailData.sku || '',
          ProductName: productDetailData?.productName,
          TypeOfPlatform: isPrime ? 'Prime' : 'Free'
        }
      });
      setASuccessfulUploadWasPerformed(true);
    }
  }, [productDetailData]);

  useEffect(() => {
    getListOfCountriesByOfferedService(setAllCountries);
  }, []);

  if (productLoading) return <LoaderProductDetails />;

  if (productDetailError && !productDetailData && aSuccessfulUploadWasPerformed) push('/catalogue/home');

  if (redirectToConfirmationViewValidation) {
    return <RedirectionToCheckoutView openLinkAction={() => updateStoreCountryCartWithProducts(true)} />;
  }

  if (changeCountryStoreValidation) changeStoreCountry();

  if (productDetailErrorAndNoData && !aSuccessfulUploadWasPerformed && !navigationCountryDiffersFromLocalCountry)
    push('/catalogue/product-not-found');

  return (
    <>
      {productDetailData && (
        <main id="internalProductCatalog" style={{ height: 'calc(100vh - 54px)' }}>
          {openChangeCountryModal && (
            <ModalCountryChangeAlert
              loadingModal={loadingAndDeleteCart}
              firstAction={() => {
                segmentTrackEvent({ catalogueChangeCountryNoClick: null });
                setOpenChangeCountryModal(undefined);
              }}
              secondAction={() => updateStoreCountryCartWithProducts()}
            />
          )}
          {openCart && <FloatCartDetail />}
          <section className="left-section">
            <NudosBreadcrumbButton
              customClassName="mb-6"
              returnText={returnText || productDetailData?.categoryName || t('nodi:nudosModalContentTextBack')}
              returnAction={() => returnAction()}
            />
            <section className="name-pills-brand-section extraNameAndPills">
              <div className="commercial-name-and-brand-container" style={{ marginBottom: '8px' }}>
                <h2 className="commercial-name changesNewStore">
                  {productDetailData?.productName?.length > 40
                    ? `${productDetailData?.productName.slice(0, 40)}...`
                    : productDetailData?.productName}
                </h2>
                {productDetailData?.brandImage ? (
                  <div className="brand-image-container">
                    <img src={productDetailData?.brandImage} alt="brand image" />
                  </div>
                ) : (
                  <p className="brand">{productDetailData.brandName}</p>
                )}
              </div>
              <div className="newStorePillsContainer">
                {productDetailData.pills && (productDetailData.pills['Perfil'] || productDetailData.pills['Perfil 1']) && (
                  <div className="pill profile cursor-pointer">
                    <p className="pill-text">
                      {productDetailData.pills['Perfil'] || productDetailData.pills['Perfil 1']}
                    </p>
                    <div className="hover-container">
                      <p className="hover-text">{t(`nodi:checkout:profile`)}</p>
                    </div>
                  </div>
                )}
                {productDetailData.pills && productDetailData.pills['Gama'] && (
                  <div className="pill line cursor-pointer">
                    <p className="pill-text">{productDetailData.pills['Gama']}</p>
                    <div className="hover-container">
                      <p className="hover-text">Gama</p>
                    </div>
                  </div>
                )}
                {productDetailData.pills &&
                  Object.entries(productDetailData.pills).length > 0 &&
                  Object.entries(productDetailData.pills).map(([key, value]) => {
                    if (value && !key.includes('Perfil') && key !== 'Gama')
                      return (
                        <div key={uuid()} className="pill technical-detail cursor-pointer">
                          <p className="pill-text">{value.length > 14 ? `${value.slice(0, 14)}...` : value}</p>
                          <div className="hover-container">
                            <p className="hover-text">{key}</p>
                          </div>
                        </div>
                      );
                  })}
              </div>
              {productDetailData.sku && !originSeeker && false && <p className="sku">SKU: {productDetailData?.sku}</p>}
            </section>
            <div className="tagsContainerNewStore">
              <div className="titleTags">Tags</div>
              <div className="productTag">
                <div className="textTag">{t(`${translationKey}product`)}</div>
              </div>
              <div className="productTag">
                <div className="textTag">{t(`${translationKey}tech`)}</div>
              </div>
            </div>
            <section className="description-section" style={{ marginBottom: '16px' }}>
              <p className="descriptionItemNewStore">
                {productDetailData.longDescription ? productDetailData.longDescription : productDetailData.description}
              </p>
            </section>
            <div className="estimatedDeliveryContainer">
              <div className="grayText">{t(`${translationsEcommerce}estimatedDelivery`)}</div>
              <div className="minFlagContainer">
                <div
                  className="minFlagBox"
                  style={{
                    backgroundImage: `url("${storeCountry?.countryFlag}")`
                  }}
                />
              </div>
              <div className="textDays">
                {`${storeCountry?.countryName}: ${productDetailData?.estimatedDeliveryDate?.minDeliverTime}-${
                  productDetailData?.estimatedDeliveryDate?.maxDeliverTime
                } ${!spanishLanguage ? t(`${translationsEcommerce}businessDays`) : ''}  ${t(
                  `${translationKey}${productDetailData?.estimatedDeliveryDate.deliveryPeriod}`
                )} ${spanishLanguage ? t(`${translationsEcommerce}businessDays`) : ''}`}
              </div>
            </div>
            <section
              className={`${
                productDetailData.categoryName !== 'Accesorios' &&
                productDetailData?.specs &&
                Object.entries(productDetailData?.specs).length > 0
                  ? 'technical-specifications-section extraSpecificationsSection'
                  : 'accessory-technical-specification'
              }`}
            >
              <div className="technical-specifications-container">
                <div className="first-column">
                  {productDetailData?.specs &&
                    Object.entries(productDetailData?.specs).length > 0 &&
                    Object.entries(productDetailData?.specs).map(([key, value], i) => {
                      if (value && i < 4)
                        return (
                          <span key={uuid()} className="detail-container">
                            <p className="detail-name detail-name-new-store">{t(`services:specs:${key}`)}</p>
                            <p className="detail-value">{value}</p>
                          </span>
                        );
                    })}
                </div>
                <div className="spaceLine" />
                <div className="second-column secondColumnNewStore">
                  {productDetailData?.specs &&
                    Object.entries(productDetailData?.specs).length > 0 &&
                    Object.entries(productDetailData?.specs).map(([key, value], i) => {
                      if (value && i >= 4)
                        return (
                          <span key={uuid()} className="detail-container">
                            <p className="detail-name detail-name-new-store">{t(`services:specs:${key}`)}</p>
                            <p className="detail-value">{value}</p>
                          </span>
                        );
                    })}
                </div>
              </div>
            </section>
            {!!productDetailData && !!productDetailData?.dataSheet && (
              <button
                className="downLoadFileButton"
                onClick={() => window.open(productDetailData?.dataSheet || '', '_blank')}
              >
                {t(`${translationKey}downLoadFileButton`)} <BlackArrowDownload className="ml-1" />
              </button>
            )}
            {showSpecialElements && (
              <div
                className="subscriptionBannerContainer subscriptionBannerContainerNewStore"
                onClick={() => {
                  if (isPrime)
                    segmentTrackEvent({
                      primeProductBannerClick: {
                        SKUId: `${productDetailData?.sku}`,
                        TotalSaving: totalSaving
                      }
                    });
                }}
              >
                <FooterBanner
                  clickInformation={() => {
                    segmentTrackEvent({
                      catalogueFreeProductBannerClick: {
                        SKUId: `${productDetailData?.sku}`,
                        TotalSaving: totalSaving,
                        ProductName: productDetailData?.productName || ''
                      }
                    });
                  }}
                  originBanner="catalogueProductDetail"
                  typePlan={typePlan === 'Vip' ? 'Lite' : typePlan}
                />
              </div>
            )}
            {!!productDetailData?.availableCountries && productDetailData?.availableCountries.length > 0 && (
              <>
                <div className="lineGraySpace" />
                <div className="pricesListContainer">
                  <div className="titleListContainer">{t(`${translationsEcommerce}priceOnOtherCountries`)}</div>
                  <div className="flagListBox">
                    {productDetailData?.availableCountries.map((countryAvailable, index) => {
                      return (
                        <div key={uuid()} className="countryListContainer">
                          {!!loadingSpecificCountry && loadingSpecificCountry === countryAvailable.id ? (
                            <div className="w-full h-full flex items-center content-center justify-center">
                              <GenericLoader sizeLoader={10} borderWidth={2} />
                            </div>
                          ) : (
                            <>
                              <div className="flagList">
                                <div
                                  className="flagBoxList"
                                  style={{
                                    backgroundImage: `url("${countryAvailable?.countryFlag}")`
                                  }}
                                ></div>
                              </div>
                              {countryAvailable && (
                                <div className="countryName">
                                  {getCountryName({
                                    code: countryAvailable?.countryCode,
                                    flag: countryAvailable?.countryFlag,
                                    id: countryAvailable?.id,
                                    name: countryAvailable?.countryName,
                                    nameStringId: countryAvailable?.nameStringId
                                  })}
                                </div>
                              )}
                              {showSpecialElements ? (
                                <>
                                  {isPrime ? (
                                    <>
                                      <div className="firstPricePrime">{`(${nudosFormatCurrency({
                                        value: Number(countryAvailable?.prices?.priceWithoutPrimeUsd),
                                        maximumFractionDigits: 2
                                      })} USD)`}</div>
                                      <div className="centerIconPrice">
                                        <IconGreenThunder fill="#7B61FF" />
                                      </div>
                                      <div className="secondPricePrime">{`${nudosFormatCurrency({
                                        value: Number(countryAvailable?.prices?.pricePrimeUsd),
                                        maximumFractionDigits: 2
                                      })} USD`}</div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="firstPrice">{`${nudosFormatCurrency({
                                        value: Number(countryAvailable?.prices?.priceWithoutPrimeUsd),
                                        maximumFractionDigits: 2
                                      })} USD`}</div>
                                      <div className="secondGrayPrice">(</div>
                                      <div className="centerIconPrice">
                                        <IconGreenThunder fill="#7B61FF" />
                                      </div>
                                      <div className="secondGrayPrice line-through">{`${nudosFormatCurrency({
                                        value: Number(countryAvailable?.prices?.pricePrimeUsd),
                                        maximumFractionDigits: 2
                                      })} USD)`}</div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <div className="firstPrice">{`${nudosFormatCurrency({
                                  value: Number(countryAvailable?.prices?.pricePrimeUsd),
                                  maximumFractionDigits: 2
                                })} USD`}</div>
                              )}
                              <div
                                className="redirectionArrowContainer"
                                style={{ right: !showSpecialElements ? '134px' : '' }}
                                onMouseOverCapture={() => setHoverCountry(`item-country-${String(index)}`)}
                                onMouseMoveCapture={() => setHoverCountry(`item-country-${String(index)}`)}
                                onMouseOutCapture={() => setHoverCountry(undefined)}
                                onMouseOut={() => setHoverCountry(undefined)}
                              >
                                <div
                                  className="boxArrowContainer"
                                  onClick={() => {
                                    segmentTrackEvent({
                                      internalProductStoresClick: {
                                        CountryName: countryAvailable?.countryName || '',
                                        SKUId: `${productDetailData?.sku}`,
                                        ProductName: productDetailData.productName || ''
                                      }
                                    });
                                    if (itemsCart?.shoppingCartId && totalItemsCart > 0 && !loadingSpecificCountry) {
                                      setOpenChangeCountryModal(countryAvailable);
                                    } else {
                                      updateStoreCountryEmptyCart(countryAvailable);
                                    }
                                  }}
                                >
                                  {hoverCountry === `item-country-${index}` && (
                                    <div className="blueArrowText">{t(`${translationsEcommerce}goToStore`)}</div>
                                  )}
                                  <div className="blueArrow">
                                    <IconArrow stroke="#54A1FC" />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                    {productDetailData?.availableCountries?.length >= 4 && <div className="finalContainer" />}
                  </div>
                </div>
              </>
            )}
          </section>

          <section className="right-section">
            <SecondaryImages product={productDetailData} />
            <div className="containerPrices">
              {!!storeCountry && !!productDetailData && (
                <SingleCountryAddToCartButton
                  productInfo={productDetailData}
                  showSpecialElements={showSpecialElements}
                  setTotalSaving={totalSaving => setTotalSaving(totalSaving)}
                />
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default InternalProductCatalog;

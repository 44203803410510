import { useTranslation } from 'react-i18next';
import { IBillingMethod } from '../../../../../../types/orders';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { getOrderRequiredDataByPaymentMethod } from '../../../../../../utils/productDefinitions';
import { NudosToolTip, NudosUploadButton } from '../../../../../../components/NudosComponents';
import { formatRoundedUpNumber } from '../../../../../../utils/formatNumbers';
import { PAYMENT_TYPES } from '../../../../../../types/checkout';
import { NudosLinkTypeButton } from '../../../../../../components/DesignSystem';
import { isValidLink } from '../../../../../../utils/formValidations';
import { IbankAccountInfo } from '../../../../../../types/cart';
import { TlogisticsStatus } from '../../../../../../types/logisticsServicesModule.types';
import './PaymentMethodAndLink.scss';

interface IPaymentMethodAndLink {
  paymentMethod?: string | null;
  proofOfPaymentUrl?: string | null;
  paymentLink?: string | null;
  paymentDate?: string | null;
  committedPaymentDate?: string | null;
  exchangeRate?: string | null;
  paymentMethodStringId?: string | null;
  isPaymentReceived?: boolean;
  isLogisticService?: boolean;
  bankAccountInfo?: IbankAccountInfo;
  manualQuote?: boolean;
  quoteDocumentUrl?: string;
  billingMethod?: IBillingMethod;
  invoiceUrl?: string;
  status?: TlogisticsStatus | null;
  paymentType?: string;
}

const PaymentMethodAndLink = ({
  paymentMethodAndLink,
  uploadProofOfPaymentCallback,
  openPaymentInfoModal,
  isLogisticServiceFlux
}: {
  paymentMethodAndLink?: IPaymentMethodAndLink;
  uploadProofOfPaymentCallback: (file?: File) => Promise<void>;
  openPaymentInfoModal: () => void;
  isLogisticServiceFlux?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    paymentMethod,
    proofOfPaymentUrl,
    paymentLink,
    paymentDate,
    committedPaymentDate,
    exchangeRate,
    paymentMethodStringId,
    isPaymentReceived,
    isLogisticService,
    manualQuote,
    quoteDocumentUrl,
    billingMethod,
    invoiceUrl,
    status,
    paymentType
  } = paymentMethodAndLink || {};

  const { requiresProofOfPayment, requiresPaymentLink, showLocalCurrency, requiresBankData } =
    getOrderRequiredDataByPaymentMethod(paymentMethod || '', billingMethod, isLogisticServiceFlux);

  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:';
  const paymentMethodText = paymentMethodStringId
    ? t(`services:paymentType:${paymentMethodStringId}`)
    : paymentMethod || t(`${i18nLanguageKey}paymentMethodValue`);

  const exchangeRateValue = exchangeRate ? formatRoundedUpNumber(+exchangeRate, 2) : t(`${i18nLanguageKey}values`);
  const displayExchangeRate = isLogisticService ? showLocalCurrency && !manualQuote : showLocalCurrency;
  const paymentMethodisARS = paymentMethod === PAYMENT_TYPES.TRANSFER_ARS;
  const countryAdjustedExchangeRateValue = paymentMethodisARS ? 'al valor del mercado' : exchangeRateValue;
  const validPaymentLink = isValidLink(paymentLink || '') ? paymentLink : undefined;

  const exchangeRateText = `${t(`${i18nLanguageKey}exchangeRate`)} ${countryAdjustedExchangeRateValue}`;
  const displayPaymentLink = requiresPaymentLink && (isLogisticService ? !isPaymentReceived : true);
  const displayCoveredWithNudosCare = isLogisticServiceFlux && paymentType === 'Care';

  const displayLogisticPayed =
    requiresPaymentLink && isPaymentReceived && isLogisticService && !displayCoveredWithNudosCare;
  const requireQuoteToDisplayLink = manualQuote && !quoteDocumentUrl && !paymentMethodisARS;
  const requireInvoiceToDisplayLink = manualQuote && !invoiceUrl && paymentMethodisARS;
  const tootilValue = requireQuoteToDisplayLink ? 'apruebes la cotización' : 'la factura esté disponible';
  const disabledNudosLinkButton = isLogisticServiceFlux && (requireQuoteToDisplayLink || requireInvoiceToDisplayLink);
  const diplayBankAccountInfo = (requiresBankData || paymentMethodisARS) && status !== 'cancelado';

  return (
    <div className="paymentMethodAndLink">
      <div className="paymentInfoTitle paymentMethodTitle">{t(`${i18nLanguageKey}paymentMethodTitle`)}</div>
      {!displayCoveredWithNudosCare && (
        <div className="informationSection">
          <div className="paymentField paymentMethod">{paymentMethodText}</div>
          {diplayBankAccountInfo && (
            <div className="flex gap-1 items-center">
              {disabledNudosLinkButton && (
                <NudosToolTip tooltipContent={<>Podrás ver los datos de pago cuando {tootilValue}</>} type="info" />
              )}
              <NudosLinkTypeButton
                customClassname="paymentField"
                text={t(`${i18nLanguageKey}nudosLinkTypeButtonText`)}
                handleClickCallback={openPaymentInfoModal}
                isDisabled={disabledNudosLinkButton}
                hideIcon={disabledNudosLinkButton}
              />
            </div>
          )}

          {displayExchangeRate && <div className="exchangeRate">{exchangeRateText}</div>}
          {requiresProofOfPayment && (
            <div className={`paymentField proofOfPaymentButton ${requiresPaymentLink ? 'fullLine' : ''}`}>
              <NudosUploadButton
                uploadedFileHandler={uploadProofOfPaymentCallback}
                isFilled={!!proofOfPaymentUrl}
                buttonEmptyText={t(`${i18nLanguageKey}proofOfPaymentButton:buttonEmptyText`)}
                buttonFilledText={t(`${i18nLanguageKey}proofOfPaymentButton:buttonFilledText`)}
                height="16px"
                redFilled={!!committedPaymentDate && !proofOfPaymentUrl}
              />
              <div className="emoji">{proofOfPaymentUrl ? '👍' : '⚠️'}</div>
            </div>
          )}
          {displayPaymentLink && (
            <div className="paymentField paymentLink">
              {!validPaymentLink && (
                <div className="paymentLinkPending"> {paymentLink || t(`${i18nLanguageKey}paymentLinkPending`)}</div>
              )}
              {validPaymentLink && (
                <a className="activePaymentLink" href={validPaymentLink} target="blank">
                  Da clic aquí para pagar
                </a>
              )}
            </div>
          )}
          {displayLogisticPayed && <div>✅ Pago recibido</div>}
          {committedPaymentDate && (
            <div className="paymentField containerDateCommitment">
              <div className={`textCommitment ${paymentDate ? 'simpleText' : 'redText'}`}>
                {`${paymentDate ? 'Pagado el ' : 'Acuerdo de pago para'} `}{' '}
                <span className="bigText">
                  {formatShortDate(paymentDate || committedPaymentDate || '')?.textWithTimezone}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {displayCoveredWithNudosCare && <div>✅ Cubierto con Nudos Care</div>}
    </div>
  );
};

export default PaymentMethodAndLink;

import { NudosGenericModal, NudosModalContent } from '../../../../../../../../components/NudosComponents';
import ReviewLogisticQuoteModalContent from './ReviewLogisticQuoteModalContent';
import { IlogisticServiceDetailedData } from '../../../../../../../../types/logisticsServicesModule.types';
import './ReviewLogisticQuoteModal.scss';

const ReviewLogisticQuoteModal = ({
  closeModalCallback,
  logisticServiceData,
  handleLsRejection,
  approveOrRejectLogisticQuote
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  closeModalCallback: () => void;
  handleLsRejection: () => void;
  approveOrRejectLogisticQuote: (approveOrReject: 'approved' | 'rejected') => void;
}) => {
  return (
    <div className="reviewLogisticQuoteModal">
      <NudosGenericModal
        closeModalCallback={() => closeModalCallback}
        modalContent={
          <NudosModalContent
            toCloseModal={closeModalCallback}
            customWidth="466px"
            hideNudosIcon
            customHeight="340px"
            modalContentTitle="Revisar cotización del servicio"
            CustomModalComponent={
              <ReviewLogisticQuoteModalContent
                approveOrRejectLogisticQuote={approveOrRejectLogisticQuote}
                logisticServiceData={logisticServiceData}
                handleLsRejection={handleLsRejection}
              />
            }
          />
        }
      />
    </div>
  );
};

export default ReviewLogisticQuoteModal;

import { NudosGenericModal, NudosModalContent } from '../../NudosComponents';
import { ICountryDetail } from '../../../types/countries';
import { countriesRequiringTaxSituationDocument } from '../../../utils/productDefinitions';
import { useTranslation } from 'react-i18next';
import BordUpdateBillingDataModalContent from './BordUpdateBillingDataModalContent';
import { IbillingData } from '../../../types/checkout';
import './BordUpdateBillingDataModal.scss';

const BordUpdateBillingDataModal = ({
  initialData,
  billingCountryData,
  closeModalCallback,
  updateBillingData,
  orderOrLogisticId
}: {
  initialData: IbillingData;
  billingCountryData?: ICountryDetail;
  closeModalCallback: React.Dispatch<React.SetStateAction<boolean>>;
  updateBillingData: () => void;
  orderOrLogisticId: number | string;
}) => {
  const { t } = useTranslation();

  const i18nLanguageKey = 'nodi:orderDetails:updateOrderBillingDataFlux:';
  const isAMexicanOrganization = !!initialData?.documentCountry?.code && initialData?.documentCountry?.code === 'mx';
  const requiresTaxDocument = countriesRequiringTaxSituationDocument.includes(initialData?.documentCountry?.code || '');
  const organizationIsUruguay = !!initialData?.documentCountry?.code && initialData?.documentCountry?.code === 'uy';

  const getHeight = () => {
    if (organizationIsUruguay) return '490px';
    if (isAMexicanOrganization) return '556px';
    if (requiresTaxDocument) return '480px';
    if (!initialData?.documentCountry?.code) return '556px';
    return '418px';
  };

  return (
    <div className="updateOrderBillingDataFlux">
      <NudosGenericModal
        modalContent={
          <NudosModalContent
            toCloseModal={closeModalCallback}
            customHeight={getHeight()}
            customWidth="780px"
            modalContentTitle={t(`${i18nLanguageKey}modalContentTitle`)}
            CustomModalComponent={
              <BordUpdateBillingDataModalContent
                initialData={initialData}
                closeModalCallback={closeModalCallback}
                billingCountryData={billingCountryData}
                updateBillingData={updateBillingData}
                orderOrLogisticId={orderOrLogisticId}
              />
            }
          />
        }
      />
    </div>
  );
};

export default BordUpdateBillingDataModal;

import { useTranslation } from 'react-i18next';
import { CloseFile } from '../../../assets/images/views/Ecommerce';
import CopyIcon from '../../../assets/images/views/Ecommerce/CopyIcon';
import useCartState from '../../../state/useCartState';
import { IspeiRequiredDataResponse, PAYMENT_TYPES } from '../../../types/checkout';
import { Tany } from '../../../types/global';
import { formatRoundedUpNumber } from '../../../utils/formatNumbers';
import { GenericLoader } from '../../../views/Admin/dashboard/components';
import { NudosHoverText } from '../../NudosComponents';
import { chargeFile } from '../../../utils/validateFile';
import { useEffect, useState } from 'react';
import { INudosTransfDetailsData } from '../../../types/cart';
import { getSpeiBankData } from '../../../services/checkout';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import NudosLinkTypeButton from '../NudosLinkTypeButton/NudosLinkTypeButton';
import './NudosTransfDetails.scss';

/**
 * @param {Object} props - The component props.
 * @param {INudosTransfDetailsData | undefined} props.specialData - The special data containing bank account information and payment details.
 */

const NudosTransfDetails = ({ specialData }: { specialData: INudosTransfDetailsData | undefined }) => {
  const { t } = useTranslation();
  const { dataFinishCart } = useCartState();

  const [uploadFile, setUploadFile] = useState<Tany>();
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [SPEIBankData, setSPEIBankData] = useState<IspeiRequiredDataResponse>();

  const translationKey = 'nodi:billingDataSection:nudosTransfDetails:';
  const noDataText = t(`${translationKey}noData`);
  const numberAccountText = t(`${translationKey}numberAccount`);
  const checkingAccountText = t(`${translationKey}checkingAccount`);
  const beneficiaryTitle = t(`${translationKey}beneficiaryTitle`);
  const referenceText = t(`${translationKey}reference`);

  const { TRANSFER_COP, TRANSFER_PEN, TRANSFER_ARS } = PAYMENT_TYPES;

  const {
    bankAccountInfo,
    currency,
    orderId,
    paymentMethod,
    paymentType,
    totalUsd,
    total,
    orderTotal,
    currencyCode,
    quoteDocumentUrl,
    manualQuote,
    invoiceUrl
  } = specialData || {};

  const { accountNumber, beneficiary, beneficiaryAddress, cbu, cuit, clabe, beneficiaryBank, reference } =
    bankAccountInfo || {};

  const paymentMethodData = paymentMethod || paymentType;
  const hasClabeAndAccountNumber = clabe && accountNumber;

  const invoiceIsUy = accountNumber?.includes('7252');
  const beneficiaryCountryIsPeru = beneficiary?.toLowerCase().includes('peru') && orderId;
  const hasAccountNumber =
    invoiceIsUy || beneficiaryCountryIsPeru || paymentMethodData === TRANSFER_COP || !!SPEIBankData?.accountNumber;

  const clabeValue = SPEIBankData?.clabe || SPEIBankData?.accountNumber || clabe;
  const accountNumberOrCLABE = accountNumber || clabeValue || noDataText;
  const bankName = bankAccountInfo?.bank || SPEIBankData?.bank || noDataText;
  const beneficiaryValue =
    beneficiary || SPEIBankData?.reference || SPEIBankData?.beneficiary || reference || noDataText;
  const beneficiaryOrReferenceTitle = SPEIBankData?.reference || reference ? referenceText : beneficiaryTitle;

  const displayAddress = beneficiaryAddress || SPEIBankData?.beneficiaryAddress;
  const displayRUT = bankAccountInfo?.rut || SPEIBankData?.rut;
  const displayCCI = SPEIBankData?.cci || bankAccountInfo?.cci;
  const displaySwift = bankAccountInfo?.swift || SPEIBankData?.swift;
  const displayDocumentInput = dataFinishCart && (!!invoiceIsUy || !!displaySwift);

  const transferIsARS = paymentMethodData === TRANSFER_ARS;
  const displayARSLayout = transferIsARS || currency === 'USD';
  const beneficiaryBankValue = beneficiaryBank || SPEIBankData?.beneficiaryBank;
  const accountDetailsTitle =
    (hasAccountNumber && numberAccountText) || (displayARSLayout && checkingAccountText) ? numberAccountText : 'CLABE';
  const paymentInPenCurrency = currency === TRANSFER_PEN || dataFinishCart?.paymentType === TRANSFER_PEN;
  const currencySymbol = paymentInPenCurrency ? 'S/' : '$';
  const countriesToDisplayPrinceInUSD = beneficiaryCountryIsPeru || (orderId ? displayARSLayout : undefined);

  const valueToPay = SPEIBankData?.amountRemaining || (countriesToDisplayPrinceInUSD ? totalUsd : total) || orderTotal;
  const transferValue = valueToPay ? formatRoundedUpNumber(Number(valueToPay), 2) : noDataText;
  const transferCurrencyCode = (orderId ? displayARSLayout : undefined)
    ? 'USD'
    : currency || currencyCode || noDataText;

  const transferValueText = manualQuote
    ? transferIsARS
      ? 'Revisar factura'
      : 'Descargar cotización'
    : `${currencySymbol} ${transferValue} ${transferCurrencyCode}`;

  const documentWithTotalPrice = transferIsARS ? invoiceUrl || undefined : quoteDocumentUrl;

  const charactersLimit = 26;

  const deleteFile = () => setUploadFile(undefined);
  const documentInputOnChange = (file: Tany) => chargeFile(file, setLoadingFile, setUploadFile, orderId || undefined);

  const getSpeiBankAccountData = async () => {
    if (paymentMethodData === PAYMENT_TYPES?.TRANSFER_MXN) {
      if (!orderId) return;
      try {
        const data = await getSpeiBankData(orderId);
        setSPEIBankData(data);
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSpeiBankAccountData();
  }, []);

  if (loading)
    return (
      <div className="loaderData">
        <GenericLoader sizeLoader={25} borderWidth={3} />
      </div>
    );

  return (
    <div id="nudosTransfDetails">
      <div className="informationField">
        <div className="title">{accountDetailsTitle}</div>
        <div className="accountCointainer" onClick={() => navigator.clipboard.writeText(String(accountNumberOrCLABE))}>
          {accountNumberOrCLABE}
          <CopyIcon className="copyTextIcon" stroke="#383838" />
        </div>
      </div>
      {hasClabeAndAccountNumber && (
        <div className="informationField">
          <div className="title">CLABE</div>
          <div className="accountCointainer" onClick={() => navigator.clipboard.writeText(String(clabe))}>
            {clabe}
            <CopyIcon className="copyTextIcon" stroke="#383838" />
          </div>
        </div>
      )}
      <div className="informationField">
        <div className="title">{beneficiaryOrReferenceTitle}</div>
        <NudosHoverText
          customClassName="subtitle"
          text={beneficiaryValue}
          charactersLimit={charactersLimit}
          onlyIfTruncated
        />
      </div>
      {!!displayRUT && (
        <div className="informationField">
          <div className="title">RUT</div>
          <div className="accountCointainer" onClick={() => navigator.clipboard.writeText(String(displayRUT))}>
            {displayRUT}
            <CopyIcon className="copyTextIcon" stroke="#383838" />
          </div>
        </div>
      )}
      {displayCCI && (
        <div className="informationField">
          <div className="title">CCI</div>
          <div className="accountCointainer" onClick={() => navigator.clipboard.writeText(String(displayCCI))}>
            <NudosHoverText
              customClassName="subtitle"
              text={displayCCI}
              charactersLimit={charactersLimit}
              onlyIfTruncated
            />
            <CopyIcon className="copyTextIcon" stroke="#383838" />
          </div>
        </div>
      )}
      {!!displaySwift && !displayCCI && (
        <div className="informationField">
          <div className="title">Swift</div>
          <NudosHoverText
            customClassName="subtitle"
            text={displaySwift}
            charactersLimit={charactersLimit}
            onlyIfTruncated
          />
        </div>
      )}
      <div className="informationField">
        <div className="title">{t(`${translationKey}bank`)}</div>
        <NudosHoverText customClassName="subtitle" text={bankName} charactersLimit={charactersLimit} onlyIfTruncated />
      </div>
      {displayARSLayout && !!cbu && (
        <div className="informationField">
          <div className="title">CBU</div>
          <NudosHoverText customClassName="subtitle" text={cbu} charactersLimit={charactersLimit} onlyIfTruncated />
        </div>
      )}
      {!!beneficiaryBankValue && (
        <div className="informationField">
          <div className="title">{t(`${translationKey}bankBeneficiary`)}</div>
          <NudosHoverText
            customClassName="subtitle"
            text={beneficiaryBankValue}
            charactersLimit={charactersLimit}
            onlyIfTruncated
          />
        </div>
      )}
      {!!displayAddress && (
        <div className="informationField addressField">
          <div className="title">{t(`${translationKey}addressFieldTitle`)}</div>
          <NudosHoverText
            customClassName="subtitle"
            text={displayAddress}
            charactersLimit={2 * charactersLimit}
            onlyIfTruncated
          />
        </div>
      )}
      <div className="informationField">
        <div className="title">{t(`${translationKey}valueTitle`)}</div>
        {!manualQuote && (
          <NudosHoverText
            customClassName="subtitle"
            charactersLimit={charactersLimit}
            onlyIfTruncated
            text={transferValueText}
          />
        )}
        {manualQuote && (
          <NudosLinkTypeButton
            customClassname="subtitle"
            text={transferValueText}
            handleClickCallback={() => window.open(documentWithTotalPrice, 'blank')}
            hideIcon
          />
        )}
      </div>
      {displayARSLayout && !!cuit && (
        <div className="informationField">
          <div className="title">CUIT</div>
          <NudosHoverText customClassName="subtitle" text={cuit} charactersLimit={charactersLimit} onlyIfTruncated />
        </div>
      )}
      {displayDocumentInput && (
        <div className="uploadDocument">
          <div className="title">{t(`${translationKey}uploadDocumentTitle`)}</div>
          {uploadFile && (
            <div onClick={() => deleteFile()} className="selectFile">
              <div className="redText"> {uploadFile?.name}</div>
              <CloseFile />
            </div>
          )}
          {!uploadFile && (
            <>
              <input className="documentInput" type="file" onChange={e => documentInputOnChange(e)} />
              <div className="noSelectFile">
                {loadingFile && <GenericLoader borderWidth={1} sizeLoader={8} pb={5} />}
                {!loadingFile && (
                  <>
                    <div className="grayText">{t(`${translationKey}receipt`)}</div>
                    <CloseFile stroke="#CCCCCC" />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NudosTransfDetails;

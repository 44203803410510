import { useEffect, useState } from 'react';
import { NudosSelectButton } from '../../../../../../../components/NudosComponents';
import { ICurrency, IInvoicePaymentMethods } from '../../../../../../../types/cart';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../utils/displayNudosStandardNotifications';
import { getOrganizationPaymentMethods } from '../../../../../../../services/checkout';
import { PaymentMethodCard } from '../PaymentMethodCard';
import { NudosStaticAlertInComponent } from '../../../../../../../components/DesignSystem';
import { EditPencilIcon } from '../../../../../../../assets/images/views/Admin/employees';
import { ModalShareInvoice } from '../../../../../../RequestLogistics/LogisticsQuoteAndBillingStep/LogisticServiceBillingData/Components';
import NudosCurrencyDropdown from '../../../../../../../components/DesignSystem/NudosCurrencyDropdown/NudosCurrencyDropdown';
import { Trans, useTranslation } from 'react-i18next';
import { validatePaymentMethods } from '../../../../../../../utils/checkout';
import { IbillingData, SPECIAL_BUSINESS_NAME_TYPES } from '../../../../../../../types/checkout';
import { formatMethodsList, getExtraPaymentMethods } from '../../../../../../../utils/paymentMethods';
import useCheckoutStore from '../../../../state';
import { getOrgData } from '../../../../../../../utils/getLocalStorageData';
import { useLocation } from 'react-router-dom';
import { CardIcon } from '../../../../../../../assets/images/views/Ecommerce';
import './PaymentMethodSection.scss';

/**
 * @param {Object} props - The component props.
 * @param {IbillingData} props.billingData - The billing data selected in the parent component.
 * @param {Function} props.updateBillingData - The function to update the billing data.
 * @param {IInvoicePaymentMethods} props.selectedPaymentMethod - The selected payment method in the parent component.
 * @param {Function} props.setSelectedPaymentMethod - The function to set the selected payment method in the parent component.
 */

const PaymentMethodSection = ({
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  updateBillingData,
  billingData,
  countryCode,
  displaySpecialPaymentCardOption
}: {
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<IInvoicePaymentMethods | undefined>>;
  selectedPaymentMethod?: IInvoicePaymentMethods;
  updateBillingData?: (method: IInvoicePaymentMethods) => void;
  billingData?: IbillingData;
  countryCode?: string;
  displaySpecialPaymentCardOption?: boolean;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { invoiceAdditionalEmail, setInvoiceAdditionalEmail } = useCheckoutStore();

  const [paymentMethodsList, setPaymentMethodsList] = useState<IInvoicePaymentMethods[]>();
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(false);
  const [showShareInvoiceEmailModal, setShowShareInvoiceEmailModal] = useState(false);
  const [currency, setCurrency] = useState<IInvoicePaymentMethods>();

  const isLogisticFLux = pathname.includes('nodi');
  const translationKey = 'nodi:billingDataSection:paymentMethodSection:';
  const billingDataCountryCode = billingData?.documentCountry?.code || '';
  const specialCountriesCodes: string[] = ['co', 'ar', 'mx', 'pe', 'uy'];
  const countryIsPeru = billingDataCountryCode === 'pe';
  const titleMethod = t(`${translationKey}titleMethod`);
  const alertTitle = t(`${translationKey}alertTitle`);
  const extraDataList = getExtraPaymentMethods(billingDataCountryCode, isLogisticFLux);

  const countryCodeIsMx = countryCode === 'mx';
  const currencyIsMXN = currency?.currency?.code === 'MXN';
  const mxExchangeRate = paymentMethodsList?.find(
    paymentMethod => paymentMethod?.currency?.code === 'MXN'
  )?.exchangeRate;

  const specialPaymentCardData = {
    id: 1,
    name: currencyIsMXN ? 'Tarjeta crédito' : 'Tarjeta internacional',
    currency: {
      code: currencyIsMXN ? 'MXN' : 'USD'
    } as ICurrency,
    exchangeRate: countryCodeIsMx ? mxExchangeRate : '1.0',
    transactionFee: currencyIsMXN ? '3' : '3.6', //Comisiones que devuelve backend dependiendo si es MXN o USD
    extraData: {
      icon: <CardIcon />,
      extraBody: 'Al finalizar el servicio recibirás el link/ liga de pago a tu correo y en el detalle del servicio.',
      showCheckInvoice: false,
      needInvoice: false,
      alias: 'Tarjeta de crédito o transferencia'
    }
  };

  const handleCheckBoxClick = () => {
    if (!invoiceAdditionalEmail) setShowShareInvoiceEmailModal(true);
    if (invoiceAdditionalEmail) setInvoiceAdditionalEmail('');
  };

  const handleChangeMethod = (method: IInvoicePaymentMethods | undefined) => {
    setSelectedPaymentMethod(method);
    if (
      method?.name.includes('Tarjeta') &&
      method.transactionFee &&
      specialCountriesCodes?.includes(billingDataCountryCode)
    ) {
      displaySuccessNotification({
        customJSXMessage: (
          <>
            <Trans
              i18nKey={t(`${translationKey}changeMethodNotificationText`)}
              components={{ 1: method?.transactionFee }}
            />
          </>
        )
      });
    }
    if (updateBillingData && method) updateBillingData(method);
  };

  const changeNeedInvoice = (method: IInvoicePaymentMethods) => {
    if (method && method?.extraData) {
      const editExtraData = { ...method?.extraData };
      editExtraData.needInvoice = !method?.extraData?.needInvoice;
      setSelectedPaymentMethod({ ...method, extraData: editExtraData });
      !method?.extraData?.needInvoice &&
        displaySuccessNotification({
          customJSXMessage: <>{t(`${translationKey}changeNeedInvoiceNotification`)}</>
        });
    }
  };

  const getPaymentMethodsList = async (countryCode: string) => {
    const { organizationId } = getOrgData();
    if (!organizationId || !countryCode) return;
    try {
      setPaymentMethodsLoading(true);
      const dataList = await getOrganizationPaymentMethods({ organizationId, countryCode });
      if (dataList) formatMethodsList(dataList, extraDataList, setPaymentMethodsList);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setPaymentMethodsLoading(false);
    }
  };

  const filterUniqueCurrencies = (currencyList?: IInvoicePaymentMethods[]) => {
    if (!currencyList) return;
    const uniqueCurrencies = new Set();
    const currencies: IInvoicePaymentMethods[] = [];
    if (countryIsPeru) currencies.push(currencyList[0]);
    if (!countryIsPeru) {
      currencyList.forEach(currencyList => {
        if (!uniqueCurrencies.has(currencyList?.currency?.currencyId)) {
          uniqueCurrencies.add(currencyList?.currency?.currencyId);
          currencies.push(currencyList);
        }
      });
    }

    return currencies;
  };

  const filteredPaymentMethodsList = filterUniqueCurrencies(paymentMethodsList);

  const setBillingDataWhenJustOneExist = () => {
    if (!!filteredPaymentMethodsList && filteredPaymentMethodsList.length === 1)
      setCurrency(filteredPaymentMethodsList[0]);
  };

  const validateAndGetPaymentMethodsList = () => {
    if (!billingData || !billingData?.documentCountry?.id || !countryCode) return;
    const codeToCheckPaymentMethods = validatePaymentMethods({
      countryCode: countryCode || '',
      billinDataCountryCode: billingData.countryCode || '',
      documentCountryCode: billingData?.documentCountry?.code,
      specialBusinessNameTypeId: billingData?.specialBusinessNameTypeId as SPECIAL_BUSINESS_NAME_TYPES
    });
    getPaymentMethodsList(codeToCheckPaymentMethods);
  };

  useEffect(() => {
    setBillingDataWhenJustOneExist();
  }, [paymentMethodsList]);

  useEffect(() => {
    validateAndGetPaymentMethodsList();
  }, [billingData, countryCode]);

  useEffect(() => {
    return () => {
      setSelectedPaymentMethod(undefined);
      setInvoiceAdditionalEmail('');
    };
  }, []);

  return (
    <div className="paymentMethodSection">
      <div className="paymentMethodSectionContainer">
        <div className="titleMethod">{titleMethod}</div>
        <NudosStaticAlertInComponent
          type="information"
          color="gray"
          customClassname="explanationTextContainer"
          alertOptions={{
            title: alertTitle
          }}
          keepTypeIconButChangeColor
        />
        <NudosCurrencyDropdown
          currencyList={filteredPaymentMethodsList || []}
          currentValue={currency}
          charactersLimit={20}
          componentSize="medium"
          customPlaceholder={selectedPaymentMethod?.name || t(`${translationKey}currencyDropdownPlaceholder`)}
          showSkeletonLoader={paymentMethodsLoading}
          updateCurrentValueCallback={e => {
            setCurrency(e);
            setSelectedPaymentMethod(undefined);
          }}
        />
        <div className="paymentMethodContainer">
          {!!paymentMethodsList && !paymentMethodsLoading && !!currency && (
            <div className="containerMethodCards">
              {!displaySpecialPaymentCardOption &&
                !!paymentMethodsList &&
                paymentMethodsList.length > 0 &&
                paymentMethodsList.map(item => {
                  if (item?.currency?.code === currency?.currency?.code) {
                    return (
                      <PaymentMethodCard
                        key={`select-payment-method-${item.id}-country-${item?.currency?.code}`}
                        detailCard={item}
                        paymenthMethod={item.id === selectedPaymentMethod?.id ? selectedPaymentMethod : undefined}
                        handleChangeMethod={method => handleChangeMethod(method)}
                        changeNeedInvoice={method => changeNeedInvoice(method)}
                      />
                    );
                  } else return null;
                })}
              {displaySpecialPaymentCardOption && (
                <PaymentMethodCard
                  key={`select-payment-method-${specialPaymentCardData?.id}-country-${specialPaymentCardData?.currency?.code}`}
                  detailCard={specialPaymentCardData}
                  paymenthMethod={selectedPaymentMethod}
                  handleChangeMethod={method => handleChangeMethod(method)}
                  changeNeedInvoice={method => changeNeedInvoice(method)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="shareInvoiceSectionContainer">
        <div className="shareInvoiceButton">
          <NudosSelectButton
            isButtonActive={!!invoiceAdditionalEmail}
            handleClickButton={() => handleCheckBoxClick()}
          />
          {t(`${translationKey}shareInvoiceButton`)}
        </div>
        {invoiceAdditionalEmail && (
          <div className="shareInvoiceEmail">
            {invoiceAdditionalEmail}
            <EditPencilIcon className="editPencilIcon" onClick={() => setShowShareInvoiceEmailModal(true)} />
          </div>
        )}
      </div>
      <div className="h-10 w-10" />
      {showShareInvoiceEmailModal && (
        <ModalShareInvoice
          emailShareInvoice={invoiceAdditionalEmail}
          closeModalCallback={() => setShowShareInvoiceEmailModal(false)}
          setEmailShareInvoice={setInvoiceAdditionalEmail}
        />
      )}
    </div>
  );
};

export default PaymentMethodSection;

import { updateProofOfPayment } from '../services/orders';
import { Tany } from '../types/global';
import { displayErrorNotification, displaySuccessNotification } from './displayNudosStandardNotifications';
import i18next from 'i18next';

const isFileValid = (
  file: File,
  validFormats: string[],
  sizeLimitInMegabytes: number,
  formatErrorMessage: string,
  sizeErrorMessage: string
) => {
  const isFormatCorrect = validFormats.includes(file.type);
  const isSizeValid = file.size / 1024 / 1024 < sizeLimitInMegabytes;
  if (!isFormatCorrect) {
    displayErrorNotification({ customJSXMessage: <>{formatErrorMessage}</> });
    return false;
  }
  if (!isSizeValid) {
    displayErrorNotification({ customJSXMessage: <>{sizeErrorMessage}</> });
    return false;
  }
  return true;
};

export default isFileValid;

export const validFormatsForDocumentsIncludingImages = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/webp',
  'image/gif',
  'image/tiff',
  'image/svg+xml'
];

export const formatErrorTextForImageDocuments =
  'El documento debe estar en formato PDF o alguno de estos formatos de imagen: png, jpeg, webp, gif, tiff, svg';

export const chargeFile = async (
  file: Tany,
  setLoadingFile: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadFile: React.Dispatch<React.SetStateAction<Tany>>,
  orderId?: number | string
) => {
  const chargeFileNotification = i18next?.t(`recurrentWords:chargeFile:chargeFileNotification`);
  const chargeFileErrorFormat = i18next?.t(`recurrentWords:chargeFile:chargeFileErrorFormat`);
  const chargeFileErrorSize = i18next?.t(`recurrentWords:chargeFile:chargeFileErrorSize`);
  const uploadedFile = file.target.files[0];
  const fileValid = isFileValid(uploadedFile, ['application/pdf'], 2, chargeFileErrorFormat, chargeFileErrorSize);
  if (!fileValid) return;
  if (!orderId) return;
  setLoadingFile(true);
  const formData = new FormData();
  formData.append('file', uploadedFile);
  try {
    await updateProofOfPayment(orderId, formData);
    setUploadFile(uploadedFile);
    setLoadingFile(false);
    displaySuccessNotification({
      customJSXMessage: <>{chargeFileNotification}</>
    });
  } catch (error) {
    setLoadingFile(false);
    displayErrorNotification();
  }
};

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../types/countries';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { getCountryById } from '../../../services/countries.service';
import { NudosButton, NudosHoverText } from '../../NudosComponents';
import { IbillingData } from '../../../types/checkout';
import BordUpdateBillingDataModal from '../BordUpdateBillingDataModal/BordUpdateBillingDataModal';
import './BordBillingDataSection.scss';

const BordBillingDataSection = ({
  billingData,
  updateDataCallaback,
  disableEditingOfBillingData,
  billingCountryId = 3, // México in production
  loading,
  orderOrLogisticId
}: {
  billingData: IbillingData;
  updateDataCallaback: () => void;
  disableEditingOfBillingData?: boolean;
  billingCountryId?: number;
  loading?: boolean;
  orderOrLogisticId?: string | number;
}) => {
  const { t } = useTranslation();

  const [showUpdateOrderBillingDataModal, setShowUpdateOrderBillingDataModal] = useState(false);
  const [billingCountryData, setBillingCountryData] = useState<ICountryDetail>();

  const { businessName, state, document, city, address, additionalReferences, zipCode } = billingData;
  const i18nLanguageKey = 'nodi:orderDetails:orderBillingData:';
  const noDataText = 'Sin datos';

  const handleOpenUpdateBillingDataModal = () => setShowUpdateOrderBillingDataModal(true);

  const getCountryBillingData = async () => {
    try {
      const newCountryBillingData = await getCountryById(billingCountryId);
      setBillingCountryData(newCountryBillingData);
    } catch (error) {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    billingCountryId && getCountryBillingData();
  }, [billingCountryId]);

  if (loading)
    return (
      <div className="orderBillingDataSkeletonLoader bordBillingDataSection">
        <div className="sectionTitle animationLoader" />
        <div className="sectionBody animationLoader" />
        <div className="whiteSpaceAtTheBottom w-10 h-10" />
      </div>
    );

  return (
    <div className="bordBillingDataSection">
      {showUpdateOrderBillingDataModal && orderOrLogisticId && (
        <BordUpdateBillingDataModal
          initialData={billingData}
          billingCountryData={billingCountryData}
          closeModalCallback={setShowUpdateOrderBillingDataModal}
          updateBillingData={updateDataCallaback}
          orderOrLogisticId={orderOrLogisticId}
        />
      )}
      <h2 className="sectionTitle">{t(`${i18nLanguageKey}sectionTitle`)}</h2>
      <div className="sectionBody">
        <div className="billingDataLine line1">
          <div className="billingDataField businessName">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}businessName`)}</span>
            <NudosHoverText onlyIfTruncated text={businessName || noDataText} charactersLimit={25} />
          </div>
          <div className="billingDataField taxDocument relative">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}businessId`)}</span>
            <NudosHoverText onlyIfTruncated text={document || noDataText} charactersLimit={12} />
            {billingData?.documentCountry?.flag && (
              <div className="floatFlag">
                <div className="containerFloatFlag">
                  <div
                    className="bg-cover bg-center w-full h-full bg-no-repeat rounded-sm"
                    style={{
                      backgroundImage: `url(${billingData?.documentCountry?.flag})`
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="billingDataLine line2">
          <div className="billingDataField regionOrState">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}regionOrState`)}</span>
            <NudosHoverText onlyIfTruncated text={state || noDataText} charactersLimit={32} />
          </div>
          <div className="billingDataField city">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}city`)}</span>
            <NudosHoverText onlyIfTruncated text={city || noDataText} charactersLimit={32} />
          </div>
        </div>
        <div className="billingDataLine line3">
          <div className="billingDataField zipCode">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}zipCode`)}</span>
            <NudosHoverText onlyIfTruncated text={zipCode || noDataText} charactersLimit={25} />
          </div>
          <div className="billingDataField addressAdditionalReferences">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}addressAdditionalReferences`)}</span>
            <NudosHoverText onlyIfTruncated text={additionalReferences || noDataText} charactersLimit={25} />
          </div>
        </div>
        <div className="billingDataLine line4">
          <div className="billingDataField address">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}address1`)}</span>
            <NudosHoverText onlyIfTruncated text={billingData?.email || noDataText} charactersLimit={55} />
          </div>
        </div>
        <div className="billingDataLine line4">
          <div className="billingDataField address">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}address2`)}</span>
            <NudosHoverText onlyIfTruncated text={address || noDataText} charactersLimit={55} />
          </div>
        </div>
        <NudosButton
          customClassName="editBillingDataButton"
          buttonText={t(`${i18nLanguageKey}nudosButton`)}
          customWidth="80px"
          customHeight="18px"
          handleClick={handleOpenUpdateBillingDataModal}
          isButtonDisabled={disableEditingOfBillingData}
        />
      </div>
    </div>
  );
};

export default BordBillingDataSection;

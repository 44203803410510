import { FC, useCallback, useEffect, useRef, useState } from 'react';
import IconGreenThunder from '../../../assets/DesignSystem/SVGComponents/IconGreenThunder';
import { redIcon } from '../../../assets/images/components/countryPrices';
import { nudosFormatCurrency } from '../../../utils/formatNumbers';
import { Tany } from '../../../types/global';
import { sendSubscriptionSlackMessage } from '../../../services/subscriptions.services';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { getOrgData, getPersonalData } from '../../../utils/getLocalStorageData';
import { GenericLoader } from '../../../views/Admin/dashboard/components';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../utils/segment';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import './SmallSavingBanner.scss';

interface ISmallSavingBanner {
  discountedTotal: number;
  shippingDiscount: number;
  step: number;
}

const SmallSavingBanner: FC<ISmallSavingBanner> = ({
  discountedTotal = 0,
  shippingDiscount = 0,
  step
}: ISmallSavingBanner) => {
  const { t } = useTranslation();
  const toolTipReference = useRef<HTMLDivElement>(null);
  const organizationData = getOrgData();
  const personalData = getPersonalData();
  const { stateSubscription } = useStateSubscriptions();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { isPrime } = stateSubscription;
  const languageKey = 'nodi:checkout:countryPrices:';

  const askForInformation = async () => {
    setLoading(true);
    const notificationMessage = t(`nodi:primeBanner:notificationMessage`);
    const body = {
      organizationName: organizationData?.organizationName || '',
      requesterName: `${personalData?.firstName} ${personalData?.lastName}`,
      service: 'Nodi Prime',
      origin: 'desde órdenes',
      userRole: personalData?.role
    };
    try {
      await sendSubscriptionSlackMessage(body);
      displaySuccessNotification({
        customJSXMessage: <>{notificationMessage}</>,
        button1Text: t(`recurrentWords:understoodButton`)
      });
      setShowTooltip(false);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const handleClickAway = useCallback(
    (e: Tany) => {
      if (toolTipReference.current && !toolTipReference.current.contains(e.target)) {
        setShowTooltip(false);
      }
    },
    [toolTipReference]
  );

  useEffect(() => {
    window.addEventListener('mouseout', handleClickAway);

    () => window.removeEventListener('mouseout', handleClickAway);
  }, [handleClickAway]);

  return (
    <div
      id="smallSavingBanner"
      ref={toolTipReference}
      onMouseOverCapture={() => {
        setShowTooltip(true);
      }}
      onMouseMoveCapture={() => {
        setShowTooltip(true);
      }}
      onMouseEnter={() => {
        segmentTrackEvent({
          [!isPrime ? 'freeToolTipDiscountTotalHover' : 'primeDiscountTotalHover']: {
            ScreenName: step === 1 ? 'Kart' : step === 2 ? 'Shipping' : 'Billing&Payment'
          }
        });
      }}
    >
      {showTooltip && (
        <div className="tooltipSavingBanner">
          <div className="toltilepSB">{`${t(`recurrentWords:pay`)} ${t(`recurrentWords:less`)} ${t(
            `recurrentWords:with`
          )} Nodi Prime`}</div>
          <div className="tooltipGrayBody">
            {`${t(`${languageKey}shippingPayments`)} = ${
              shippingDiscount
                ? `${nudosFormatCurrency({ value: shippingDiscount, maximumFractionDigits: 2 })} USD`
                : t('recurrentWords:toBeCalculated')
            }`}
          </div>
          <div className="tooltipGrayBody mb-1">
            {`${t(`${languageKey}purchaseDiscount`)} = ${nudosFormatCurrency({
              value: discountedTotal - shippingDiscount
            })} USD`}
          </div>
          <div className="tooltipEndContainer">
            <div className="labelTooltip">{t(`${languageKey}labelTooltip`)}</div>
            <div className="amountTooltip">{`${nudosFormatCurrency({ value: discountedTotal })} USD`} </div>
          </div>
          <div
            className="informationLabel"
            onClick={() => {
              if (!loading) askForInformation();
            }}
          >
            {loading ? <GenericLoader sizeLoader={12} borderWidth={2} /> : <> {t(`${languageKey}informationLabel`)}</>}
          </div>
        </div>
      )}
      <div className="boxDiscountMinBanner">
        <div className="iconPercent">
          <img alt="redIcon" className="redIconSize" src={redIcon} />
        </div>
        <div className="discountTextPercent">
          {t(`recurrentWords:pay`)}
          <strong className="text-sm">
            {discountedTotal
              ? ` ${nudosFormatCurrency({ value: discountedTotal, maximumFractionDigits: 2 })} USD `
              : ' '}
            {t('recurrentWords:less')}
          </strong>
          {` ${t(`nodi:dynamicBanner:with`)} Nodi Prime`}
        </div>
        <div className="discountThunderIcon">
          <IconGreenThunder fill="#7B61FF" />
        </div>
      </div>
    </div>
  );
};

export default SmallSavingBanner;

import { FC, useEffect, useState } from 'react';
import { NudosButton } from '../../../../../components/NudosComponents';
import useCartState from '../../../../../state/useCartState';
import { useHistory } from 'react-router-dom';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { requestOrderSummaryPDF } from '../../../../../services/checkout';
import { DownloadIcon } from '../../../../../assets/images/components/NudosComponents';
import { PAYMENT_TYPES } from '../../../../../types/checkout';
import { NudosTransfDetails } from '../../../../../components/DesignSystem';
import { segmentTrackEvent } from '../../../../../utils/segment';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { Trans, useTranslation } from 'react-i18next';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import './Step4FinishCart.scss';

interface IStep4FinishCart {
  deliveryDayRange?: string;
}

const Step4FinishCart: FC<IStep4FinishCart> = ({ deliveryDayRange }: IStep4FinishCart) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { dataFinishCart, setDataFinishCart } = useCartState();
  const { stateSubscription } = useStateSubscriptions();
  const { storeCountry } = useStoreCountryState();

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [transfMX, setTransfMX] = useState<boolean>();
  const [transfCOP, setTransfCOP] = useState<boolean>();
  const [transfUSD, setTransfUSD] = useState<boolean>();
  const [creditCardCOP, setCreditCardCOP] = useState<boolean>();
  const [nationalCreditCard, setNationalCreditCard] = useState<boolean>();
  const [internationalCreditCard, setInternationalCreditCard] = useState<boolean>();
  const [loadingOrderSummary, setLoadingOrderSummary] = useState(false);
  const [orderSummaryPDFUrl, setOrderSummaryPDFUrl] = useState('');

  const { isPrime } = stateSubscription;
  const { currencyCode, orderId, shippings, summary, paymentType } = dataFinishCart || {};
  const translationKey = 'nodi:billingDataSection:step4FinishCart:';
  const containerTitle = t(`${translationKey}containerTitle`);
  const graySubtitle = t(`${translationKey}graySubtitle`);
  const nudosButtonText = t(`${translationKey}nudosButtonText`);
  const orangeButtonText = t(`${translationKey}orangeButtonText`);

  const paymentMethodIsStripe = currencyCode === 'MXN' && paymentType === PAYMENT_TYPES?.CARD_MXN;
  const transfPEN = paymentMethod === PAYMENT_TYPES?.TRANSFER_PEN;
  const transfARS = paymentMethod === PAYMENT_TYPES?.TRANSFER_ARS;
  const displayQuotationButton = !paymentMethodIsStripe && !transfCOP && !transfPEN;
  const displayTransfDetails = transfMX || transfCOP || transfPEN || transfARS || transfUSD;

  const orderText = t(`${translationKey}containerOrderId`);
  const withWord = t(`nodi:dynamicBanner:with`);
  const shipmentsWord = t(`recurrentWords:shipments`);

  const destinationCountries = summary?.shippingsList?.map(item => item?.countryName);
  const orderTextId = `${orderText} ${orderId} ${withWord} ${shippings} ${shipmentsWord}`;
  const additionalStyles =
    nationalCreditCard || creditCardCOP ? 'extraPadding' : internationalCreditCard ? 'internationalExtraPadding' : '';

  const resetStates = () => localStorage.setItem('savedStep', '0');

  const initialPaymentMethod = () => {
    if (paymentType) {
      setPaymentMethod(paymentType);
      resetStates();
    }
    if (!dataFinishCart) {
      resetStates();
      push('/catalogue/home');
    }
  };

  const downloadOrderSummary = async () => {
    if (!orderId) return;
    setLoadingOrderSummary(true);
    try {
      const { url } = await requestOrderSummaryPDF(orderId);
      if (url) {
        setOrderSummaryPDFUrl(url);
        window.open(url, 'blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOrderSummary(false);
    }
  };

  const handleClickRequestQuotation = () => {
    if (orderSummaryPDFUrl) window.open(orderSummaryPDFUrl, 'blank');
    else downloadOrderSummary();
  };

  const setPaymentmethod = () => {
    if (paymentMethod === PAYMENT_TYPES?.TRANSFER_MXN) setTransfMX(true);
    if (paymentMethod === PAYMENT_TYPES?.TRANSFER_USD) setTransfUSD(true);
    if (paymentMethod === PAYMENT_TYPES?.CARD_MXN) setNationalCreditCard(true);
    if (paymentMethod === PAYMENT_TYPES?.INTERNATIONAL_CARD) setInternationalCreditCard(true);
    if (paymentMethod === PAYMENT_TYPES?.CARD_COP) setCreditCardCOP(true);
    if (paymentMethod === PAYMENT_TYPES?.TRANSFER_COP) setTransfCOP(true);
  };

  useEffect(() => {
    setPaymentmethod();
  }, [paymentMethod]);

  useEffect(() => {
    initialPaymentMethod();
  }, [dataFinishCart]);

  useEffect(() => {
    return () => setDataFinishCart(undefined);
  }, []);

  useEffect(() => {
    segmentTrackEvent({
      [!isPrime ? 'free4CheckoutResumeView' : 'prime4CheckoutResumeView']: {
        PaymentMethods: paymentType,
        DestinationCountry: destinationCountries
      }
    });
  }, []);

  return (
    <div id="step4FinishCart">
      <div className="boxFinishCart">
        <div className={`containerInfo ${additionalStyles}`}>
          <div className="containerTitle">{containerTitle}</div>
          <div className="containerOrderId" style={{ marginBottom: internationalCreditCard ? '32px' : '' }}>
            {orderTextId}
          </div>
          {nationalCreditCard && (
            <div className="confirmationSubBlack">
              <Trans i18nKey={t(`${translationKey}confirmationSubBlack`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(internationalCreditCard || creditCardCOP) && (
            <div className="confirmationSub font-medium">
              <Trans i18nKey={t(`${translationKey}confirmationSub`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(transfMX || transfCOP) && (
            <div className="containerInfoAccount">
              <Trans i18nKey={t(`${translationKey}containerInfoAccountMx`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(transfUSD || transfARS) && (
            <>
              <div className="containerInfoAccount">
                <Trans
                  i18nKey={t(`${translationKey}containerInfoAccountArs`)}
                  components={{ 1: <span className="text-nudos-orange font-bold"></span>, 2: <br /> }}
                />
              </div>
            </>
          )}
          {displayTransfDetails && (
            <div className="boxAccount">
              <NudosTransfDetails specialData={dataFinishCart} />
            </div>
          )}
          <div className="orangeSubtitle">
            Realiza el pago para confirmar tu orden. En caso de tener <br /> acuerdo de pago, se confirma
            automáticamente
          </div>
          <div className="dateContainer">
            <div className="blackTitle">Entrega estimada: </div>
            <div className="flagAndDaysContianer">
              {storeCountry && (
                <div className="flagContainer">
                  <div className="flagBg" style={{ backgroundImage: `url("${storeCountry?.countryFlag}")` }} />
                </div>
              )}
              {`${storeCountry ? storeCountry?.countryName : '📆'}: ${deliveryDayRange} días hábiles`}{' '}
            </div>
          </div>
          <div className="graySubtitle">{graySubtitle}</div>
          {displayQuotationButton && (
            <NudosButton
              colorPalette="blue"
              customHeight="28px"
              customWidth="166px"
              buttonText={nudosButtonText}
              buttonIcon={<DownloadIcon className="downloadIcon" />}
              customClassName="requestOrderSummaryButton"
              handleClick={handleClickRequestQuotation}
              isButtonLoading={loadingOrderSummary}
              isButtonDisabled={loadingOrderSummary}
            />
          )}
          <div className="containerButtons">
            <div className="orangeButton">
              <NudosButton
                handleClick={() => {
                  if (orderId) push(`/nodi/orders/details/${orderId}`);
                }}
                buttonText={orangeButtonText}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4FinishCart;

import { IconLocation } from '../../assets/DesignSystem/SVGComponents';
import { World } from '../../assets/DesignSystem/images';
import XSign from '../../assets/images/components/NudosComponents/XSign';
import { FlowToChangeCountryStore } from '../FlowToChangeCountryStore';
import useStoreCountryState from '../../state/useStoreCountryState';
import { useTranslation } from 'react-i18next';
import { getCountryName } from '../../utils/getCountries';
import './CountryButton.scss';

interface ICountryButton {
  handleChangeStatusModal: (status: boolean) => void;
  openModal: boolean;
}

const CountryButton = ({ openModal, handleChangeStatusModal }: ICountryButton) => {
  const { t } = useTranslation();
  const translations = 'ecommerce:countryButton:';
  const countriesTranslations = 'services:countries:';
  const { storeCountry, setInitialCountryTooltip, initialCountryTooltip } = useStoreCountryState();

  const checkNameSize = storeCountry?.countryName?.length || 0;
  const makeLineBreak = checkNameSize >= 9;

  const closeModal = () => {
    handleChangeStatusModal(false);
  };

  return (
    <>
      {openModal && <FlowToChangeCountryStore closeModals={closeModal} />}{' '}
      <div id="countryButton">
        {initialCountryTooltip && (
          <div className="countryButtonTooltip">
            <XSign
              className="closeIconX"
              fill="#999999"
              onClick={() => {
                setInitialCountryTooltip(false);
              }}
            />
            <div className="imgWorld">
              <div className="limitsWorld">
                <img src={World} />
              </div>
            </div>
            <div className="textTooltip">
              {`${t(`${translations}countryProducts`)} ${t(`${countriesTranslations}${storeCountry?.nameStringId}`)}`}
              <div className="minFlagContainer">
                <div className="generalFlagBox">
                  <div
                    className="boxFlagContainer"
                    style={{ backgroundImage: `url("${storeCountry?.countryFlag}")` }}
                  />
                </div>
              </div>
              {t(`${translations}changeHere`)}
            </div>
          </div>
        )}
        <div
          className="flagContainer"
          onClick={() => {
            handleChangeStatusModal(true);
          }}
        >
          {storeCountry?.countryFlag ? (
            <div
              className="boxCountryFlag flagBackground"
              style={{
                backgroundImage: `url("${storeCountry?.countryFlag}")`
              }}
            ></div>
          ) : (
            <div className="boxCountryFlag">
              <IconLocation stroke="#383838" transform="scale(1.2)" />
            </div>
          )}
        </div>
        <div
          className="countryNameContainer"
          onClick={() => {
            handleChangeStatusModal(true);
          }}
        >
          {storeCountry?.countryName ? (
            <div className={`countryStore ${!makeLineBreak ? 'inlineElements' : ''}`}>
              <div className={`blackText ${makeLineBreak ? 'whitespace-nowrap' : ''}`}>
                {t(`${translations}title`)}&nbsp;
              </div>
              <div className={`blueText ${makeLineBreak ? 'whitespace-nowrap maxCountryName' : ''}`}>
                {getCountryName({
                  code: storeCountry.countryCode,
                  flag: storeCountry.countryFlag,
                  id: storeCountry.id,
                  name: storeCountry.countryName,
                  nameStringId: storeCountry.nameStringId
                })}
              </div>
            </div>
          ) : (
            <div className="noSelectCountry">{t(`${translations}countryNotSelected`)}</div>
          )}{' '}
        </div>
      </div>
    </>
  );
};

export default CountryButton;

import { useEffect, useState } from 'react';
import { IbillingData, SPECIAL_BUSINESS_NAME_TYPES } from '../../../../../../types/checkout';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import useLogisticsServiceBillingState from '../../../../../../state/useLogisticsQuoteAndBilling';
import { GenericLoader } from '../../../../../Admin/dashboard/components';
import { NudosRadioButton } from '../../../../../../components/NudosComponents';
import { IDetermineSLBillingMethod } from '../../../../../../types/DTO';
import { postDetermineSLBillingMethod } from '../../../../../../services/services';
import {
  IlocationAddressForLogistics,
  TlogisticsOriginOrDestinationData
} from '../../../../../../types/requestLogisticsModule';
import { internationalCode } from '../../../../../../utils/productDefinitions';
import './SelectOtherBillingCountryForLogisticService.scss';

interface InvoiceCountryOption {
  id: number;
  description: string;
  countryCode?: string;
}

/**
 * @param {IbillingData} props.billingData - The billing data selected in the parent component.
 */

const SelectOtherBillingCountryForLogisticService = ({
  billingData = {},
  detinationData,
  originData
}: {
  billingData?: IbillingData;
  detinationData?: TlogisticsOriginOrDestinationData;
  originData?: TlogisticsOriginOrDestinationData;
}) => {
  const { setBillingCountryCode } = useLogisticsServiceBillingState();

  const [chosenInvoiceCountry, setChosenInvoiceCountry] = useState<InvoiceCountryOption>();
  const [typeOfBillingMethod, setTypeOfBillingMethod] = useState<IDetermineSLBillingMethod>();
  const [typeOfBillingMethodLoader, setTypeOfBillingMethodLoader] = useState<boolean>(true);

  const destinationCountry = (detinationData as IlocationAddressForLogistics)?.countryData || detinationData?.country;

  const postTypeOfBillingMethod = async () => {
    try {
      const body = {
        billingEntityCountryCode: billingData?.countryCode || '',
        destinationCountryCode: destinationCountry?.code,
        originCountryCode: originData?.country?.code,
        specialBusinessNameTypeId: billingData?.specialBusinessNameTypeId || undefined
      };
      const data = await postDetermineSLBillingMethod(body);
      setTypeOfBillingMethod(data);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setTypeOfBillingMethodLoader(false);
    }
  };

  const isLocalLS =
    typeOfBillingMethod &&
    typeOfBillingMethod?.billingMethodName?.includes('local') &&
    billingData?.specialBusinessNameTypeId !== SPECIAL_BUSINESS_NAME_TYPES?.TRADING &&
    billingData?.countryCode !== 'us';

  const { documentCountry } = billingData;
  const invoiceCountryOptionsData: InvoiceCountryOption[] = [
    { id: 1, description: 'Factura emitida desde Estados Unidos', countryCode: internationalCode },
    {
      id: 2,
      description: `Factura emitida desde ${documentCountry?.name}`,
      countryCode: documentCountry?.code
    }
  ];

  const seInitialInvoiceOptionSelected = () => {
    if (typeOfBillingMethodLoader || !isLocalLS) return;
    setChosenInvoiceCountry(invoiceCountryOptionsData[1]);
    setBillingCountryCode({
      billingMethod: typeOfBillingMethod,
      billingCountryCode: invoiceCountryOptionsData[1]?.countryCode
    });
  };

  const getInvoiceCountryOptionsData = (id?: number) => {
    const currentOptionIsSelected = chosenInvoiceCountry?.id && chosenInvoiceCountry?.id === id;
    return {
      optionSelected: !!currentOptionIsSelected,
      disabledStyles: !currentOptionIsSelected ? 'disabled' : ''
    };
  };

  useEffect(() => {
    seInitialInvoiceOptionSelected();
  }, [typeOfBillingMethodLoader]);

  useEffect(() => {
    if (!isLocalLS && !typeOfBillingMethodLoader)
      setBillingCountryCode({
        billingMethod: typeOfBillingMethod,
        billingCountryCode: internationalCode
      });
  }, [isLocalLS, typeOfBillingMethodLoader]);

  useEffect(() => {
    postTypeOfBillingMethod();
    return () => setBillingCountryCode(undefined);
  }, []);

  if (typeOfBillingMethodLoader)
    return (
      <div className="selectOtherBillingCountryLoader">
        <GenericLoader sizeLoader={30} borderWidth={4} />
      </div>
    );

  if (!isLocalLS && !typeOfBillingMethodLoader) return <></>;

  return (
    <div className="selectOtherBillingCountryForLogisticService">
      {invoiceCountryOptionsData?.map(invoiceCountry => {
        const { id, description } = invoiceCountry;
        const { disabledStyles, optionSelected } = getInvoiceCountryOptionsData(id);
        const invoiceOptionsContainerOnClick = () => {
          if (chosenInvoiceCountry?.id === id) return;
          setChosenInvoiceCountry(invoiceCountry);
          setBillingCountryCode({
            billingMethod: typeOfBillingMethod,
            billingCountryCode: invoiceCountry?.countryCode
          });
        };
        return (
          <div key={id} className={`invoiceOptionsContainer ${disabledStyles}`}>
            <NudosRadioButton
              componentSize="small"
              isButtonActive={optionSelected}
              handleClickButton={invoiceOptionsContainerOnClick}
            />
            {description}
          </div>
        );
      })}
    </div>
  );
};

export default SelectOtherBillingCountryForLogisticService;

import { useEffect, useRef, useState } from 'react';
import { LocationIcon } from '../../../assets/images/components/NudosComponents';
import useDebounce from '../../../hooks/useDebounce';
import { IaddressFromGoogleMaps } from '../../../types/global';
import NudosGenericSearchBar from '../NudosGenericSearchBar';
import MoreInfoIcon from '../../../assets/images/components/MoreInfoIcon';
import { useTranslation, Trans } from 'react-i18next';
import './NudosAddressSearchBar.scss';

/**
 * A react functional component to search addressess using google maps
 * IMPORTANT: if a text and tooltip with instructions on how to proceed when the desired address is not found is to be shown, it is necessary to provide a value either the didntFindAddressTooltipTopPosition or didntFindAddressTooltipRightPosition, or both
 * @property {string} label - Optional
 * @property {google.maps.MapOptions} mapOptions - Optional, an object with a set of options to the map that uses the places service, can be used to bias the search
 * @property {string} errorText - Optional, the text to be shown if theres an error with the input form. If an error is passed the style of the component will change to reflect that.
 * @property {boolean} isDisabled - Optional, if a value is provided the input would not allowed new searches. Styles would reflect its status as disabled.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {function(selectedAddress: IaddressFromGoogleMaps): void} handleAddressSelection - Optional, a callback to execute upon changing the selected address
 * @property {string} defaultValueAddressName - Optional, in cases where there is a default address stored. A string with the name to be displayed instead of a placeholder.
 * @property {string} customPlaceholder - Optional.
 * @property {string} activeStylesIfCustomPlaceholder - Optional - a boolean indicating if the UI of the search bar should look active when a customPlaceHolder is passed.
 * @property {string} regionBias - Optional - The ISO 3166-1 alpha-2 two letter code for the country to provided as the region bias for the searches. If no value is passed "mx" is the default.
 * @property {string} hideDidntFindAddressToolTip - Optional - A boolean indicating if the text and tooltip with instructions on how to proceed when the desired address is not found should NOT be shown
 * @property {number} didntFindAddressTooltipTopPosition - Optional - The top position in px for the absolute text and tooltip with instructions on how to proceed when the desired address is not found. Default is 36px
 * @property {number} didntFindAddressTooltipRightPosition - Optional - The right position in px for the absolute text and tooltip with instructions on how to proceed when the desired address is not found. Default is -132px
 * @property { {[key: string]: string} } componentCustomStyles - Optional, an object with custom styles for the component
 * @returns
 */

const NudosAddressSearchBar = ({
  label,
  mapOptions,
  errorText,
  isDisabled,
  componentSize,
  handleAddressSelection,
  defaultValueAddressName,
  customPlaceholder,
  activeStylesIfCustomPlaceholder,
  regionBias,
  hideDidntFindAddressToolTip,
  didntFindAddressTooltipTopPosition,
  didntFindAddressTooltipRightPosition,
  styleLabel,
  componentCustomStyles,
  isLoading,
  showSkeletonLoader
}: InudosAddressSearchBar) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map>();
  const [currentMapPlacesService, setCurrentMapPlacesService] = useState<google.maps.places.PlacesService>();
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();
  const [mapAutocomplete, setMapAutocomplete] = useState<google.maps.places.AutocompleteService>();
  const [searchString, setSearchString] = useState('');
  const [possibleAddresses, setPossibleAddresses] = useState<google.maps.places.AutocompletePrediction[]>();
  const [possibleAddressesNotFound, setPossibleAddressesNotFound] = useState('');
  const [openPossibleAddressesList, setOpenPossibleAddressesList] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<google.maps.places.PlaceResult>();

  const searchedAddress = useDebounce(searchString, 1000);

  const onChangeSelectedAddress = (newSelectedAddress: google.maps.places.AutocompletePrediction | undefined) => {
    if (newSelectedAddress?.place_id) {
      currentMapPlacesService?.getDetails({ placeId: newSelectedAddress.place_id }, result => {
        setSelectedAddress(result || undefined);
        result?.formatted_address && setSearchString(result.formatted_address);
      });
    }
    setOpenPossibleAddressesList(false);
  };

  const formPossibleAddressesUI = (
    possibleAddresses: google.maps.places.AutocompletePrediction[] | undefined,
    onChangeSelectedAddress: (newSelectedAddress: google.maps.places.AutocompletePrediction | undefined) => void
  ) =>
    possibleAddresses?.map((address, i) => {
      return (
        <div key={`option${i}`} className="optionContainer" onClick={() => onChangeSelectedAddress(address)}>
          <div className="iconContainer">
            <LocationIcon />
          </div>
          <p>{address?.description}</p>
        </div>
      );
    });

  const filledStyles = selectedAddress || searchString || defaultValueAddressName ? 'filled' : '';
  const errorStyles = errorText && errorText.length > 0 ? 'error' : '';
  const disabledStyles = isDisabled ? 'disabled' : '';
  const componentSizeStyles = componentSize ? componentSize : '';

  const findAddressFromText = () => {
    if (!currentMapPlacesService || !searchedAddress) return;
    mapAutocomplete?.getPlacePredictions({ input: searchedAddress, region: regionBias }, function (results, status) {
      if (status == 'OK' && results && results.length > 0) {
        setPossibleAddresses(results);
      } else if (status === 'ZERO_RESULTS') {
        setPossibleAddresses(undefined);
        setPossibleAddressesNotFound(t('nodi:addressSearchBar:findAddressFromText'));
      }
    });
  };

  const handleChangeSelectedAddress = async () => {
    if (!selectedAddress || !handleAddressSelection) return;
    if (!selectedAddress.geometry?.location?.lat || !selectedAddress.geometry?.location?.lng) return;
    const addressComponents = await geocoder?.geocode({
      location: {
        lat: selectedAddress.geometry?.location?.lat(),
        lng: selectedAddress.geometry?.location?.lng()
      }
    });
    if (addressComponents) {
      const country = addressComponents?.results[0]?.address_components
        .find(addressComponent => addressComponent.types.includes('country'))
        ?.short_name.toLowerCase();
      if (!country) return;
      const city = addressComponents?.results[0]?.address_components.find(
        addressComponents =>
          addressComponents.types.includes('political') &&
          !addressComponents.types.includes('sublocality') &&
          !addressComponents.types.includes('neighborhood')
      )?.long_name;
      const addressReturnValue = {
        address: selectedAddress.formatted_address,
        coordinates: {
          lat: `${selectedAddress.geometry?.location?.lat()}`,
          lng: `${selectedAddress.geometry?.location?.lng()}`
        },
        country: country,
        city: city || ''
      };
      handleAddressSelection(addressReturnValue);
    }
  };

  const handleBlur = () =>
    setSearchString(
      selectedAddress?.formatted_address ? selectedAddress?.formatted_address : defaultValueAddressName || ''
    );

  const didntFindAddressPositionStyle = {
    top: typeof didntFindAddressTooltipTopPosition === 'number' ? `${didntFindAddressTooltipTopPosition}px` : undefined,
    right:
      typeof didntFindAddressTooltipRightPosition === 'number'
        ? `${didntFindAddressTooltipRightPosition || 0}px`
        : undefined
  };

  const noAddressToolTip = (
    <div className="toolTip">
      <Trans i18nKey={'nodi:addressSearchBar:toolTip'} components={{ 1: <strong /> }} />
    </div>
  );

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, mapOptions));
    }
  }, [ref?.current, map]);

  useEffect(() => {
    if (!map) return;
    !currentMapPlacesService && setCurrentMapPlacesService(new window.google.maps.places.PlacesService(map));
    !geocoder && setGeocoder(new window.google.maps.Geocoder());
    !mapAutocomplete && setMapAutocomplete(new window.google.maps.places.AutocompleteService());
  }, [map]);

  useEffect(() => {
    findAddressFromText();
  }, [searchedAddress]);

  useEffect(() => {
    handleChangeSelectedAddress();
  }, [selectedAddress]);

  useEffect(() => {
    setSearchString(defaultValueAddressName || '');
  }, [defaultValueAddressName]);

  if (showSkeletonLoader)
    return (
      <div className={`nudosSkeletonLoader ${componentSizeStyles}`}>
        {label && <label className={styleLabel ? styleLabel : ''}>{label}</label>}
        <div className="inputSkeletonLoader animationLoader" />
      </div>
    );

  return (
    <div
      className={`nudosAddressSearchBar ${filledStyles} ${errorStyles} ${disabledStyles} ${componentSizeStyles}`}
      onBlur={handleBlur}
      style={componentCustomStyles}
    >
      {label && <label className={styleLabel ? styleLabel : ''}>{label}</label>}
      <NudosGenericSearchBar
        searchString={searchString || ''}
        setSearchString={setSearchString}
        searchedOptions={formPossibleAddressesUI(possibleAddresses, onChangeSelectedAddress)}
        handleChangeSelectedOption={onChangeSelectedAddress}
        defaultValue={undefined}
        isSelectOpen={openPossibleAddressesList}
        setIsSelectOpen={setOpenPossibleAddressesList}
        noResultsMessage={<em key="no-results-option">{possibleAddressesNotFound}</em>}
        noResultsUpdater={setPossibleAddressesNotFound}
        errorText={errorText}
        isDisabled={isDisabled}
        customPlaceholder={customPlaceholder}
        activeStylesIfCustomPlaceholder={activeStylesIfCustomPlaceholder}
        isLoading={isLoading}
      />
      {!hideDidntFindAddressToolTip && (
        <div className="didntFindAddress" style={didntFindAddressPositionStyle}>
          <div className="text">
            {t('nodi:addressSearchBar:didntFindAddress')}
            {noAddressToolTip}
          </div>
          <MoreInfoIcon className="moreInfoIcon" />
        </div>
      )}
      <div className="map hidden" ref={ref} />
      {errorText && errorText.length > 0 && <div className="errorText">{errorText}</div>}
    </div>
  );
};

export default NudosAddressSearchBar;

export interface InudosAddressSearchBar {
  label?: string;
  mapOptions?: google.maps.MapOptions;
  errorText?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  componentSize?: string;
  handleAddressSelection?: (selectedAddress: IaddressFromGoogleMaps) => void;
  defaultValueAddressName?: string;
  customPlaceholder?: string;
  activeStylesIfCustomPlaceholder?: boolean;
  regionBias?: string;
  hideDidntFindAddressToolTip?: boolean;
  didntFindAddressTooltipTopPosition?: number;
  didntFindAddressTooltipRightPosition?: number;
  styleLabel?: string;
  componentCustomStyles?: { [key: string]: string };
  showSkeletonLoader?: boolean;
}

import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { successCheckmark, waitingClock } from '../../../assets/images/views/RequestLogistics';
import { NudosButton } from '../../../components/NudosComponents';
import { BodyAlert, NewLogisticsVerifyData } from '../Components';
import { getLogisticServiceDetailsById } from '../../../services/logisticsServicesModule.services';
import {
  IlogisticServiceDetailedData,
  IlogisticServiceLocationData
} from '../../../types/logisticsServicesModule.types';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { GenericLoader } from '../../Admin/dashboard/components';
import useRequireLogisticsSummaryData from '../../../state/useRequireLogisticsSummaryData';
import { segmentTrackEvent } from '../../../utils/segment';
import { locationFormat } from '../../../utils/locationFormat';
import { getOrderRequiredDataByPaymentMethod } from '../../../utils/productDefinitions';
import { PAYMENT_TYPES } from '../../../types/checkout';
import './NewLogisticServiceSummary.scss';

const NewLogisticServiceSummary = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { logisticsId } = useParams<{ logisticsId: string }>();

  const {
    estimatedCollection: collectionData,
    estimatedDelivery: deliveryData,
    inferiorAlert,
    superiorAlert,
    stateEventsUnassignment
  } = useRequireLogisticsSummaryData();

  const [loading, setLoading] = useState(true);
  const [logisticServiceData, setLogisticServiceData] = useState<IlogisticServiceDetailedData>();

  const {
    collection,
    delivery,
    status,
    tools,
    manualQuote,
    billingData,
    paymentMethod,
    billingMethod,
    logisticsQuoteData
  } = logisticServiceData || {};
  const allToolHasNudosCare = tools?.every(tool => tool.nudosCare !== 'Free');
  const noDataText = 'Sin información';
  const searchParams = new URLSearchParams(search);
  const navigationOrigin = searchParams.get('origin') || '';
  const oldUserId = searchParams.get('oldUserId');

  const toolsIds = (tools || []).reduce<number[]>((prev, curr) => {
    curr?.productId && +curr?.productId !== 0 && prev.push(+curr?.productId);
    return prev;
  }, []);

  const { requiresBankData } = getOrderRequiredDataByPaymentMethod(paymentMethod || '', billingMethod, true);

  const summaryType =
    searchParams.get('flux') === 'assignment' || searchParams.get('collection') === 'false'
      ? 'assignment'
      : 'unassignment';

  const logisticDetailsRedirection = {
    pathname: `/nodi/logistics-services/details/${logisticsId}`,
    state: {
      returnText: 'Dashboard',
      returnAction: '/nodi/dashboard'
    }
  };

  const redirectionButtonText = ['inventory', 'tool-details', 'inventory-management'].includes(navigationOrigin)
    ? 'Ver herramienta'
    : 'Ver empleado';

  const getSubtitle = () => {
    if (allToolHasNudosCare)
      return (
        <div>
          Tu servicio fue confirmado con éxito gracias a Nudos Care, podrás ver el avance en el detalle del servicio
          logístico.
        </div>
      );
    if (manualQuote)
      return (
        <div>
          Pronto <strong>recibirás un correo electrónico con la cotización.</strong> Debes aprobar o rechazar la
          cotización desde el detalle del servicio.
        </div>
      );
    if (requiresBankData)
      return (
        <div>
          Realiza el pago a los datos indicados{' '}
          {billingData?.countryCode === 'uy' ? 'y sube el comprobante lo antes posible.' : 'lo antes posible.'}
          Toda esta información estará disponible en detalle en el servicio logístico en Nodi.
        </div>
      );
    return (
      <div>
        Pronto{' '}
        <strong>
          recibirás un correo electrónico con{' '}
          {paymentMethod === PAYMENT_TYPES.TRANSFER_ARS
            ? 'la factura y el monto total de pago.'
            : 'el enlace de pago y la factura.'}
        </strong>
        . Además, toda esta información estará disponible en detalle en el servicio logístico en Nodi.
      </div>
    );
  };

  const getLogisticMessages = () => {
    switch (manualQuote) {
      case true:
        return {
          titleText: 'Estamos cotizando tu servicio logístico',
          paymentAgreementAlert:
            '⚠️Si cuentas con un acuerdo de pago, debes aprobar la cotización para que tu servicio sea confirmado y asignarte una fecha de pago.',
          image: waitingClock
        };
      default:
        return {
          titleText: 'El servicio logístico fue solicitado correctamente',
          paymentAgreementAlert:
            '⚠️Si cuentas con un acuerdo de pago, pronto se confirmará el servicio y se te asignará una fecha de pago.',
          image: successCheckmark
        };
    }
  };

  const getLogisticServiceDetail = async () => {
    if (!logisticsId) return;
    setLoading(true);
    try {
      const response = await getLogisticServiceDetailsById(logisticsId);
      setLogisticServiceData(response);
    } catch {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const handleRedirectionToOrigin = () => {
    switch (navigationOrigin) {
      case 'inventory': {
        displaySuccessNotification({
          customJSXMessage: <>Las herramientas se desasignaron correctamente. </>,
          button1Text: 'Ver empleado',
          button1Action: () => push(`/nodi/employees/details/${oldUserId}`)
        });
        return push(`/nodi/details/${tools?.[0].productId}`);
      }
      case 'tool-details': {
        return push(`/nodi/details/${tools?.[0].productId}`);
      }
      case 'inventory-management': {
        return push(`/nodi/details/${tools?.[0].productId}`);
      }
      default:
        if (toolsIds?.length) {
          displaySuccessNotification({
            customJSXMessage: <>Las herramientas se desasignaron correctamente. </>,
            button1Text: toolsIds?.length > 1 ? 'Ver herramientas' : 'Ver herramienta',
            button1Action: () => push(toolsIds?.length > 1 ? '/nodi/tools' : `/nodi/details/${toolsIds[0]}`)
          });
        }
        return push(`/nodi/employees/details/${oldUserId}`);
    }
  };

  const getFormattedName = (locationData?: IlogisticServiceLocationData) => {
    if (!locationData) return noDataText;
    switch (locationData?.place) {
      case 'office':
        return `Oficinas ${locationData?.country?.name || ''}`;
      case 'user':
        return locationData?.additionalInfo?.name || noDataText;
      case 'warranty':
        return 'Marca';
      case 'nudos':
        return `Bodegas Nudos ${locationData?.country?.name || ''}`;
      case 'diagnostic':
        return 'Diagnóstico';
      default:
        return `${locationData?.additionalInfo?.name || locationData?.locationName || noDataText}`;
    }
  };

  const successEvent = () => {
    if (stateEventsUnassignment?.type === 'unassignment')
      segmentTrackEvent({
        nodiLogisticServices51ResumeAprovedView: {
          DestinationCountry: stateEventsUnassignment?.destinationCountry || '',
          LogisticServiceDestination: locationFormat(stateEventsUnassignment?.logisticServiceDestination || ''),
          LogisticServiceOrigin: stateEventsUnassignment?.logisticServiceOrigin || '',
          NudosCare: !!stateEventsUnassignment?.nudosCare,
          OriginCountry: stateEventsUnassignment?.originCountry || '',
          TypeOfPlatform: stateEventsUnassignment?.typeOfPlatform || ''
        }
      });
  };

  useEffect(() => {
    getLogisticServiceDetail();
    successEvent();
  }, []);

  if (loading)
    return (
      <div className="NewLogisticServiceSummary">
        <GenericLoader />
      </div>
    );

  return (
    <div className="NewLogisticServiceSummary">
      <div className="iconImage">
        <img src={getLogisticMessages()?.image} alt="iconImage" />
      </div>
      <div className="titleText">{getLogisticMessages()?.titleText}</div>
      <div className="subtitleText">{getSubtitle()}</div>
      <div className="paymentAgreementAlert">{getLogisticMessages()?.paymentAgreementAlert}</div>
      <NewLogisticsVerifyData
        type={summaryType}
        tools={tools || []}
        originPlace={logisticServiceData?.collection?.place || ''}
        deliveryName={getFormattedName(delivery)}
        timeAlertText={superiorAlert}
        quoteData={logisticsQuoteData}
        collectionName={getFormattedName(collection)}
        destinationPlace={logisticServiceData?.delivery?.place || ''}
        estimatedDelivery={deliveryData || ''}
        selectedDatesRange={collectionData || ''}
        bodyAlertComponent={inferiorAlert && inferiorAlert?.data && <BodyAlert data={inferiorAlert?.data} />}
        paymentSpecialData={logisticServiceData}
        logisticServiceStatus={status || undefined}
      />
      <div className="floatContainerButtosServiceSummary">
        <div className="boxButtonsContainer">
          <NudosButton
            componentSize="medium"
            colorPalette="secondary"
            buttonText={redirectionButtonText}
            handleClick={handleRedirectionToOrigin}
          />
          <Link className="logisticDetailsRedirection" to={logisticDetailsRedirection}>
            <NudosButton componentSize="medium" buttonText="Ver servicio logistico" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewLogisticServiceSummary;

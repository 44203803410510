import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { NudosFileInput } from '../../../../../components/NudosComponents';
import { Iemployee, IemployeeInfo, IExistingEmployeeInfo, Tany, TfunctionOneToVoid } from '../../../../../types/global';
import NudosRadioButton from '../../../../../components/NudosComponents/NudosRadioButton/NudosRadioButton';
import NudosButton from '../../../../../components/NudosComponents/NudosButton/NudosButton';
import validateFile from '../../../../../utils/validateFile';
import useAssignationsStates from '../../../../../state/useAssignationStates';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import urlParameter from '../../../../../utils/urlParameter';
import {
  assignOrUnassign,
  getEmployeeDataByUserId
} from '../../../../RequestLogistics/utils/requestNewLogisticsUtilFunctions';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import './CompleteAssigneeInfoModal.scss';
import useLogisticsServiceBillingState from '../../../../../state/useLogisticsQuoteAndBilling';

const CompleteAssigneeInfoModal = ({
  employeeToAssign,
  toCloseModal,
  productId,
  originLocationId,
  setFormErrors,
  continueCallback,
  assignationIsDisabled,
  requiresLogistics,
  originPlace,
  originCountryId
}: IcompleteAssigneeInfoModal) => {
  const { replace } = useHistory();
  const previousUserId = urlParameter('previousUserId');
  const { setRefreshNodiDetail } = useAssignationsStates();
  const { billingMethodAndCountry, logisticsServiceBillingData } = useLogisticsServiceBillingState();

  const [previousAssigneeData, setPreviousAssigneeData] = useState<Iemployee>();
  const [destinationData, setDestinationData] = useState<Iemployee>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isUploadedFileValid, setIsUploadedFileValid] = useState<boolean>();
  const [informEmployee, setInformEmployee] = useState<boolean>();
  const [loading, setLoading] = useState(false);

  const incompleteDestinationDataForLogistics =
    requiresLogistics && (!destinationData?.locationId || !employeeToAssign?.address?.address);

  const uploadedFileHandler = (newUploadedFile: Tany) => {
    if (!newUploadedFile) return setUploadedFile(undefined);
    setUploadedFile(newUploadedFile);
    const isFileValid = validateFile(
      newUploadedFile,
      ['application/pdf'],
      2,
      'El documento debe estar en formato PDF',
      'El documento no debe superar los 2MB'
    );
    setIsUploadedFileValid(isFileValid);
  };

  const buttonIsDisabled =
    typeof informEmployee !== 'boolean' ||
    loading ||
    (!!uploadedFile && !isUploadedFileValid) ||
    assignationIsDisabled ||
    incompleteDestinationDataForLogistics;

  const handleSuccessfulAssignment = () => {
    if (!continueCallback) {
      displaySuccessNotification({ customJSXMessage: <>La herramienta se asignó correctamente</> });
      replace(`/nodi/details/${productId}`);
    }
    if (continueCallback) continueCallback();
    toCloseModal(false);
  };

  const handleAssignmentError = (error: AxiosError) => {
    if (
      setFormErrors &&
      ((typeof error?.response?.data === 'string' && error?.response?.data?.includes('teléfono')) ||
        (typeof error?.response?.data?.message && error?.response?.data?.message?.includes('teléfono')))
    ) {
      setFormErrors({ phone: 'Este teléfono ya existe, intenta con otro' });
      toCloseModal(false);
    } else if (
      setFormErrors &&
      (error?.response?.data === 'Error creating user: The email already exists' ||
        error?.response?.data?.message === 'Error creating user: The email already exists')
    ) {
      setFormErrors({ email: 'Este correo ya existe, intenta con otro' });
      toCloseModal(false);
    } else {
      displayErrorNotification();
    }
  };

  const handleAssignmentWithOrWithoutUnassignment = () => {
    if (!employeeToAssign?.country?.id || !originLocationId || incompleteDestinationDataForLogistics) return;
    if (!employeeToAssign?.firstName || !employeeToAssign?.lastName || !originCountryId || !originPlace) return;
    const originAndDestinationData = {
      originLocationId,
      originCountryId,
      destinationCountryId: employeeToAssign?.country?.id,
      destinationLocationId: destinationData?.locationId || undefined,
      origin: originPlace,
      destination: 'user'
    };
    const assignmentInfo = {
      userId: employeeToAssign?.userId,
      firstName: employeeToAssign?.firstName,
      lastName: employeeToAssign?.lastName,
      products: [+productId],
      address: employeeToAssign?.address?.address || undefined,
      countryId: employeeToAssign?.country?.id
    };
    const toolToAssign = [{ productId }];
    const collectionInfo =
      previousAssigneeData && previousAssigneeData?.firstName && previousAssigneeData?.lastName
        ? {
            delivererName: `${previousAssigneeData?.firstName || ''} ${previousAssigneeData?.lastName || ''}`,
            delivererPersonalId: previousAssigneeData?.personalId,
            delivererCountryIdPhone: previousAssigneeData?.phoneData?.countryId,
            delivererPhone: previousAssigneeData?.phoneData?.phone
          }
        : undefined;
    const deliveryInfo = {
      receiverName: `${employeeToAssign?.firstName || ''} ${employeeToAssign?.lastName || ''}`,
      receiverPersonalId: employeeToAssign?.personalId,
      receiverCountryIdPhone: employeeToAssign?.phoneData?.countryId,
      receiverPhone: employeeToAssign?.phoneData?.phone
    };
    assignOrUnassign(
      previousAssigneeData || previousUserId ? false : true, // If the tool has a previousAssignee (ie, it is currently assigned) it must be unassigned first and hence the flux is considered an unassignment
      requiresLogistics,
      informEmployee || false,
      originAndDestinationData,
      logisticsServiceBillingData || null,
      billingMethodAndCountry?.billingCountryCode,
      toolToAssign,
      assignmentInfo,
      collectionInfo,
      deliveryInfo,
      uploadedFile,
      undefined,
      undefined,
      setLoading,
      handleSuccessfulAssignment,
      handleAssignmentError
    );
  };

  useEffect(() => {
    setRefreshNodiDetail(false);
  }, []);

  useEffect(() => {
    if (previousUserId && !previousAssigneeData) getEmployeeDataByUserId(previousUserId, setPreviousAssigneeData);
    if (employeeToAssign?.userId && !destinationData)
      getEmployeeDataByUserId(employeeToAssign.userId, setDestinationData);
  }, [previousUserId, employeeToAssign?.userId]);

  return (
    <div className="completeAssigneeInfoModal">
      <div className="title">
        {'¿Deseas informar a '}
        {employeeToAssign && <strong>{`${employeeToAssign.firstName} ${employeeToAssign.lastName} `}</strong>}
        {'sobre la asignación de este dispositivo?'}
      </div>
      <div className="optionsContainer">
        <div className="option" onClick={() => setInformEmployee(true)}>
          <NudosRadioButton isButtonActive={informEmployee} componentSize="medium" />
          {'Sí'}
        </div>
        <div className="option" onClick={() => setInformEmployee(false)}>
          <NudosRadioButton
            isButtonActive={typeof informEmployee === 'boolean' && !informEmployee}
            componentSize="medium"
          />
          {'No'}
        </div>
      </div>
      <div className="assignationDocument">
        <div className="titleQuestion">{'Sube el documento de asignación (Opcional).'}</div>
        <NudosFileInput
          color="orange"
          uploadedFileHandler={uploadedFileHandler}
          buttonText={uploadedFile?.name || 'Documento de asignación'}
          isFilled={!!uploadedFile}
          errorText={uploadedFile && !isUploadedFileValid ? 'El documento cargado no es válido' : undefined}
          resetSelectionWhenClickingWhenFilled
        />
      </div>
      <NudosButton
        buttonText={'Asignar'}
        handleClick={handleAssignmentWithOrWithoutUnassignment}
        isButtonDisabled={buttonIsDisabled}
        isButtonLoading={loading}
        componentSize="large"
      />
    </div>
  );
};
export default CompleteAssigneeInfoModal;

export interface IcompleteAssigneeInfoModal {
  employeeToAssign?: IExistingEmployeeInfo;
  toCloseModal: TfunctionOneToVoid;
  productId: string;
  originLocationId?: number | null;
  setFormErrors?: React.Dispatch<React.SetStateAction<{ [key: string]: string } | undefined>>;
  place?: string;
  continueCallback?: () => void;
  assignationIsDisabled?: boolean;
  requiresLogistics: boolean;
  originPlace?: TstoragePlace;
  originCountryId?: number;
}

export type TonAssignToEmployee = (
  employeeToAssign: IemployeeInfo,
  assignationDate: string | undefined,
  uploadedFileUrl: string | undefined,
  setLoadingParam: React.Dispatch<React.SetStateAction<boolean>>,
  place?: string
) => void;

import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NudosBreadcrumbButton } from '../../../../../components/NudosComponents';
import CheckoutSummary from '../CheckoutSummary';
import useShopStore from '../../../../../state/useShopContext';
import { ISummaryItem } from '../../../../../types/cart';
import { CountriesList } from './components';
import { GREYSHOPPINGCART } from '../../../../../assets/images/views/Ecommerce';
import useCheckoutStore from '../../state';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import { productsNumber, productsOnly } from '../../../../../utils/cart';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { ShipmentSubtotalTooltip } from '../../../../../components/NudosPrimeOrFree';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { getShoppingCart } from '../../../../../services/cart.services';
import './Step1ShoppingCart.scss';

interface IStep1ShoppingCart {
  openModalInvalidCart: () => void;
}

const Step1ShoppingCart: FC<IStep1ShoppingCart> = ({ openModalInvalidCart }: IStep1ShoppingCart) => {
  const { t } = useTranslation();
  const [loadingCart, setLoadingCard] = useState<boolean>(false);
  const { stateSubscription } = useStateSubscriptions();
  const { replace } = useHistory();
  const { itemsCart } = useShopStore();
  const { setStep } = useCheckoutStore();
  const productsCart = productsOnly();
  const orgInfo = formatOrgData();

  const { isPrime } = stateSubscription;
  const itemsCartProducs = itemsCart?.products || [];
  const productsList = itemsCartProducs;
  const productsListExists = productsList && productsList.length > 0;
  const shoppingCartIsEmpty = !itemsCart?.numberOfProducts || !itemsCart?.references;
  const translationKey = 'nodi:checkout:step1ShoppingCart:';

  const productsEvent = itemsCartProducs?.map(product => {
    return {
      SKUId: `${product?.sku}`,
      Total: `${product?.unitPrice} USD`,
      Country: `${product?.countryName}`,
      Quantity: `${product?.quantity}`
    };
  });

  const summaryFirstSectionListingElements = {
    [t('recurrentWords:referencesName')]: itemsCart?.references || '0',
    [t('recurrentWords:products')]: productsNumber() || '0',
    [t('recurrentWords:numberOfShipments')]: t('recurrentWords:toBeCalculated')
  };

  const summarySecondSectionListingElementsOtherFormat: ISummaryItem[] = [
    {
      key: 'productsSubtotal',
      name: t(`${translationKey}productsSubtotal`),
      mainValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.total || 0 : itemsCart?.totalWithoutPrime || 0),
        maximumFractionDigits: 2
      })} USD`,
      secondaryValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.totalWithoutPrime || 0 : itemsCart?.total || 0),
        maximumFractionDigits: 2
      })} USD`,
      showSecondaruValue: true,
      toCalculate: false,
      showGrayIcon: !isPrime,
      showYellowIcon: false
    },
    {
      key: 'deliverySubtotal',
      name: t(`${translationKey}deliverySubtotal`),
      mainValue: t('recurrentWords:toBeCalculated'),
      toCalculate: true,
      tooltip: <ShipmentSubtotalTooltip step={1} />
    },
    {
      key: 'productsTotal',
      name: 'Total',
      mainValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.total || 0 : itemsCart?.totalWithoutPrime || 0),
        maximumFractionDigits: 2
      })} USD`,
      secondaryValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.totalWithoutPrime || 0 : itemsCart?.total || 0),
        maximumFractionDigits: 2
      })} USD`,
      showSecondaruValue: true,
      toCalculate: false,
      isBold: true,
      showYellowIcon: isPrime,
      showGrayIcon: !isPrime
    }
  ];

  const usdTotal = `${nudosFormatCurrency({
    value: Number(isPrime ? itemsCart?.total || 0 : itemsCart?.totalWithoutPrime || 0),
    maximumFractionDigits: 2
  })} USD`;

  const checkCart = async () => {
    setLoadingCard(true);
    try {
      const dataCart = await getShoppingCart(Number(orgInfo?.userId), Number(orgInfo?.organizationId));
      if (!!dataCart?.products && dataCart?.products?.length <= 0) {
        openModalInvalidCart();
      } else {
        segmentTrackEvent({
          [isPrime ? 'primeCheckoutKartClick' : 'freeCheckoutKartClick']: {
            ProductList: productsEvent,
            UsdTotal: usdTotal
          }
        });
        setStep(1);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingCard(false);
    }
  };

  useEffect(() => {
    segmentTrackEvent({
      [!isPrime ? 'freeCheckout1KartView' : 'primeCheckout1KartView']: {
        ProductList: productsEvent
      }
    });
  }, []);

  return (
    <div id="step1ShoppingCart">
      <NudosBreadcrumbButton
        returnText={t(`${translationKey}returnText`)}
        returnAction={() => {
          replace('/catalogue/home');
          segmentTrackEvent({
            [!isPrime ? 'freeCheckoutBackClick' : 'primeCheckoutBackClick']: {
              ScreenName: 'Kart'
            }
          });
        }}
      />
      <div className="spaceInfo">
        <div className={'cardsContainer calcSpecialWidth'}>
          <div className="cardsBox">
            {productsList && productsList.length > 0 && <CountriesList referencesList={productsList} />}
            {!productsListExists && (
              <div className="zeroState">
                <div className="imageContainer">
                  <img className="maxWidthCartImg" src={`${GREYSHOPPINGCART}`} alt="Carrito" />
                </div>
                <div className="text">{t(`${translationKey}zeroState`)}</div>
              </div>
            )}
          </div>
        </div>
        <div className="totalContainer boxTotalAditionalStyles">
          <div className="boxTotalContainer">
            <CheckoutSummary
              customClassName="checkoutCartSummary checkoutCartSummaryWidth"
              buttonText={t(`${translationKey}mainButtonText`)}
              firstSectionListingElements={summaryFirstSectionListingElements}
              secondSectionListItems={summarySecondSectionListingElementsOtherFormat}
              showShipmentsSection={true}
              handleClickButton={() => {
                checkCart();
              }}
              isButtonDisabled={shoppingCartIsEmpty}
              checkoutStep={1}
              itemsCart={itemsCart}
              heightLimitProductList={100}
              referencesList={productsCart && productsCart.length > 0 ? productsCart : []}
              loadingButton={loadingCart}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1ShoppingCart;

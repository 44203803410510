import { createContext, Suspense, useContext, useEffect, useState } from 'react';
import {
  ConfirmationResult,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
  User
} from '@firebase/auth';
import * as Sentry from '@sentry/react';
import { auth } from './firebase';
import { displayErrorNotification } from '../utils/displayNudosStandardNotifications';
import useShopStore from './useShopContext';
import { getShoppingCart } from '../services/cart.services';
import { formatOrgData } from '../utils/orgFormatData';
import { getInfoSubscription } from '../services/subscriptions.services';
import useStateSubscriptions from './useStateSubscriptions';
import Logout from '../components/Logout';
import useLogOutState from './useLogOutState';
import { Tany } from '../types/global';
import { initI18n } from '../i18n/config';
import useOnlyDevelopers from './useStateOnlyDevelopers';
import useLanguagePlatformState from './useLenguageState';
import { GenericLoader } from '../views/Admin/dashboard/components';
import { ISubscriptions } from '../types/subscriptions';
import useStoreCountryState, { IStoreCountry } from './useStoreCountryState';
declare global {
  interface Window {
    analytics: Tany;
  }
}

const userAuthContext = createContext<{
  user?: User | null;
  setUpRecaptha?: (number: string) => Promise<ConfirmationResult>;
  logOut?: () => void;
}>({});

export function UserAuthContextProvider({ children }: { children: JSX.Element }) {
  const [user, setUser] = useState<User | null>(null);
  const [loadingCart, setLoadingCart] = useState<boolean>(false);
  const [loadingSubscriptions, setLoadingsubscriptions] = useState<boolean>(false);
  const { setStateSubscription } = useStateSubscriptions();
  const { setIsLogoutModalOpen } = useLogOutState();
  const [loadLanguage, setLoadLanguage] = useState<boolean>(false);
  const [loadingLS, setLoadingST] = useState<boolean>(false);
  const [loadingSentry, setLoadingSentry] = useState<boolean>(false);
  const { setOnlyDevelopers } = useOnlyDevelopers();
  const { setCart } = useShopStore();
  const { setStoreCountry } = useStoreCountryState();
  const { setPlatformLanguage } = useLanguagePlatformState();
  const orgInfo = formatOrgData();
  function setUpRecaptha(number: string) {
    const recaptchaVerifier = new RecaptchaVerifier(
      'sign-button',
      {
        size: 'invisible',
        callback: () => undefined
      },
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

  function logOut() {
    signOut(auth)
      .then(() => {
        [
          'orgData',
          'sessionLogin',
          'bagItems',
          'savedStep',
          'ajs_anonymous_id',
          'typeLogin',
          'activeWasShown',
          'personalData',
          'uniqSessionId',
          'currentOrderShippingData',
          'actualDateLogisticsService'
        ].forEach(storedElement => localStorage.removeItem(`${storedElement}`));
        window.location.replace('/login');
      })
      .catch(() => {
        displayErrorNotification();
      });
  }

  const getUserCart = async () => {
    setLoadingCart(true);
    try {
      if (orgInfo) {
        const dataCart = await getShoppingCart(orgInfo?.userId, orgInfo?.organizationId);
        setLoadingCart(false);
        setCart(dataCart);
        localStorage.setItem('bagItems', JSON.stringify(dataCart));
        if (dataCart?.country) {
          const formartStoreCountry: IStoreCountry = {
            countryCode: dataCart?.country?.code,
            countryFlag: dataCart?.country?.flag,
            countryName: dataCart?.country?.name,
            id: dataCart?.country.id,
            nameStringId: dataCart?.country?.nameStringId || ''
          };
          setStoreCountry(formartStoreCountry);
        }
      }
      setLoadingCart(false);
    } catch (error) {
      const fakeCart = {
        shoppingCartId: null,
        userId: orgInfo?.userId || 0,
        organizationId: orgInfo?.organizationId || 0,
        references: null,
        numberOfProducts: null,
        total: null,
        products: null,
        totalDiscountValue: null,
        totalWithoutPrime: null
      };
      setCart(fakeCart);
      localStorage.setItem('bagItems', JSON.stringify(fakeCart));
      setLoadingCart(false);
      displayErrorNotification();
    }
  };

  const resetStepsCheckout = () => {
    if (localStorage.getItem('resetCheckoutSteps')) {
      localStorage.setItem('savedStep', '0');
      localStorage.removeItem('resetCheckoutSteps');
    }
  };

  const initialSubscriptionInfo = async () => {
    setLoadingsubscriptions(true);
    try {
      const dataSubscriptions = await getInfoSubscription(orgInfo?.organizationId || 0);
      if (dataSubscriptions) {
        const { levelSubscription } = dataSubscriptions;
        //si quieren mas empresas "Vip" se debe considerar cambiar la logica
        const data: ISubscriptions = {
          levelSubscription: levelSubscription === 'Vip' ? 'Lite' : levelSubscription,
          nudosCare: true,
          isPrime: levelSubscription === 'Lite' || levelSubscription === 'Vip' ? true : false
        };
        setStateSubscription({ ...data });
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingsubscriptions(false);
    }
  };

  const platformLanguage = () => {
    const language = initI18n();
    if (language) {
      setLoadLanguage(true);
      setPlatformLanguage(language);
    }
  };
  const searchOnlyDevelopers = () => {
    setLoadingST(true);
    if (localStorage.getItem('onlyDevs')) {
      const isTrueOnlyDevs = localStorage.getItem('onlyDevs') === 'true';
      if (isTrueOnlyDevs) {
        setOnlyDevelopers(true);
      } else {
        setOnlyDevelopers(false);
      }
      setLoadingST(false);
    } else {
      setOnlyDevelopers(false);
      setLoadingST(false);
    }
  };

  const initializeSentry = async () => {
    setLoadingSentry(true);
    try {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: []
      });
      setLoadingSentry(false);
    } catch (error) {
      setLoadingSentry(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentuser => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (orgInfo && !!user) {
      initialSubscriptionInfo();
    }
  }, [user]);

  useEffect(() => {
    if (orgInfo) {
      getUserCart();
      resetStepsCheckout();
    }
  }, []);

  useEffect(() => {
    platformLanguage();
    searchOnlyDevelopers();
    initializeSentry();
  }, []);

  return (
    <Suspense fallback="LOADING">
      <userAuthContext.Provider
        value={{
          user,
          setUpRecaptha,
          logOut
        }}
      >
        <Logout
          setIsLogoutModalOpen={state => {
            setIsLogoutModalOpen(state);
          }}
        />
        {loadingSentry || loadingCart || loadingSubscriptions || !loadLanguage || loadingLS ? (
          <div className="w-screen h-screen">
            <GenericLoader />
          </div>
        ) : (
          children
        )}
      </userAuthContext.Provider>
    </Suspense>
  );
}
export function useUserAuth() {
  return useContext(userAuthContext);
}

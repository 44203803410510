import { FC } from 'react';
import {
  BlackCheckIcon,
  CheckPaymentIcon,
  SpeiCard,
  americanExpressCard,
  mastercard,
  visaCard
} from '../../../../../../../assets/images/views/Ecommerce';
import { IInvoicePaymentMethods } from '../../../../../../../types/cart';
import { IconInformation } from '../../../../../../../assets/DesignSystem/SVGComponents';
import { useTranslation } from 'react-i18next';
import useStoreCountryState from '../../../../../../../state/useStoreCountryState';
import { useLocation } from 'react-router-dom';
import './PaymentMethodCard.scss';

interface IPaymentMethodCard {
  detailCard: IInvoicePaymentMethods;
  handleChangeMethod: (method: IInvoicePaymentMethods | undefined) => void;
  paymenthMethod?: IInvoicePaymentMethods;
  changeNeedInvoice: (method: IInvoicePaymentMethods) => void;
  openModalCallback?: () => void;
}

const PaymentMethodCard: FC<IPaymentMethodCard> = ({
  detailCard,
  paymenthMethod,
  handleChangeMethod,
  changeNeedInvoice
}: IPaymentMethodCard) => {
  const { t } = useTranslation();
  const { storeCountry } = useStoreCountryState();
  const { pathname } = useLocation();

  const isLogisticFLux = pathname.includes('nodi');
  const translationKey = 'nodi:billingDataSection:paymentMethodCard:';
  const exchangeRateValueArs = t(`${translationKey}exchangeRateValueArs`);
  const cardFeesDetails = t(`${translationKey}cardFeesDetails`);
  const cardComissionDetails = t(`${translationKey}cardComissionDetails`);
  const checkedInvoice = t(`${translationKey}checkedInvoice`);

  const currencyCodes: string[] = ['COP'];
  const chosenPaymenMethod = !!paymenthMethod;
  const displaySpeiCard = detailCard?.extraData?.alias === 'Transferencia en (SPEI)';
  const displayVisaCard =
    detailCard?.extraData?.alias?.includes('Tarjeta') && !currencyCodes?.includes(detailCard?.currency?.code);
  const displayAmericanCard =
    detailCard?.extraData?.alias === 'Tarjeta de crédito nacional' && detailCard?.currency?.code === 'MXN';
  const displayCards = displaySpeiCard || displayVisaCard || displayAmericanCard;
  const paymentIsARS = detailCard?.currency?.code === 'ARS';
  const hasExchangeRate = (!!detailCard?.exchangeRate && detailCard?.currency?.code !== 'USD') || paymentIsARS;
  const hasBothDetails = hasExchangeRate && detailCard?.transactionFee ? 'hasBothDetails' : '';
  const exchangeRateValue = paymentIsARS
    ? exchangeRateValueArs
    : `${detailCard.exchangeRate} ${detailCard?.currency?.code}`;
  const countryOfPurchaseAr = !isLogisticFLux && storeCountry?.countryCode === 'ar';
  const displayBlueMessage = !!detailCard?.extraData?.blueMessage && isLogisticFLux ? true : countryOfPurchaseAr;
  const blueMessage = detailCard?.extraData?.blueMessage;

  const handlePaymentRadiusOnClik = () => {
    if (detailCard.id !== paymenthMethod?.id) {
      handleChangeMethod(detailCard);
    } else {
      handleChangeMethod(undefined);
    }
  };

  return (
    <div id="paymentMethodCard">
      <div className="boxPaymentCard">
        <div className="paymentNameBox">
          <div className="paymentContainer">
            <div className="paymentRadius" onClick={handlePaymentRadiusOnClik}>
              {chosenPaymenMethod ? <CheckPaymentIcon /> : <div className="noSelect" />}
            </div>
            <div className="paymentIcon">{detailCard?.extraData?.icon && detailCard?.extraData?.icon}</div>
            <div className="paymentName">
              <div className={`noSelect ${chosenPaymenMethod ? 'blackText' : ''}`}>
                {detailCard?.extraData?.alias || detailCard?.name}
              </div>
            </div>
          </div>
          <div className="floatSubtitle">
            {(hasExchangeRate || !!detailCard?.transactionFee) && (
              <div className="noSelectFloatText extraText">
                <div className="flex gap-1">
                  {hasExchangeRate && (
                    <span className={`cardFeesDetails ${hasBothDetails}`}>
                      {cardFeesDetails}
                      <p className="font-bold">{exchangeRateValue}</p>
                    </span>
                  )}
                  {!!detailCard?.transactionFee && (
                    <span className="cardFeesDetails">
                      {cardComissionDetails}
                      <p className="font-bold"> {detailCard?.transactionFee}%</p>
                    </span>
                  )}
                </div>
                {displayCards && (
                  <div className="cardsContainer">
                    {displaySpeiCard && <img className="w-6" src={`${SpeiCard}`} />}
                    {displayVisaCard && <img className="w-6" src={`${visaCard}`} />}
                    {displayVisaCard && <img className="w-4 h-3" src={`${mastercard}`} />}
                    {displayAmericanCard && <img className="w-4 h-3" src={`${americanExpressCard}`} />}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {chosenPaymenMethod && detailCard?.extraData?.extraBody && (
          <div className="extraBody">
            <div className="extraText">{detailCard?.extraData?.extraBody}</div>
            {paymenthMethod && paymenthMethod?.extraData?.showCheckInvoice && (
              <div className="checkedInvoice">
                <div className="checkedIcon" onClick={() => changeNeedInvoice(paymenthMethod)}>
                  {paymenthMethod?.extraData?.needInvoice ? <BlackCheckIcon /> : <div className="noCheck" />}
                </div>
                <div className="checkedText">{checkedInvoice}</div>
              </div>
            )}
            {displayBlueMessage && (
              <div className="blueMessage">
                <div className="messageContainer">
                  <IconInformation width={24} />
                  {blueMessage}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethodCard;
